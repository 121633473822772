import React from 'react'

const SnapshotFrame = props => {
  if (props.isTabletOrMobile) {
    if (props.snapshotSize.height !== 400 && props.snapshotSize.width !== 400)
      props.setSnapshotSize({ height: 400, width: 400 })
  } else {
    if (props.snapshotSize.height !== 500 && props.snapshotSize.width !== 500)
      props.setSnapshotSize({ height: 500, width: 500 })
  }

  const style = {
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0)',
    border: '2px solid rgba(255,0,0,.75)',
    pointerEvents: 'none',
    height: props.snapshotSize.height + 'px',
    width: props.snapshotSize.width + 'px',
    position: 'absolute',
    // top: props.isTabletOrMobile ? "calc(50% + 48px)" : "calc(50% + 64px)",
    zIndex: '1',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)'
  }

  const hiddenStyle = {
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'none',
    pointerEvents: 'none',
    height: props.snapshotSize.height + 'px',
    width: props.snapshotSize.width + 'px',
    position: 'absolute',
    zIndex: '1',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)'
  }

  const changeDisplay = () => {
    props.setDisplayFrame(!props.displayFrame)
  }

  if (props.display) {
    return <div style={style}></div>
  } else {
    return (
      <div style={props.displayFrame ? style : hiddenStyle}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          {/* <button onClick={changeDisplay} style={{position: 'absolute', top: '0', right: '0', fontSize: '12px', pointerEvents: 'auto'}}>{props.displayFrame ? 'Hide' : 'Show'} Frame</button> */}
          <button
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              fontSize: '12px',
              pointerEvents: 'auto'
            }}
          >
            Put this box in center
          </button>
        </div>
      </div>
    )
  }
}

export default SnapshotFrame
