import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import UploadCamera from './UploadCamera';
import Upload from './Upload';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  dialogPaper: { 
    minWidth: "50%",
    [theme.breakpoints.down('md')]: {
      minWidth:'62%',
    },
   [theme.breakpoints.down('sm')]: {
      minWidth:'90%',
    },
  },
}));

const PhotoDialog = (props) => {

  const classes = useStyles();

    const styles = theme => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
          width:'40%',
          margin:'0 auto',
          position:'relative',
          marginTop:'1%',
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
        title_edit: {
          width: '38.9%',
          margin: '0px auto',
          background: '#fff',
          marginTop: '5%',
          padding: '16px 16px 0 16px',
      },
        
      });
      
      const DialogTitle = withStyles(styles)(props => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });
      
      const DialogContent = withStyles(theme => ({
        root: {
          padding: theme.spacing(2),
          borderBottom:'1px solid #acacac',
        },
      }))(MuiDialogContent);
      
      const DialogActions = withStyles(theme => ({
        root: {
          margin: 0,
          padding: theme.spacing(1),
          borderBottom:'1px solid #acacac'
        },
      }))(MuiDialogActions);


    const handleClose = () => {
        props.setDialogOpen(false);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.dialogOpen} classes={{ paper : classes.dialogPaper}}>
            
            <DialogTitle className={styles.title_edit} id="customized-dialog-title" onClose={handleClose}>
                {props.param === 'take' ? 'Take ' : 'Add '} Photo
            </DialogTitle>

            {/* Camera For Taking Photos */}
            {props.param === 'take' && 
                <UploadCamera 
                    selectedReport={props.selectedReport} 
                    selectedJob={props.selectedJob} 
                    dialogOpen={props.dialogOpen} 
                    setDialogOpen={props.setDialogOpen} 
                    setReloadPhotos={props.setReloadPhotos}
                />
            }

            {/* Form for Uploading Photos */}
            {props.param === 'upload' && 
                <Upload 
                    selectedReport={props.selectedReport} 
                    selectedJob={props.selectedJob} 
                    dialogOpen={props.dialogOpen} 
                    setDialogOpen={props.setDialogOpen} 
                    setReloadPhotos={props.setReloadPhotos}
                />
            }
        </Dialog>
    );
}

export default PhotoDialog;


