import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../../react-auth0-wrapper'
import BottomNavBar from '../NavBars/bottomNavbar'

const PrivateRoute = props => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  let url = props.location.pathname
  let Component = props.component
  let path = props.path

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: url }
        })
      }
    }

    fn()
  }, [isAuthenticated, loginWithRedirect, path])

  const render = props =>
    isAuthenticated === true ? <Component {...props} /> : null

  return (
    <>
      <Route exact path={path} render={render} />
      {path !== '/opportunities/:opid' && <BottomNavBar />}
    </>
  )
}

export default PrivateRoute
