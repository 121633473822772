import React, {useState, useEffect} from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const JobSelect = (props) => {

    const handleSelect = (event) => {
        let job = props.jobs.find(job => job.__ID === event.target.value);

        if(typeof job === 'undefined') {
            job = null;
        }
        props.setSelectedJob(job);
    }

    const selectStyle = {
        width : '100%',
        fontSize:'15px',
        // marginBottom:'1.5%'        
    }

    const selectHeading = {
        color:'#000',
        fontWeight: '500',
        fontSize: '17px',
        marginBottom: '2px'
    }

    return (
        <div>
            <br />
            {/* List of Jobs */}
            {!props.loadingJobs && <>
            <p id="job-select-label" style={selectHeading}>Select a Job</p>
            <Select
              id="demo-simple-select"
              labelid="job-select-label"
              value={props.selectedJob ? props.selectedJob.__ID : ''}
              onChange={handleSelect}
              style={selectStyle}
            >
              <MenuItem value={''}></MenuItem>
              {props.jobs.map(job => <MenuItem key={job.recordId} value={job.__ID}>{job.Job_Name}</MenuItem>)}
            </Select>
            </>}

            {/* Loading */}
            {props.loadingJobs && <div>Loading Jobs...</div>}
        </div>
    );
}

export default JobSelect;