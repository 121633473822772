// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-wrapper";
import config from "./auth_config.json";
import './index.css';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from './themes/theme';
import HttpsRedirect from 'react-https-redirect';
import history from "./utils/history";
import { RoofProvider } from './AppProvider';

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
  /*
  if(appState && appState.targetUrl) {
    window.location.replace(appState.targetUrl);
  } else {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
  */

};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    audience={config.audience}
    onRedirectCallback={onRedirectCallback}
  ><MuiThemeProvider theme={theme}>
      <HttpsRedirect>
        <RoofProvider>
          <App />
        </RoofProvider>
      </HttpsRedirect>
    </MuiThemeProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();