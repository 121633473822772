import React, {useState, useEffect} from "react";
import Button from '@material-ui/core/Button';
import { Icon, TextField } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import Message from './Message';
import {makeStyles} from '@material-ui/core/styles';
import { generateSnapshot, generateSnapshotList, generatePDF, generateMarkerPhotos } from './SavePdf';
import { useAuth0 } from "../../../react-auth0-wrapper";
import Dialog from '@material-ui/core/Dialog';
// import style from './actionDetail.css';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { StylesContext } from "@material-ui/styles";
import backPng from '../../../assets/icons/back_arrow.svg';
import { Link, useParams } from 'react-router-dom';
import { actionColumns, actionOptions, Icons } from "../List/TableSettings";
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import actionPng from '../../../assets/icons/pen.svg';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
// import { useMediaQuery } from 'react-responsive';
import styles from './individual.module.css';
import { config } from "../../../config";

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      width: '100%'
  
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: '10px',
        color: theme.palette.grey[500],
        [theme.breakpoints.down('sm')]: {
          right:'11px',
          top:'10px'
      },
      [theme.breakpoints.down('xs')]: {
        right:'11px',
        top:'2px'
      },
      },

    paper: { minWidth: "56%",
        [theme.breakpoints.down('md')]: {
            minWidth:'62%',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth:'90%',
        },
    },

    finalize_button: {
        marginTop:'0',
        [theme.breakpoints.down('sm')]: {
            marginLeft:'4px',
        },
    },

    opportunities_Icon: {
        fontSize:'21px',
        marginLeft:'4px',
        [theme.breakpoints.down('sm')]: {
            marginLeft:'2px',
        },
    },

    selectEmpty: {
      marginTop: theme.spacing(2),
    },
 

    action: {
        display:'flex',
        justifyContent: "space-between",
        margin:'0',
        padding:'0',
        borderBottom: '1px solid #acacac'
    },

    content: {
        padding:'8px 24px',
        [theme.breakpoints.down('sm')]: {
            padding:'0 24px',
        },
    },

    // finalizeTable: {
    //     table: {
    //         whiteSpace: 'nowrap'
    //     }
    // }
    
  }));

  const customerUrl =async (url) => {
   

    const path = config.API_ENDPOINT + '/api/create/pdf_url'
    const request = {
        method: 'POST',
        headers: { 
            Accept: 'application/json'
        },
        body: JSON.stringify({pdf_path: url})
    };
    const result = await fetch(path, request);
    let obj = await result.json();
    window.open(obj.pdf_path)
   
    // return "https://google.com"
}

const GeneratePDF = (props) => {
    // const item = localStorage.getItem('pdf_url')
    // let url = JSON.parse(item)
    const {opid} = useParams();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('')
    let [lastInspection, setLastInspection] = useState([])
    const [selectedName, setSelectedName] = useState('Roof Inspection Report');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { getTokenSilently } = useAuth0();
    const classes = useStyles();
    const [finalized, setFinalized] = useState({
        creation_data_time: '',
        inspection_no: '',
        note: '',
        opportunity_id: '',
        pdf_path: ''
    })

    // const isTablet = useMediaQuery({ query: '(max-width: 1370px)' });   


    //Performs an HTTP get request to the server
    //Returns a JSON object of the result
    const fetchCommand = async (url, data) => {
        const token = await getTokenSilently();
        const request = {
            method: 'POST',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            },
            body: JSON.stringify(data)
        };
        const result = await fetch(url, request);
        const obj = await result.json();
        return obj;
    }

    const savePDF = async (opp, pdf) => {
        try {
            const path = config.API_ENDPOINT + '/api/opportunities/pdf/' + opp.id;
            const result = await fetchCommand(path, {opp : opp, pdf : pdf});
            localStorage.setItem('pdf_url', JSON.stringify(result.data))
            return result;
        } catch(error) {
            console.error(error);
            return false;
        }
    }

    const saveInspection = async (inspectionData) => {
        try {
            setMessage('Finalising current roof inspection')
            const path = config.API_ENDPOINT + '/api/create/roof/inspection';
            const result = await fetchCommand(path, inspectionData);
            if(result.message) {
                setMessage('')
            }
            return result.message;
        } catch(error) {
            console.error(error);
            return false;
        }
    }
    
    const handleClick = () => {
        setIsDialogOpen(true);
    }

    const handleCloseClick = () => {
        setIsDialogOpen(false);
    }
    useEffect(()=> {
        (async () => {
            const path = config.API_ENDPOINT + '/api/roof/inspections/' + opid;   
        const token = await getTokenSilently();
        const request = {
            method: 'GET',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            }
        };
        const result = await fetch(path, request);
        let obj = await result.json();
        if(!obj.message) return;
        obj  = obj.data.map(result => {
            let obj1 = {
                    creation_data_time: result.fieldData.creation_data_time,
                    note: result.fieldData.note,
                    inspection_no: result.fieldData.inspection_no,
                    pdf_path: result.fieldData.pdf_path
            }
            return obj1
        })
       
        setLastInspection(obj)
        })()
        // return obj;
      }, [loading]);

    const handleSaveClick = async () => {
        // setIsDialogOpen(false);`
     if(finalized.note == undefined || finalized.note == '') { 
        setMessage("Please write some notes")
        setTimeout(() => {
            setMessage(null)
        }, 5000)
        return;
      }
        setLoading(true);
        // setMessage('Generating PDF...');

        //get the list of snapshots
        let snapshot = generateSnapshot(props.map, props.markers, props.snapshotSize);
        let list = generateSnapshotList(props.map, props.markers, props.snapshotSize);
        let markerPhotos = generateMarkerPhotos(props.map, props.photos);

        //Combine photos, markers, and roof sections 
        let combinedList = list.concat(markerPhotos);

        //sort markers and photos
        combinedList.sort(function(a,b) {
            if(a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        });

        //add title
        props.opportunity.reportTitle = selectedName;

        //generate a pdf of them
        let pdf = await generatePDF(props.opportunity, snapshot, combinedList);
        
        setMessage('Finalizing roof inspection...');

        //Save PDF to server
        let result = await savePDF(props.opportunity, pdf);
       
        finalized.pdf_path = result.data.S3Key
        await saveInspection(finalized)
        setLoading(true)
        let reset = finalized;
            reset.note = ''
            reset.opportunity_id = ''
            reset.pdf_path = ''
            reset.creation_data_time = ''
        setFinalized(reset) 
        //Display result to user
        if(result) {
            setMessage('Finalized successfully');
        } else {
            setMessage('Please try again.');
        }

        //Hide result after 5 seconds
        setTimeout(()=> {
            setMessage(null);
        }, 5000);

        setLoading(false);
    }
    // const onFinialized = (event) => {
    //     event.preventDefault();
    //     saveInspection(finalized)
    //     setLoading(true)
    //   }
      
  const  handleChange = (event) => {
    event.preventDefault()
   
    let r = Math.random().toString(36).substring(7);
    let d = new Date();
    let n = d.toLocaleString();
    let value = finalized;
    value.inspection_no = r
    // value[event.target.name] = event.target.value;
    value.creation_data_time = n
    value.opportunity_id = opid;
   
    setFinalized({...finalized, [event.target.name]: event.target.value}, value)
    // setFinalized(value);
  }
  let { note} = finalized; 

    return (
        <>
        <Button variant="contained" color="primary" onClick={handleClick} className={classes.finalize_button}>
            {props.isTabletOrMobile ? 'Action' : 'Action'}
            {/* {!loading && <img src={actionPng} alt='action icon' className={classes.action_icon}/>} */}
            {!loading && <AssignmentTurnedInIcon className={classes.opportunities_Icon} />}
            {loading && <CircularProgress size={20} thickness={4} style={{color: 'white'}}/>}
        </Button>

        <Dialog
            open={isDialogOpen}
            onClose={handleCloseClick}
            aria-labelledby="max-width-dialog-title"
            // fullWidth
            // maxWidth="md"
            classes={{ paper: classes.paper}}
        >
            
            <DialogActions className={classes.action}>

            <DialogTitle id="max-width-dialog-title" className={styles.finalize_title}>Action Detail</DialogTitle>

                <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseClick}>
                    <CloseIcon />
                </IconButton>

            </DialogActions>


            <DialogContent className={classes.content}>
                
                    <Message message ={message}/>
    
                    <div className="container__action">

                        <div className="body__finalize">

                            <h5 className="body__heading">Finalize</h5>
                            <div className="body__content1">
                                <textarea placeholder="Note:" className="content__textarea" name="note" value={note} onChange={e =>  handleChange(e)}></textarea>
                                <div className="content__button">
                                    <Button variant="contained" color="primary" onClick={e => handleSaveClick(e)} disabled={loading}>
                                        Finalize
                                        {loading && <CircularProgress size={24} thickness={4} style={{color: 'white'}}/>}
                                    </Button>
                                </div>
                            </div>
                        </div>


                        <h5 className="body__heading">History</h5>

                        {/* {
                            lastInspection.length &&  */}
                            
                            <MaterialTable
            
                                components={{
                                    Container: props => <Paper {...props} elevation={0}/>
                                }}
                                
                                className={classes.finalizeTable}
                                columns={actionColumns}
                                options={actionOptions}
                                icons={Icons}
                                data={lastInspection}
                                    actions={[
                                        {
                                            icon: Icons.Pdf,
                                            tooltip: 'Save User',
                                            onClick: (event, rowData) => customerUrl(rowData.pdf_path)
                                        }
                                    ]}
                            >
            
                            </MaterialTable>
                        {/* } */}

                    </div>
            
            </DialogContent>
                        
        </Dialog>
        </>
    );
}

export default GeneratePDF;