import React, {useState, useEffect} from "react";
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import lists from './individual.module.css';


const useStyles = makeStyles(theme => ({
  
  photoEditPaper: { 
    minWidth: "40%",
    paddingBotton:'20px',
   [theme.breakpoints.down('sm')]: {
      minWidth:'60%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth:'90%',
    },
  },

  photoEditTextfield: {
    width: '92%',
    display: 'flex',
    margin: '0 auto',
    marginBottom: '18px',
    [theme.breakpoints.down('xs')]: {
      marginBottom:'14px',
      width:'85%',
    },
  },

  photoEditAction: {
    display:'flex',
    justifyContent: 'space-between',
    width:'92%',
    margin:'0 auto',
    marginBottom:'30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection:'row'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection:'column-reverse',
      padding:'18px 13px',
      width:'85%',
      marginBottom:'10px',
    },
  },

  photoEditDeleteButton: {
    width:'50%',
    color:'#fff',
    backgroundColor: '#535b69',
    '&:hover': {
      backgroundColor: '#494f5a',
    },
    [theme.breakpoints.down('xs')]: {
      width:'100%',
      marginBottom:'2%',
      marginLeft:'0'
    },
  },

  photoEditSaveButton: {
    width:'50%',
    color:'#fff',
    backgroundColor: '#24a0ed',
    '&:hover': {
      backgroundColor: '#258fd2',
    },
    [theme.breakpoints.down('xs')]: {
      width:'100%',
      margin: '8px',
      marginTop: '0',
    },
},

}));


const EditDialog = (props) => {
  const classes = useStyles();
    const [description, setDescription] = useState('');

    const styles = theme => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
          // background: '#758196'
        },
        closeButton: {
          position: 'absolute',
          right: '14px',
          top: '10px',
          color: theme.palette.grey[500],
          [theme.breakpoints.down('sm')]: {
            right:'11px',
            top:'4px'
        },
      },
      });
      
      const DialogTitle = withStyles(styles)(props => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography>{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });
      
      const DialogContent = withStyles(theme => ({
        root: {
          // padding: theme.spacing(2),
        },
      }))(MuiDialogContent);
      
      const DialogActions = withStyles(theme => ({
        // root: {
        //   margin: 0,
        //   padding: theme.spacing(1),
        // },
      }))(MuiDialogActions);


    const handleClose = () => {
        props.setDialogOpen(false);
    };

    useEffect(() => {
        if(props.dialogOpen) {
            setDescription(props.selectedPhoto.Description);
        }
    }, [props.dialogOpen]);

    //Update the text description of a photo when changed
    const onChangeHandler = (event) => {
        event.preventDefault();
        setDescription(event.target.value);
    }

    const onSaveHandler = () => {
        props.setDialogOpen(false);
        props.updateDescription(description);
    }

    const modalStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'scroll',
    }

    const dialog = (
      <Dialog classes={{ paper: classes.photoEditPaper}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.dialogOpen}>

        <DialogTitle id="customized-dialog-title" onClose={handleClose} className={lists.photoEdit_dialogTitle}>
            Edit Photo
        </DialogTitle>

        <div className={lists.photoEdit_Content}>

            {/* Display the photo */}

            <div className={lists.photoEdit_ImageWrapper}>
                <img src={props.selectedPhoto.base64Img} />
            </div> 

            {/* Edit the caption */}

            <div className={lists.photoEdit_captionWrapper}>
                <TextField className={classes.photoEditTextfield} label="Add Description" multiline rowsMax="4" onChange={onChangeHandler} value={description} />
            </div>

        </div>

        <DialogActions className={classes.photoEditAction}>
            <Button className={classes.photoEditDeleteButton} onClick={e => props.deletePhoto(props.selectedPhoto.__ID)} variant="contained">
                Delete
            </Button>
            <Button className={classes.photoEditSaveButton} onClick={onSaveHandler} variant="contained">
                Save
            </Button>
        </DialogActions>


      </Dialog>
    );

    return dialog;
}

export default EditDialog;