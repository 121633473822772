import React, { useState, useEffect, } from 'react';
import {CircularProgress, IconButton,Card, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, Checkbox, ListItemText, Divider} from '@material-ui/core';
import theme from '../../themes/theme';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from "../../react-auth0-wrapper";
import Modal from '@material-ui/core/Modal';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      margiRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    paper: {
      margin: theme.spacing(3),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    list: {
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  
  }));




export default function JobimageList(props) {
    const [currentImage, setCurrentImage] = useState(null);
    const [stepImages, setStepImages] = useState([]);
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [imageOpen, setImageOpen] = useState(false);
    const {getTokenSilently} = useAuth0();
    const [checked, setChecked] = useState([0]);
    const [id, setId] = useState("");
    const [checkedBA, setCheckedBA] = useState([0]);
    

        const handleToggle = value => () => {
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];
        
            if (currentIndex === -1) {
              newChecked.push(value);
              value.fieldData._idf_marker = props.currentFeature.getProperty('id');
              value.changed = true;
            } else {
              newChecked.splice(currentIndex, 1);
              value.fieldData._idf_marker = "";
              value.changed = true;
            }
        
            setChecked(newChecked);
          };

          const handleImageClose = () => {
            setImageOpen(false);
          }

          useEffect(() => {
            const newChecked = [0];
            props.images.forEach(element => {
              const currentIndex = checkedBA.indexOf(element);
                  if(element.fieldData.BeforeAfter == "After")
                  {
                      newChecked.push(element);
                  }
    
            });


          
      
          setCheckedBA(newChecked);
    
      }, [props.currentFeature, props.detailsTab]);

      const handleBAToggle = value => () => {
        const currentIndex = checkedBA.indexOf(value);
        const newChecked = [...checkedBA];
    
        if (currentIndex === -1) {
          newChecked.push(value);
          value.fieldData.BeforeAfter = "After";
          value.changed = true;
        } else {
          newChecked.splice(currentIndex, 1);
          value.fieldData.BeforeAfter = "Before";
          value.changed = true;
        }
    
        setCheckedBA(newChecked);
      };

        useEffect(() => {
            if(props.currentFeature  != null)
            {
                setId(props.currentFeature.getProperty('id'));
            }
            const newChecked = [0];
            props.images.forEach(element => {
            const currentIndex = checked.indexOf(element);
            if(props.currentFeature != null)
            {
                if(element.fieldData._idf_marker == props.currentFeature.getProperty('id'))
                {
                    newChecked.push(element);
                }
            }

            });
            
    
        setChecked(newChecked);

    }, [props.currentFeature, props.detailsTab]);

    return (
        <>
        <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}}>
        <List className={classes.list}>
            {props.loading && <CircularProgress></CircularProgress>}
            {!props.loading && <>
            <Divider />
                {props.images.filter(image =>  (image.fieldData._idf_marker == "" || image.fieldData._idf_marker == id || props.filterType == "gallery")).map((image, i) => {
                    const labelId = `checkbox-list-label-${image}`;
                    return (
                        <div key={image.fieldData.__ID}>
                    <ListItem button >
                        {props.currentFeature != null &&
                        <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={checked.indexOf(image) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={handleToggle(image)}
                        />
                        </ListItemIcon>
                        }
                        <ListItemAvatar>
                        <IconButton onClick={() => { setCurrentImage(image.fieldData); setImageOpen(true) }}>
                            <Avatar  style={{backgroundColor:theme.palette.secondary.main}} alt="Remy Sharp" src={image.fieldData.Picture_oAe} />
                        </IconButton>
                        </ListItemAvatar>
                        <ListItemText primary={image.fieldData.Description} />
                        <FormControlLabel
                                    value={image.fieldData.BeforeAfter}
                                    control={<Switch checked={checkedBA.indexOf(image) !== -1} onClick={handleBAToggle(image)} color="secondary" />}
                                    label={image.fieldData.BeforeAfter}
                                    labelPlacement="bottom"
                                  />
                    </ListItem>
                    <Divider />
                    </div>
                )})}
            </>}
        </List>
        </Card>
                      <Modal
                        className={classes.modal}
                        open={imageOpen}
                        onClose={handleImageClose}
                      >
                        <Card style={{maxWidth: '100%', margin: theme.spacing(2), display: 'flex', textAlign: 'center', flexDirection:'column',}}>
                          <>
                          {currentImage != null &&
                            <>
                              <img alt={currentImage.Description} style={{width: '100%',}} src={currentImage.Picture_oAe} />
                              <div style={{margin: theme.spacing(2)}}>{currentImage.Description}</div>
                            </>
                          }
                          </>
                        </Card>
                      </Modal>
                      </>
    );

};