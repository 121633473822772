import React, {useState, useEffect} from 'react';
import {
  CircularProgress,
  Card,
  List,
  ListItem,
  Divider,
  IconButton,
  Button,
  TextField,
  Grid
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { useAuth0 } from "../../../react-auth0-wrapper";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InvoiceMaterialDropdown from "../InvoiceMaterialDropdown";
import InvoiceMaterialUnitsDropdown from "../InvoiceMaterialUnitDropdown";
import ListSubheader from '@material-ui/core/ListSubheader';
import { config } from "../../../config";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%'

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function InvoiceMaterials(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [materiallist, setMaterialList] = useState([]);
    const { isAuthenticated, loginWithRedirect, logout, user, getTokenSilently } = useAuth0();
    const [permissionType, setPermissionType] = useState([]);

    useEffect(() => {
      const getPermissions = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(config.API_ENDPOINT + "/api/getUserPermissions", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
    
          const responseData = await response.json();
          setPermissionType(responseData);
        }
        catch (error) {
          console.error(error);
        }
      };
      getPermissions();
    }, [getTokenSilently, user])

    const changeChoice = (choice, item) => {
        props.setMaterialList(prevState => {
          return [...prevState.map(material => {
            if(material !== item)
            {
              return material;
            }
            else
            {
              material.basePrice = choice.fieldData.PurchaseService_Base_c;
              material.price = choice.fieldData.PurchaseSalePrice_c;
              material.units = choice.fieldData.Unit;
              return material;
            }
          })];
        });
    };




    useEffect(() => {
      const getMaterials = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(config.API_ENDPOINT + "/api/getMaterials", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({id: props.jobId})
          });
    
          const responseData = await response.json();
          if(responseData.message)
          {
            setMaterialList(responseData.data.response.data)
            setLoading(false);
            
            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          }
          else
          {
              setLoading(false);
            //alert(responseData);
          }
  
        } catch (error) {
          console.error(error);
        }
      };
        getMaterials();
    }, [getTokenSilently, props.jobId]);

    useEffect(() => {
      if(props.oldMaterials.length > 0) {
        props.setMaterialList(props.oldMaterials.map( material => {
          return {
            name : material.fieldData.Item,
            qty : material.fieldData.Qty,
            price : material.fieldData.UnitPrice,
            units : material.fieldData.pictureUnit,
            basePrice : material.fieldData.BasePrice,
            direct_cost : material.fieldData.DirectCost
          };
        }));
      }
    }, [props.oldMaterials]);


  return  (
  <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', flexDirection:'column', borderRadius: '0px'}}>
      {!loading && 
          <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}}>
              <List
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Enter the materials used for this job
                  </ListSubheader>
                }
              >
                
                {props.materialList.map((item, i) => {
                  return (
                    <div key={i} >
                    <ListItem style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}} key={i}>
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <InvoiceMaterialDropdown changeValue={(value) => {
                            props.setMaterialList(prevState => {
                              return [...prevState.map(material => {
                                if(material !== item)
                                {
                                  return material;
                                }
                                else
                                {
                                  material.name = value;
                                  return material;
                                }
                              })];
                            }
                          )
                          }} value={item.name} changeChoice={(choice) => { changeChoice(choice, item)}} materials={materiallist} />
                        </Grid>

                      
                      <Grid item xs={2}>
                      <TextField
                        style={{width: '100%'}}
                        label="Quantity"
                        onChange={(event) =>
                          {
                            event.persist();
                          props.setMaterialList(prevState => {
                            return [...prevState.map(material => {
                              if(material !== item)
                              {
                                return material;
                              }
                              else
                              {
                                material.qty = event.target.value;
                                return material;
                              }
                            })];
                          }
                        )}}
                        value={item.qty}
                      >

                      </TextField>

                    </Grid>
                    <Grid item xs={2}>
                      <InvoiceMaterialUnitsDropdown
                            changeValue={(value) => {
                              props.setMaterialList(prevState => {
                                return [...prevState.map(material => {
                                  if(material !== item)
                                  {
                                    return material;
                                  }
                                  else
                                  {
                                    material.units = value;
                                    return material;
                                  }
                                })];
                              })
                            }}
                            value={item.units}
                            changeChoice={() => {}}
                            materials={[...new Set(materiallist.map(material => { return material.fieldData.Unit }))]}
                            label="Unit of Measurement"
                            placeholder="Unit of Measurement"
                          />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={(item) => { props.setMaterialList(prevState => {
                          return prevState.filter((val, k) => { return k !== i;})
                      })}}>
                        <DeleteForeverIcon />
                      </IconButton>
                        </Grid>
                    </Grid>
                    </ListItem>
                    <Divider />
                        
                    </div>
                    );
                })}


            </List>
            <Button style={{margin:'25px'}} onClick={() => {
                  props.setMaterialList(prevState => {return [...prevState, {name:"", price:"", qty: "", units: ""}]})
              }} variant="contained" color="primary">Add Row</Button>
          </Card>
      }
      {loading && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}
  </div>
  );
};