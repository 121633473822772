import React, {useState, useEffect} from "react";
import { useAuth0 } from "../../../react-auth0-wrapper";
import EditDialog from './PhotoEditDialog';
import GridList from '@material-ui/core/GridList';
import {CircularProgress, colors} from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import EditIcon from '@material-ui/icons/Edit';
import { config } from "../../../config";

const PhotoList = (props) => {
    const { getTokenSilently } = useAuth0();
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        setPhotos(prevState => {
            var markerId = props.selectedFeature.getProperty('id');
            return props.photos.filter(function(photo) {
                return photo._idf_marker === markerId;
            })
        });
    }, [props.selectedFeature, props.photos]);

    const fetchCommand = async (url, method = '') => {
        const token = await getTokenSilently();
        const headers = { headers: { Authorization: 'Bearer ' + token } };
        const result = await fetch(url, headers);
        const obj = await result.json();
        return obj;
    }

    const deleteCommand = async (url) => {
        const token = await getTokenSilently();
        const request = {
            method: 'DELETE',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            },
            body: {}
        };
        const result = await fetch(url, request);
        const obj = await result.json();
        return obj;
    }

    const updateCommand = async (url, data) => {
        const token = await getTokenSilently();
        const request = {
            method: 'POST',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            },
            body: JSON.stringify(data)
        };

        const result = await fetch(url, request);
        const obj = await result.json();
        return obj;
    }

    const deletePhoto = async (value) => {
        if(window.confirm('Are you sure you want to delete this photo?'))
            try {
                props.setLoadingPhotos(true);
                const path = config.API_ENDPOINT + '/api/opportunities/' + props.opportunity.id + '/photos/' + value;
                const result = await deleteCommand(path);

                //if data successfully fetched
                if(result.message) {
                    props.setReloadPhotos(true);
                    props.setEditOpen(false);
                } else {
                    props.setLoadingPhotos(false);
                    props.setEditOpen(false);
                }
            } catch(error) {
                console.error(error);
            }

    }

    const editPhoto = (photo) => {
        props.setSelectedPhoto(photo);
        props.setEditOpen(true);
    }

    const updateDescription = async (description) => {
        try {
            props.setLoadingPhotos(true);
            const path = config.API_ENDPOINT + '/api/opportunities/' + props.opportunity.id + '/photos/' + props.selectedPhoto.__ID;
            const result = await updateCommand(path, { description : description });

            //if data successfully fetched
            if(result.message) {
                props.setReloadPhotos(true);
            } else {
                props.setLoadingPhotos(false);
            }
        } catch(error) {
            console.error(error);
        }
    }

    return (
        <>
         <div /* style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}*/>
            {/* <br /> */}

            <GridList cellHeight={90} cols={3}>
                {photos.map(photo => (
                    <GridListTile key={photo.recordId} cols={1} onClick={e => editPhoto(photo)} style={{cursor:'pointer'}}>
                        {/* <div style={{marginRight: '1%'}}> */}
                        {/* <div style={{position: 'relative', width: '100%'}}> */}
                            <div>
                                <EditIcon /> Edit/Delete
                            </div>
                             <img style={{width:'100%', height:'100%'}} src={photo.base64Img}/* onClick={e => editPhoto(photo)}*/ />
                        {/* </div> */}
                    
                    <p style={{position:'fixed', marginTop:'-17px', width: '10%'}}>{photo.Description}</p>
                    {/* </div> */}
                </GridListTile>
                ))}

                {/* {photos.map(photo => (
                <GridListTile key={photo.recordId} cols={1}> 
                   
                  <p>{photo.Description}</p>
                </GridListTile>
                ))} */}
            </GridList>

            {/* Loading */}
            {props.loadingPhotos && <div><CircularProgress></CircularProgress></div>}
        </div>
        <EditDialog
            dialogOpen={props.editOpen}
            setDialogOpen={props.setEditOpen}
            selectedPhoto={props.selectedPhoto}
            setSelectedPhoto={props.setSelectedPhoto}
            updateDescription={updateDescription}
            deletePhoto={deletePhoto}
        />
        </>
    );
}

export default PhotoList;


