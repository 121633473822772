import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import PhotoList from './PhotoList'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { useMediaQuery } from 'react-responsive'
import lists from './individual.module.css'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    width: '100%',
    // marginTop:'8px',
    fontWeight: '500',
    marginBottom: '22px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '4px'
    }
  },

  edit_primaryButton: {
    color: 'white',
    backgroundColor: '#24a0ed',
    width: '100%',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: '#258fd2'
    }
  }
}))

const EditMobile = props => {
  const classes = useStyles()
  const [roofType, setRoofType] = useState('')
  const roofType1 = [
    { id: 1, name: 'BUR' },
    { id: 2, name: 'TPO rhino bond' },
    { id: 3, name: 'EPDM ballast' },
    { id: 4, name: 'TPO fully adhered' },
    { id: 5, name: 'EPDM fully adhered' }
  ]
  const [yearType, setRoofYear] = useState([])
  const years = []
  for (let i = 1; i <= 30; i++) {
    years.push(i)
  }
  const [descriptionHeight, setDescriptionHeight] = useState('150px')
  const styles = theme => ({
    root: {
      position: 'relative',
      // padding: '2% 4%',
      width: '100%',
      margin: '0 auto'
    },

    closeButton: {
      position: 'absolute',
      right: '14px',
      top: '10px',
      color: theme.palette.grey[500],
      [theme.breakpoints.down('sm')]: {
        right: '11px',
        top: '10px'
      },
      [theme.breakpoints.down('xs')]: {
        right: '11px',
        top: '4px'
      }
    }
  })

  const btnStyle = {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#24a0ed',
    width: '100%'
  }

  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent)

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1)
    }
  }))(MuiDialogActions)

  const handleClose = () => {
    props.setDialogOpen(false)
  }

  return (
    <div>
      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby='simple-dialog-title'
        open={props.dialogOpen}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
          className={lists.photoEdit_dialogTitle}
        >
          {props.selectedFeature &&
          props.selectedFeature.getProperty('isMarker') === undefined
            ? 'Roof Section ' +
              (Number(props.selectedFeature.getProperty('markerIndex')) + 1)
            : ''}
          {props.selectedFeature &&
          props.selectedFeature.getProperty('isMarker') !== undefined
            ? 'Marker ' + props.selectedFeature.getProperty('label')
            : ''}
        </DialogTitle>

        <div className={lists.editmobile_wrapper}>
          <FormControl className={classes.formControl}>
            Roof Type
            <Select
              value={props.roofType}
              onChange={props.setMarkerRoofType}
              inputProps={{
                name: 'roofType',
                id: 'roofType'
              }}
            >
              {roofType1.map((roofs, index) => (
                <MenuItem value={roofs.id} key={index}>
                  {roofs.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {'  '}

          <FormControl className={classes.formControl}>
            Suggested Year of Replacement Cost
            <Select
              value={props.yearType}
              onChange={props.setMarkerRemainingYear}
              inputProps={{
                name: 'yearType',
                id: 'yearType'
              }}
            >
              {years.map((year, index) => (
                <MenuItem value={year} key={index}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div>
            <p className={lists.editmobile_label}>Est. Replacement Cost</p>
            <TextField
              className={lists.fullWidth}
              onFocus={e => {
                props.setTextBoxFocus(true)
              }}
              onBlur={e => {
                props.setTextBoxFocus(false)
              }}
              onChange={props.setMarkerEstReplacement}
              value={props.replacement}
              id='replacement_cost'
            />
          </div>

          <div className={lists.editmobile_spacing}>
            <p className={lists.editmobile_label}>Est. Annual Repair Cost</p>
            <TextField
              className={lists.fullWidth}
              onChange={props.setMarkerEstRepair}
              value={props.repair}
              onFocus={e => {
                props.setTextBoxFocus(true)
              }}
              onBlur={e => {
                props.setTextBoxFocus(false)
              }}
              id='repair_cost'
            />
          </div>

          <div className={lists.editmobile_spacing}>
            <p className={lists.editmobile_label}>
              Conditon of Roof/Description
            </p>
            <textarea
              onChange={props.setMarkerDescription}
              onFocus={e => {
                props.setTextBoxFocus(true)
              }}
              onBlur={e => {
                props.setTextBoxFocus(false)
              }}
              value={props.description}
              style={{
                width: '100%',
                height: '70px',
                descriptionHeight,
                boxSizing: 'border-box',
                padding: '8px',
                marginTop: '10px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
              }}
            ></textarea>
          </div>

          <div className={lists.editmobile_spacing}>
            <p className={lists.editmobile_label}>
              Cost of Repair for this Section
            </p>

            <TextField
              className={lists.fullWidth}
              onFocus={e => {
                props.setTextBoxFocus(true)
              }}
              nBlur={e => {
                props.setTextBoxFocus(false)
              }}
              onChange={props.setMarkerCostOfRepair}
              value={props.repairCost}
              id='to_cost'
            />
          </div>

          {/* <div className={lists.editmobile_spacing}>
              <p className={lists.editmobile_label}>Photos</p>
            <div className={lists.editMobile_photos}>

                <PhotoList 
                    photos={props.photos} 
                    setPhotos={props.setPhotos} 
                    loadingPhotos={props.loadingPhotos} 
                    setLoadingPhotos={props.setLoadingPhotos} 
                    reloadPhotos={props.reloadPhotos}
                    setReloadPhotos={props.setReloadPhotos}
                    opportunity={props.opportunity}
                    editOpen={props.editOpen}
                    setEditOpen={props.setEditOpen}
                    selectedPhoto={props.selectedPhoto}
                    setSelectedPhoto={props.setSelectedPhoto}
                    selectedFeature={props.selectedFeature}
                />
            </div>
            </div>

            <div className={lists.edit_Button}>
                <Button onClick={e => props.setPhotoDialogOpen(true)} className={classes.edit_primaryButton}>Add Photo</Button>
            </div> */}
        </div>
      </Dialog>
    </div>
  )
}

export default EditMobile
