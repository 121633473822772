import React, {useState, useEffect} from "react";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import styles from './reportSelect.module.css';


const DateSelect = (props) => {

    const handleDateChange = (date) => {
        props.setDateOfWork(date);
    }

    const selectStyle = {
        width : '100%',
        fontSize:'15px',
        marginTop:'1%',
    }


    return (
        <div>
            <p className={styles.datePara}>Date of work performed</p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    
                    format="MM/dd/yyyy"
                    value={props.dateOfWork}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={selectStyle}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default DateSelect;