import React from "react";

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    style = {
        color: "white",
        backgroundColor: "rgba(0,0,0,.75)",
        padding: "10px",
        position: "fixed",
        top: "150px",
        zIndex: "3",
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "500px"
    };

    render() {
        if(this.props.message) {
            return <div style={this.style}>{this.props.message}</div>;
        } else {
            return null;
        }
    }
}

export default Message;