import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Upload from './Upload';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import lists from './individual.module.css';

const useStyles = makeStyles(theme => ({
  dialogPaper: { 
    minWidth: "40%",
   [theme.breakpoints.down('sm')]: {
      minWidth:'60%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth:'90%',
    },
  },
}));

const PhotoDialog = (props) => {

  const classes = useStyles();

    const styles = theme => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
          width:'40%',
          margin:'0 auto',
          position:'relative',
          marginTop:'1%',
          
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: '10px',
          color: theme.palette.grey[500],
          [theme.breakpoints.down('sm')]: {
            right:'11px',
            top:'10px'
        },
        [theme.breakpoints.down('xs')]: {
          right:'11px',
          top:'4px'
        },
        },
      });
      
      const DialogTitle = withStyles(styles)(props => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography>{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });
      
      const DialogContent = withStyles(theme => ({
        root: {
          padding: theme.spacing(2),
          borderBottom:'1px solid #acacac',
        },
      }))(MuiDialogContent);
      
      const DialogActions = withStyles(theme => ({
        root: {
          margin: 0,
          padding: theme.spacing(1),
          borderBottom:'1px solid #acacac'
        },
      }))(MuiDialogActions);


    const handleClose = () => {
        props.setDialogOpen(false);
    };

    return (

           <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.dialogOpen} classes={{ paper : classes.dialogPaper}}>

            <DialogTitle className={lists.photoDialog_title} id="customized-dialog-title" onClose={handleClose}>
                  Add Photo
                {/* photo popup for opportunities page only */}
            </DialogTitle>

            {/* Form for Uploading Photos */}
            <Upload 
                roofapi={props.roofapi}
                opportunity={props.opportunity} 
                dialogOpen={props.dialogOpen} 
                reloadPhotos={props.reloadPhotos}
                setReloadPhotos={props.setReloadPhotos}
                setDialogOpen={props.setDialogOpen} 
                feature={props.feature}
            />  
        </Dialog>
    );
}

export default PhotoDialog; 