import React, { useState, useEffect } from 'react'
import { useAuth0 } from '../../react-auth0-wrapper'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Fab from '@material-ui/core/Fab'
import { green, red } from '@material-ui/core/colors'
import {
  CircularProgress,
  Card,
  Typography,
  CardContent
} from '@material-ui/core'
import theme from '../../themes/theme'
import openSocket from 'socket.io-client'
import Button from '@material-ui/core/Button'
import Compress from 'compress.js'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { config } from '../../config'

const theme2 = createMuiTheme({
  palette: {
    primary: green,
    secondary: red
  }
})

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
    width: '150px',
    height: '150px',
    fontSize: '20px',
    fontWeight: 'bold'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  card: {
    minWidth: 275,
    background: '#f3f3f3',
    // width:'90%',
    textAlign: 'center',
    padding: theme.spacing(2)
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    // fontSize: 14,
    fontSize: '1rem',
    // fontFamily: "Roboto,Helvetica,Arial,sans-serif',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
    color: '#000'
  },
  pos: {
    marginBottom: 12
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    // width: 'fit-content',
    width: '70%'
  },
  formControl: {
    //marginTop: theme.spacing(2),
    minWidth: 160,
    width: '100%'
  },
  formControlLabel: {
    //marginTop: theme.spacing(1),
  }
}))

const btnStyle = {
  color: 'white',
  fontWeight: 'bold',
  // backgroundColor: '#24a0ed',
  // marginLeft: '2.5px',
  // marginRight: '2.5px',
  width: '100%'
}

export default function Timecard (props) {
  const classes = useStyles()
  const [clockedIn, setClockedIn] = useState(false)
  const [loading1, setLoading1] = useState(true)
  const [infoLoaded, setInfoLoaded] = useState(false)
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    getTokenSilently,
    user,
    loading
  } = useAuth0()
  const [timecardData, setTimecardData] = useState(null)
  const [newLoading, setNewLoading] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [workType, setWorkType] = useState('')

  useEffect(() => {
    const connect = async () => {
      let token = await getTokenSilently()
      const socket = await openSocket(config.API_ENDPOINT, {
        query: 'token=' + token
      })
      socket.on('update', newData => {
        setTimecardData(newData.data)
        localStorage.setItem('timecardData', JSON.stringify(newData.data))
        if (newData.data.fieldData.TimeEnd_t != '') {
          setClockedIn(false)
        } else {
          setClockedIn(true)
        }

        setWorkType(newData.data.fieldData.WorkType)
      })
    }
    if (!loading && isAuthenticated) {
      connect()
    }
  }, [loading, isAuthenticated, getTokenSilently])

  const compressImage = image => {
    return new Promise((resolve, reject) => {
      //Testing image compression
      const compress = new Compress()

      compress
        .compress([image], {
          size: 0.25, // the max size in MB, defaults to 2MB
          quality: 0.75, // the quality of the image, max is 1,
          maxWidth: 400, // the max width of the output image, defaults to 1920px
          maxHeight: 400, // the max height of the output image, defaults to 1920px
          resize: true // defaults to true, set false if you do not want to resize the image width and height
        })
        .then(compressedImages => {
          var img1 = compressedImages[0]
          var base64str = img1.data
          var imgExt = img1.ext
          var file = Compress.convertBase64ToFile(base64str, imgExt)

          resolve(file)
        })
    })
  }

  const changeStatus = () => {
    if (!clockedIn && !workType) {
      alert('You must select a work type before clocking in.')
      return
    }

    /* HPDS Rennel Parino 10/25/2023 A - Temporary deprecation per client request 
    if (!clockedIn && !selectedPhoto) {
      alert('You must add a photo of yourself on the roof before clocking in.')
      return
    }*/

    if (!loading1) {
      if (timecardData == null) {
        setNewLoading(true)
        localStorage.removeItem('timecardData')
        setLoading1(true)
        const createTimecard1 = async data => {
          const formData = new FormData()

          //Compress selected photo
          /* HPDS Rennel Parino 10/25/2023 A - Temporary deprecation per client request
          if (selectedPhoto && !clockedIn) {
            let compressedFile = await compressImage(selectedPhoto)
            let filename = selectedPhoto.name
            formData.append('photo', compressedFile, filename)
          }*/

          formData.append('jsonData', JSON.stringify(data))

          try {
            const token = await getTokenSilently()
            const response = await fetch(
              config.API_ENDPOINT + '/api/createTimecard',
              {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  //'Content-Type': 'application/json',  // sent request
                  Accept: 'application/json' // expected data sent back
                },
                body: formData
              }
            )

            const responseData = await response.json()
            if (responseData.message) {
              setInfoLoaded(false)
              setNewLoading(false)
              //setJobDetails(responseData.data.response.data[0].fieldData);
              //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
            } else {
              alert(
                'THERE WAS AN ERROR AND YOU WERE NOT CLOCKED IN. PLEASE CONTACT YOUR SUPERVISOR '
              )
            }
          } catch (error) {
            console.error(error)
          }
        }
        let data = {}
        data._idf_job = props.jobId
        data._idf_changeOrder = ''
        data.WorkType = workType
        data.flag_ChangeOrder = ''
        data.SystemTask_Lu = ''
        createTimecard1(data)
      } else if (timecardData.fieldData._idf_job != props.jobId) {
        setNewLoading(true)
        localStorage.removeItem('timecardData')
        setLoading1(true)
        const createTimecard1 = async data => {
          const formData = new FormData()

          //Compress selected photo
          /* HPDS Rennel Parino 10/25/2023 A - Temporary deprecation per client request
          if (selectedPhoto && !clockedIn) {
            let compressedFile = await compressImage(selectedPhoto)
            let filename = selectedPhoto.name
            formData.append('photo', compressedFile, filename)
          }*/

          formData.append('jsonData', JSON.stringify(data))

          try {
            const token = await getTokenSilently()
            const response = await fetch(
              config.API_ENDPOINT + '/api/createTimecard',
              {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  //'Content-Type': 'application/json',  // sent request
                  Accept: 'application/json' // expected data sent back
                },
                body: formData
              }
            )

            const responseData = await response.json()
            console.log(responseData, 'response data Timecard.js 252')

            if (responseData.message) {
              setInfoLoaded(false)
              setNewLoading(false)
              //setJobDetails(responseData.data.response.data[0].fieldData);
              //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
            } else {
              //alert(responseData);
            }
          } catch (error) {
            console.error(error)
          }
        }
        let data = {}
        data._idf_job = props.jobId
        data._idf_changeOrder = ''
        data.WorkType = workType
        data.flag_ChangeOrder = ''
        data.SystemTask_Lu = ''
        createTimecard1(data)
      } else {
        localStorage.removeItem('timecardData')
        setLoading1(true)
        const getTimecardData = async () => {
          const formData = new FormData()

          //Compress selected photo
          /* HPDS Rennel Parino 10/25/2023 A - Temporary deprecation per client request
          if (selectedPhoto && !clockedIn) {
            let compressedFile = await compressImage(selectedPhoto)
            let filename = selectedPhoto.name
            formData.append('photo', compressedFile, filename)
          }*/

          timecardData.fieldData.WorkType = workType

          console.log('timecardData', timecardData)

          formData.append('jsonData', JSON.stringify(timecardData))

          try {
            const token = await getTokenSilently()
            const response = await fetch(config.API_ENDPOINT + '/api/clockInOut', {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                //'Content-Type': 'application/json',  // sent request
                Accept: 'application/json' // expected data sent back
              },
              body: formData
            })
            const responseData = await response.json()
            if (responseData.message) {
              setInfoLoaded(false)
              //setJobDetails(responseData.data.response.data[0].fieldData);
              //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
            } else {
              setInfoLoaded(false)
              //alert(responseData);
            }

            setSelectedPhoto(null)
          } catch (error) {
            console.error(error)
          }
        }
        getTimecardData()
      }
    }
  }

  useEffect(() => {
    if (!infoLoaded) {
      if (localStorage.getItem('timecardData')) {
        setInfoLoaded(true)
        setLoading1(false)

        var localData = JSON.parse(localStorage.getItem('timecardData'))

        setTimecardData(localData)
        if (localData.fieldData.TimeEnd_t != '') {
          setClockedIn(false)
        } else {
          setClockedIn(true)
        }

        setWorkType(localData.fieldData.WorkType)
      }
      const getTimecardData = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(
            config.API_ENDPOINT + '/api/getTimecardData',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // sent request
                Accept: 'application/json' // expected data sent back
              },
              body: null
            }
          )

          const responseData = await response.json()
          if (responseData.message) {
            if (responseData.data.fieldData.TimeEnd_t != '') {
              setClockedIn(false)
            } else {
              setClockedIn(true)
            }
            setInfoLoaded(true)
            setLoading1(false)
            localStorage.setItem(
              'timecardData',
              JSON.stringify(responseData.data)
            )
            setTimecardData(responseData.data)

            setWorkType(responseData.data.response.data[0].fieldData.WorkType)
            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          } else {
            setClockedIn(false)
            localStorage.removeItem('timecardData')
            setInfoLoaded(true)
            setTimecardData(null)
            setLoading1(false)
            //alert(responseData);
          }
        } catch (error) {
          console.error(error)
        }
      }
      getTimecardData()
    }
  }, [getTokenSilently, infoLoaded])

  const onImageSelect = event => {
    //Verify image file
    if (event.target.files.length > 0) {
      setSelectedPhoto(event.target.files[0])
    } else {
      setSelectedPhoto(null)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {!newLoading && (
        <>
          <Card
            className={classes.card}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CardContent>
              <Typography
                className={classes.title}
                color='textSecondary'
                gutterBottom
              >
                Track Time For This Job
              </Typography>
            </CardContent>
            {props.jobDetails && props.jobDetails.FixItStatus === 'Invoiced' ? (
              <>
                <div style={{ color: 'red' }}>
                  WARNING: This job has already been invoiced.
                  <br />
                  <br />
                </div>
              </>
            ) : (
              ''
            )}

            <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor='workType'>Work type</InputLabel>
                <Select
                  value={workType}
                  onChange={event => {
                    setWorkType(event.target.value)
                  }}
                  inputProps={{
                    name: 'workType',
                    id: 'workType'
                  }}
                  style={{ width: '100%' }}
                  disabled={clockedIn}
                >
                  <MenuItem value='Paver Installation'>
                    Paver Installation
                  </MenuItem>
                  <MenuItem value='RFG Field'>RFG Field</MenuItem>
                  <MenuItem value='RFG Delivery'>RFG Delivery</MenuItem>
                  <MenuItem value='RFG PM'>RFG PM</MenuItem>
                  <MenuItem value='RFG Supervisor'>RFG Supervisor</MenuItem>
                  <MenuItem value='SM Field'>SM Field</MenuItem>
                  <MenuItem value='SM Shop'>SM Shop</MenuItem>
                  <MenuItem value='SM Delivery'>SM Delivery</MenuItem>
                  <MenuItem value='SM PM'>SM PM</MenuItem>
                </Select>
              </FormControl>
            </form>
            <br />

             <div>
              <input
                accept='image/*'
                style={{ display: 'none' }}
                id='raised-button-file'
                multiple
                type='file'
                onChange={onImageSelect}
              />
              <label htmlFor='raised-button-file'>
                <Button
                  variant='contained'
                  color='primary'
                  component='span'
                  style={{ textTransform: 'capitalize', display: 'none' }}
                >
                  Add Photo On Roof{' '}
                  {selectedPhoto ? <span>&nbsp;&#10003;</span> : ''}
                </Button>
              </label>
            </div> 
            <br />
            <ThemeProvider theme={theme2}>
              <Fab
                disabled={
                  loading1
                    ? true
                    : timecardData !== null
                    ? timecardData.fieldData._idf_job !== props.jobId &&
                      timecardData.fieldData.TimeEnd_t == ''
                      ? true
                      : false
                    : false
                }
                onClick={() => {
                  changeStatus()
                }}
                style={
                  !clockedIn
                    ? {
                        background: 'rgb(11 135 206)',
                        color: 'white',
                        textTransform: 'capitalize'
                      }
                    : {
                        background: '#f44336',
                        color: 'white',
                        textTransform: 'capitalize'
                      }
                }
                className={classes.fab}
              >
                {loading1 && (
                  <ThemeProvider theme={theme}>
                    <CircularProgress></CircularProgress>
                  </ThemeProvider>
                )}
                {!loading1 && <>{!clockedIn ? 'Clock In' : 'Clock Out'}</>}
              </Fab>
            </ThemeProvider>
            {(loading1
              ? false
              : timecardData !== null
              ? timecardData.fieldData._idf_job !== props.jobId &&
                timecardData.fieldData.TimeEnd_t == ''
                ? true
                : false
              : false) && (
              <span>
                <b>
                  You are clocked into another job and must clock out before
                  clocking in to this job!
                </b>
              </span>
            )}
          </Card>
        </>
      )}
      {newLoading && <CircularProgress></CircularProgress>}
    </div>
  )
}
