import React, {useState, useEffect} from 'react';
import {Card, TextField, Button, InputLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AuthConfig from '../../auth_config.json';

const useStyles = makeStyles(theme => ({
    textField: {
    }
}));

export default function InvoiceImages(props) {

    useEffect(() =>
    {
        
        if(props.mapData != [] && props.center != null)
        {
            var mapObject = {
                key: AuthConfig.staticMapKey,
                size: "400x400",
                zoom: props.zoom,
                center: [props.center.lat, props.center.lng],
                maptype: "satellite",
                markers: [],
                roofSections: []
            }
            //console.log(props.zoom);
            for (var key in props.mapData) {
                var feature = props.mapData[key];
                if(props.features.filter(image => image.getProperty('id') == key).length > 0)
                {
                    if(feature.type == "Point")
                    {
                        //"color:red|label:S|40.714,-73.998","color:red|label:S|40.7,-73.97"


                        let color = 'red';
                        let label = feature.label[0];

                        if(feature.label === 'Job') {
                            color = '0x00BFFF';
                            label = ''
                        }

                        var newMarker = "color:"+ color +"|label:" + label;
                        feature.coords.forEach((latlng) => {
                            newMarker += "|" + latlng.lat() + "," + latlng.lng();
                        });
                        mapObject.markers.push(newMarker);
                    }
                    else
                    {
                        var newPath = "color:0x00000000"  + "|fillcolor:0x" + feature.color.substring(1) + "|weight:5"
                        feature.coords.forEach((latlng) => {
                            newPath += "|" + latlng.lat() + "," + latlng.lng();
                        });
                        mapObject.roofSections.push(newPath);
                    }
                }

            }

            if(mapObject.markers.length > 0)
            {
                var newMarker = "";
                mapObject.markers.forEach((marker, i) => {
                    if(i > 0)
                    {
                        newMarker +=  "&markers=" + encodeURIComponent(marker);
                    }
                    else
                    {
                        newMarker = "markers=" + encodeURIComponent(marker);
                    }
        
                });
                mapObject.markers = newMarker;
            }
            if(mapObject.roofSections.length > 0)
            {
                var newMarker = "";
                mapObject.roofSections.forEach((marker, i) => {
                    if(i > 0)
                    {
                        newMarker +=  "&path=" + encodeURIComponent(marker);
                    }
                    else
                    {
                        newMarker = "path=" + encodeURIComponent(marker);
                    }
        
                });
                mapObject.roofSections = newMarker;
            }
            //var queryString = Object.keys(mapObject).map(key => key + '=' + mapObject[key]).join('&');
            var queryString = Object.keys(mapObject).map((key) => {
                if(key == "markers")
                {
                    return mapObject[key];
                }
                else if (key == "roofSections")
                {
                    return mapObject[key];
                }
                else
                {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(mapObject[key]);
                }
        
            }).join('&');
            //"&path=color:0x00000000|fillcolor:0xFF000088|weight:5|40.737102,-73.990318|40.74,-73.98|40.752946,-73.987384"
            setUrl("https://maps.googleapis.com/maps/api/staticmap?" + queryString);
            //console.log(queryString);
        }
    }, [props.mapData, props.center, props.selectedImages, props.features]);

    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");
    const classes = useStyles();

    function handleTextFieldChange(event)
    {
        setDescription(event.target.value);
        
    }

    const generateImage = () => {
        props.mapRef.data.forEach(feature => {
            console.log(feature.getProperty('id'));
        });
    }
    const createInvoice = (type) => {
        props.createInvoice(url, description, type);
    }


    return (
        <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', flexDirection:'column', borderRadius: '0px'}}>
            <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px', paddingBottom: '20px'}}>
                <div style={{margin: '20px'}}>
                <InputLabel>Description of Work Performed</InputLabel>
                <br />
                <TextField value={description} onChange={handleTextFieldChange}  multiline rows={3} variant='outlined' fullWidth className={classes.textField} placeholder="Description of Work Performed">

                </TextField>
                </div>
                <div id="mapImage">
                    {props.tab == 4 &&
                        <img src={url} style={{height:'400px'}} />
                    }
                    
                </div>
                <br />
                <div>
                    <Button onClick={() => createInvoice('simple')} variant="contained" color="secondary">
                        Preview Simple Invoice
                    </Button>
                </div>
                <br />
                <div>
                    <Button onClick={() => createInvoice('complex')} variant="contained" color="secondary">
                        Preview Complex Invoice
                    </Button>
                </div>
            </Card>

        </div>
    )
}