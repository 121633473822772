import WhiteCircle from "../../../assets/images/white_circle_small.png";

const Options = {
    streetViewControl: false,
    mapTypeControl: false,
    mapTypeId: "hybrid",
    rotateControl: false,
    gestureHandling: 'greedy',
    zoomControl: window.innerHeight > 600 ? true : false,
    fullscreenControl: false
};

const ContainerStyle = {
    height: "100%", 
    width: "100%"
}

const Zoom = 17;

const Tilt = 0;

const MarkerOptions = {
   icon:  {url : WhiteCircle}
};

const Colors = [
    'red',
    'green',
    'yellow',
    'blue',
    'orange',
    'purple'
];

export {Options, ContainerStyle, Zoom, Tilt, MarkerOptions, Colors};