import React, { Component, useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { IconButton, ListItemSecondaryAction, Input, MenuItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { Redirect } from 'react-router';
import Select from '@material-ui/core/Select';
import { config } from "../../config";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
  textField: {
    //marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(1),
    width: '100%',
  },
}));

const alertDetailDiv = {textAlign: "center"};
const alertDetailHeader = {textAlign: "center", color: "#00a2ff"};

const JobDetails = (props) => {
  const classes = useStyles();
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [showResult, setShowResult] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);
  const [notes, setNotes] = useState('');
  const [notesFromField, setNotesFromField] = useState('');
  const { getTokenSilently } = useAuth0();
  const [update, setUpdate] = useState(false);
  const [address_Street1, setAddress_Street1] = useState('');
  const [address_Street2, setAddress_Street2] = useState('');
  const [address_State, setAddress_State] = useState('');
  const [address_City, setAddress_City] = useState('');
  const [address_Zip, setAddress_Zip] = useState('');
  const [addressEdit, setAddressEdit] = useState(false);
  const [jobNameEdit, setJobNameEdit] = useState(false);
  const [jobName, setJobName] = useState("");
  const [jobTypeEdit, setJobTypeEdit] = useState(false);
  const [jobType, setJobType] = useState("");
  const [redirect, setRedirect] = useState(false);


  function handleNoteChange(name) {
    setNotes(name.target.value);
  };

  function handleNoteFromFieldChange(name) {
    setNotesFromField(name.target.value);
  };


  useEffect(() => {
    const callApi = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(config.API_ENDPOINT + "/api/updateJobDetails", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: JSON.stringify({data: jobDetails})
        });
  
        const responseData = await response.json();
        console.log(responseData);
        if(responseData.message)
        {
          setUpdate(false);
          setShowResult(false);
          
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        }
        else
        {
          //alert(responseData);
        }

      } catch (error) {
        console.error(error);
        //setShowResult(false);
      }
    };
    if(update)
    {
      callApi();
    }
  }, [jobDetails, update, getTokenSilently]);

  useEffect(() => {

    if(!showResult)
    {
      
      const callApi = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(config.API_ENDPOINT + "/api/getJobDetails", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({id: props.__ID})
          });
    
          const responseData = await response.json();
          if(responseData.message)
          {

            setJobDetails(responseData.data.response.data[0].fieldData);
            props.setJobDetails(responseData.data.response.data[0].fieldData);
            setShowResult(true);
            setJobName(responseData.data.response.data[0].fieldData.Job_Name);
            setJobType(responseData.data.response.data[0].fieldData.JobType);
            setNotes(responseData.data.response.data[0].fieldData.TechNotes);
            setNotesFromField(responseData.data.response.data[0].fieldData.TechNotesFromField)
            setAddress_Street1(responseData.data.response.data[0].fieldData.address_Street1_Ae);
            setAddress_Street2(responseData.data.response.data[0].fieldData.address_Street2_Ae);
            setAddress_State(responseData.data.response.data[0].fieldData.address_State_Ae);
            setAddress_Zip(responseData.data.response.data[0].fieldData.address_Zip_Ae);
            setAddress_City(responseData.data.response.data[0].fieldData.address_City_Ae);
          }
          else
          {
            alert('Job not found.');
            setRedirect(true);
          }

        } catch (error) {
          console.error(error);
          setShowResult(false);
        }
      };
        callApi();
    }
},[getTokenSilently, props.__ID, showResult]);

function handleJobNameChange(value)
{
  setJobName(value.target.value);
}

function handleJobTypeChange(value)
{
  setJobType(value.target.value);
}

function handleStreet1Change(value)
{
  setAddress_Street1(value.target.value);
}
function handleStreet2Change(value)
{
  setAddress_Street2(value.target.value);
}
function handleStateChange(value)
{
  setAddress_State(value.target.value);
}
function handleZipChange(value)
{
  setAddress_Zip(value.target.value);
}
function handleCityChange(value)
{
  setAddress_City(value.target.value);
}

const getContacts = () => {
  return (
    <>
      {jobDetails.JobSite_ContactName === '' ? '' : 
        <>
        Job Site - <br/>
        {jobDetails.JobSite_ContactName} <br />
          <a href={"tel:" + jobDetails.JobSite_ContactPhone_ae}>{jobDetails.JobSite_ContactPhone_ae}</a><br/><br />
        </> }


      {jobDetails.ProjectManager === '' ? '' : 
        <>
        Project Manager - <br />
        {jobDetails.ProjectManager}<br />
          <a href={"mailto:" + jobDetails.ProjectManagerEmail_lu}>{jobDetails.ProjectManagerEmail_lu}</a> <br />
        </> }
    </>
  )
}

  return (
    <>
    {redirect && <Redirect push to={'/jobs-list'} />}
      {showResult && <>
        <ListItem>
          {/* View Job Name */}
          {!jobNameEdit &&
            <>
              <ListItemText style={{color: 'black'}} primary="Job Name" secondary={jobDetails.Job_Name} />
              {props.admin &&
              <ListItemIcon>
                  <IconButton onClick={() => setJobNameEdit(true)}>
                    <EditIcon />
                  </IconButton>
              </ListItemIcon>
              }
            </>
          }

          {/* Edit Job Name */}
          {jobNameEdit &&
            <>
              <ListItemText>
              <TextField
                style={{width: '100%'}}
                multiline
                id="jobName"
                label="Job Name"
                value={jobName}
                onChange={handleJobNameChange}
                className={classes.textField}
                margin="normal"
              />
              </ListItemText>
              <ListItemIcon>
                <IconButton onClick={() => setJobNameEdit(false)}>
                  <CloseIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemIcon>
                <IconButton onClick={() => { setJobNameEdit(false); setUpdate(true); setJobDetails(prevState => { console.log({...prevState, Job_Name: jobName}); return {...prevState, Job_Name: jobName}});}} >
                  <DoneIcon />
                </IconButton>
              </ListItemIcon>
            </>
          }
        </ListItem>
        <Divider />
        <ListItem>
          {/* View Job Type */}
          {!jobTypeEdit &&
            <>
              <ListItemText style={{color: 'black'}} primary="Job Type" secondary={jobDetails.JobType} />
              {props.admin &&
              <ListItemIcon>
                  <IconButton onClick={() => setJobTypeEdit(true)}>
                    <EditIcon />
                  </IconButton>
              </ListItemIcon>
              }
            </>
          }

          {/* Edit Job Type */}
          {jobTypeEdit &&
            <>
              <ListItemText>
                <Select
                    style={{width: '100%'}}
                    value={jobType}
                    onChange={handleJobTypeChange}
                    input={<Input id="jobType" name="jobType" />}
                >
                  <MenuItem key="0" value="Curbs/Service">Curbs/Service</MenuItem>,
                  <MenuItem key="1" value="New Construction">New Construction</MenuItem>,
                  <MenuItem key="2" value="Repair">Repair</MenuItem>,
                  <MenuItem key="3" value="Reroof">Reroof</MenuItem>,
                  <MenuItem key="4" value="Roof Leak">Roof Leak</MenuItem>,
                  <MenuItem key="5" value="Roof Maintenance">Roof Maintenance</MenuItem>,
                  <MenuItem key="6" value="Sheet Metal">Sheet Metal</MenuItem>,
                  <MenuItem key="7" value="No Charge Warranty">No Charge Warranty</MenuItem>
                </Select>
              </ListItemText>
              <ListItemIcon>
                <IconButton onClick={() => setJobTypeEdit(false)}>
                  <CloseIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemIcon>
                <IconButton onClick={() => { setJobTypeEdit(false); setUpdate(true); setJobDetails(prevState => { console.log({...prevState, JobType: jobType}); return {...prevState, JobType: jobType}});}} >
                  <DoneIcon />
                </IconButton>
              </ListItemIcon>
            </>
          }
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Job Number" secondary={jobDetails.Number_JobCombined} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Job Description" secondary={jobDetails.Job_Description} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Job Created" secondary={jobDetails.created} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Job Dispatched" secondary={jobDetails.dispatched_on} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Dispatcher" secondary={jobDetails.dispatcher} />
        </ListItem>
        <Divider />
        {/*
        <ListItem>
          
          <Typography
            className={classes.dividerFullWidth}
            color="textSecondary"
            display="block"
            variant="caption"
          >
            Customer
          </Typography>
          
          </ListItem>
          */}
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Customer"  secondary={
            <>
            {jobDetails.Customer_lu}
            </>
            
            } />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Salesperson"  secondary={
            <>
            {jobDetails.Salesperson}
            </>
            
            } />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Contacts"  secondary={getContacts()} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          {/*
          <Typography
            className={classes.dividerFullWidth}
            color="textSecondary"
            display="block"
            variant="caption"
            >
            Address
          </Typography>
          */}
          {!addressEdit &&
          <>
            <ListItemText style={{color: 'black'}} primary="Address" secondary={
              (jobDetails.address_Street1_Ae != '' || jobDetails.address_City_Ae != '' || jobDetails.address_State_Ae != '' || jobDetails.address_Zip_Ae != '') &&
            <>
                  <span>
                    
                      {jobDetails.address_Street1_Ae}<br></br>{jobDetails.address_Street2_Ae != '' && (<>{jobDetails.address_Street2_Ae}<br></br></>)}{jobDetails.address_City_Ae} {jobDetails.address_State_Ae}, {jobDetails.address_Zip_Ae}
                    <br />
                    <a href={"https://maps.google.com/?q=" + jobDetails.address_Street1_Ae + " " + jobDetails.address_Street2_Ae + " " + jobDetails.address_City_Ae + ", " + jobDetails.address_State_Ae + " " + jobDetails.address_Zip_Ae} target="_blank">
                      Open in Google Maps
                    </a>
                  </span>
            </>
            } />
            <ListItemIcon>
              <IconButton onClick={() => setAddressEdit(true)}>
                <EditIcon />
              </IconButton>
            </ListItemIcon>
          </>
          }
          {addressEdit &&
          <>
            {/*<ListItemText style={{color: 'black'}} primary="Address" />*/}
            <ListItemText>
            <TextField
              style={{width: '100%'}}
              multiline
              id="street1"
              label="Street Address 1"
              value={address_Street1}
              onChange={handleStreet1Change}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              style={{width: '100%'}}
              multiline
              id="street2"
              label="Street Address 2"
              value={address_Street2}
              onChange={handleStreet2Change}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              style={{width: '33.3%'}}
              multiline
              id="city"
              label="City"
              value={address_City}
              onChange={handleCityChange}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              style={{width: '33.4%'}}
              multiline
              id="state"
              label="State"
              value={address_State}
              onChange={handleStateChange}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              style={{width: '33.3%'}}
              multiline
              id="zip"
              label="Zip"
              value={address_Zip}
              onChange={handleZipChange}
              className={classes.textField}
              margin="normal"
            />
            </ListItemText>
            <ListItemIcon>
              <IconButton onClick={() => setAddressEdit(false)}>
                <CloseIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemIcon>
              <IconButton onClick={() => { setAddressEdit(false); setUpdate(true); setJobDetails(prevState => { return {...prevState, address_Street1_Ae: address_Street1, address_Street2_Ae: address_Street2, address_State_Ae: address_State, address_City_Ae: address_City, address_Zip_Ae: address_Zip}});}} >
                <DoneIcon />
              </IconButton>
            </ListItemIcon>
          </>
          }
        </ListItem>
        
        <Divider />
        
        <ListItem>
          <TextField
            multiline
            id="standard-name"
            label="Notes to field technicians"
            value={notes}
            onChange={handleNoteChange}
            className={classes.textField}
            margin="normal"
          />
        </ListItem>
        <Divider />
      {notes != jobDetails.TechNotes && 
        <ListItem style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button onClick={() => {setNotes(jobDetails.TechNotes); }} variant="contained" color="primary">
            Cancel Changes
          </Button>
          <Button onClick={() => {setUpdate(true); setJobDetails(prevState => { return {...prevState, TechNotes: notes}}); }} variant="contained" color="primary">
            Save Changes
          </Button>
          
        </ListItem>}
        <Divider />
        <ListItem>
          <TextField
            multiline
            id="standard-name"
            label="Notes from field technicians and materials"
            value={notesFromField}
            onChange={handleNoteFromFieldChange}
            className={classes.textField}
            margin="normal"
          />
        </ListItem>
        <Divider />
        {notesFromField != jobDetails.TechNotesFromField && 
        <ListItem style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button onClick={() => {setNotesFromField(jobDetails.TechNotesFromField); }} variant="contained" color="primary">
            Cancel Changes
          </Button>
          <Button onClick={() => {setUpdate(true); setJobDetails(prevState => { return {...prevState, TechNotesFromField: notesFromField}}); }} variant="contained" color="primary">
            Save Changes
          </Button>
          
        </ListItem>}
        
      </>}
      {!showResult && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} className={classes.img}><CircularProgress></CircularProgress></div>}
    </>
  );
};

export default JobDetails;