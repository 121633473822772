import React, { useState, useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import WorkIcon from '@material-ui/icons/Work';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useAuth0 } from "../../react-auth0-wrapper";
import InputIcon from '@material-ui/icons/Input';
import { Link } from "react-router-dom";
import Slide from '@material-ui/core/Slide';
import HomeIcon from '@material-ui/icons/Home';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import openSocket from 'socket.io-client';
import BuildIcon from '@material-ui/icons/Build';
import { config } from "../../config";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    appBar: {
    position: 'fixed',
      bottom: 0,
      top: 'auto'
    },
    avatar: {
      width: 24,
      height: 24,
      margin: 0,
    },
  }));


export default function BottomNavbar(props)
{
    const { isAuthenticated, loginWithRedirect, logout, user, loading, getTokenSilently } = useAuth0();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [alertNumber, setAlertNumber] = useState(0);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [userPermissions, setUserPermissions] = useState([]);
    const [clockedIn, setClockedIn] = useState(false);
    let socket;

    useEffect(() => {
      const connect = async () => {
        let token = await getTokenSilently();
        socket = await openSocket(config.API_ENDPOINT, {'query': 'token=' + token });
        socket.on('update', (newData) => {
          if(newData.message)
        {
          if(newData.data.fieldData.TimeEnd_t != "")
              {
                  setClockedIn(false);
              }
              else
              {
                  setClockedIn(true);
              }
            }
        });

        const response = await fetch(config.API_ENDPOINT + "/api/getTimecardData", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: null
        });
        
        const responseData = await response.json();

        if(responseData.message)
        {
          if(responseData.data.fieldData.TimeEnd_t != "")
          {
            setClockedIn(false)
          }
          else
          {
            setClockedIn(true);
          }
  
        }
  
  
  
      
    }
      if(!loading && isAuthenticated)
      {
        connect();
      }

      }, [loading, isAuthenticated, getTokenSilently])

    useEffect(() => {
      const getPermissions = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(config.API_ENDPOINT + "/api/getUserPermissions", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const responseData = await response.json();
          setUserPermissions(responseData);
        }
        catch (error) {
          console.error(error);
        }
      };
      if(!loading)
      {
        getPermissions();
      }
  
    },[getTokenSilently, loading]);

    function handleProfileMenuOpen(event) {
        setAnchorEl(event.currentTarget);
      }
    
      function handleMobileMenuClose() {
        setMobileMoreAnchorEl(null);
      }

    const mobileMenuId = 'primary-search-account-menu-mobile';
    function handleMobileMenuOpen(event) {
        setMobileMoreAnchorEl(event.currentTarget);
      }
      const renderMobileMenu = (
        <Menu
          anchorEl={mobileMoreAnchorEl}
          id={mobileMenuId}
          keepMounted
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem onClick={() => { handleMobileMenuClose() }} component={Link} to={'/'}>
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <HomeIcon />
              </Badge>
            </IconButton>
            <p>Home</p>
          </MenuItem>
          <MenuItem onClick={() => { handleMobileMenuClose() }} component={Link} to={'/timecard'}>
            <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge color="secondary" variant="dot" invisible={!clockedIn}>
            {/* <TimerIcon /> */}
            <WatchLaterIcon/>
          </Badge>
            </IconButton>
            <p>Track Time</p>
          </MenuItem>
          <MenuItem disabled={userPermissions.includes("type:admin") ? false : true }  onClick={() => { handleMobileMenuClose() }} component={Link} to={'/alert-list'}>
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <p>Manage Alerts</p>
          </MenuItem>
          <MenuItem onClick={() => { handleMobileMenuClose() }} component={Link} to={'/report-leak-staff'}>
            <IconButton aria-label="" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <BuildIcon />
              </Badge>
            </IconButton>
            <p>Report Leak</p>
          </MenuItem>
          <MenuItem onClick={() => { handleMobileMenuClose() }} component={Link} to={'/jobs-list'}>
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <WorkIcon />
              </Badge>
            </IconButton>
            <p>Manage Jobs</p>
          </MenuItem>
          {/*isAuthenticated && (
          <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <p>Profile</p>
          </MenuItem>
          )*/}
      {!isAuthenticated && (
          <MenuItem  onClick={() =>
            loginWithRedirect({})
          }>
              <IconButton aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit">
                    <InputIcon />
                    </IconButton><p>Log in</p>
                    </MenuItem>
                    
                )}
        </Menu>
      );


return (

<div className={classes.sectionMobile}>
<div className={classes.appBarSpacer}></div>
    {isAuthenticated && <Slide direction="up" in={true} mountOnEnter unmountOnExit><AppBar position="static" color="primary" className={classes.appBar}>
        <Toolbar>
        <IconButton component={Link} to={'/'} aria-label="" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <HomeIcon />
              </Badge>
            </IconButton>
            
            {userPermissions.includes("type:admin") &&
            <>
            <div className={classes.grow} />
        <IconButton component={Link} to={'/alert-list'} aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            </>
            }
            <div className={classes.grow} />
        <IconButton component={Link} to={'/timecard'} aria-label="Time Tracker" color="inherit">
        <Badge color="secondary" variant="dot" invisible={!clockedIn}>
            <WatchLaterIcon />
          </Badge>
            </IconButton>
          
          <div className={classes.grow} />
          
          
            <IconButton component={Link} to={'/jobs-list'} aria-label="show 4 new jobs" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <WorkIcon />
              </Badge>
            </IconButton>
          <div className={classes.grow} />
          <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
        </Toolbar>
      </AppBar>
      </Slide>
    }
      {renderMobileMenu}
    </div>
);
}