import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import MaterialTable from 'material-table'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Button, duration, Paper } from '@material-ui/core'
import { useAuth0 } from '../../../react-auth0-wrapper'
import Loading from './Loading'
// import {Icons, Columns, Options, customStyle, searchbar} from './TableSettings';
import { Icons, Options } from './TableSettings'
import AddBox from '@material-ui/icons/AddBox'
import { StylesContext } from '@material-ui/styles'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import styles from './opportunities.module.css'
import { useMediaQuery } from 'react-responsive'
import useMediaQuery1 from '@material-ui/core/useMediaQuery'
import { IconButton } from '@material-ui/core'
import WorkIcon from '@material-ui/icons/Work'
import JobDialog from './JobDialog'
import PdfDialog from './PdfDialog'
import Tooltip from '@material-ui/core/Tooltip'
import { config } from '../../../config'

const useStyles = makeStyles(theme => ({
  opportunityList_addButton: {
    width: 'auto',
    textTransform: 'none',
    position: 'relative',
    right: '5%',
    top: '2%',
    marginLeft: '0',
    padding: '8px 18px',
    fontSize: '14px',
    fontWeight: '400',
    [theme.breakpoints.down('xs')]: {
      width: '144px',
      marginTop: '-43px'
      // position: 'absolute',
      // top: '14px',
      // right: '15px',
    },
    '&:hover': {
      backgroundColor: '#494f5a'
    }
  }
}))

const OpportunitiesList = () => {
  const theme = useTheme()
  const xsDown = useMediaQuery1(theme.breakpoints.down('xs'))
  const smDown = useMediaQuery1(theme.breakpoints.down('sm'))
  const [jobDialogOpen, setJobDialogOpen] = useState(false)
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false)

  const openJobDialog = () => {
    setJobDialogOpen(true)
  }

  //Set columns
  const Columns = [
    { title: 'Number', field: 'Number_Opp' },
    { title: 'Name', field: 'Name' },
    { title: 'Location', field: 'address_Combined_c' },
    {
      title: 'Actions',
      field: 'internal_action',
      render: rowData =>
        rowData && (
          <>
            <Button
              color='primary'
              onClick={e => {
                e.stopPropagation()
                setSelectedOpp(rowData)
                setPdfDialogOpen(true)
              }}
              variant='contained'
            >
              View Pdf <PictureAsPdfIcon />
            </Button>

            {/*<Button
                    color="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedOpp(rowData);
                        openJobDialog();
                    }}
                    variant="contained"
                >
                    Convert to Job <WorkIcon />
                </Button>*/}
          </>
        )
    }
  ]
  console.log(Columns);

  let customStyle = {
    width: '260px',
    right: '13%',
    position: 'relative'
  }

  if (smDown) {
    customStyle = {
      width: '210px',
      right: '25px'
    }
  }

  if (xsDown) {
    customStyle = {
      width: '91vw',
      position: 'relative',
      zIndex: '4000',
      left: '-33px',
      top: '65px',
      marginBottom: '75px'
    }
  }

  const classes = useStyles()
  const [opportunities, setOpportunities] = useState([])
  const [loading, setLoading] = useState(true)
  const { getTokenSilently } = useAuth0()
  const [redirect, setRedirect] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [selectedOpp, setSelectedOpp] = useState(null)
  const [selectedInspection, setSelectedInspection] = useState('')
  const [salespeople, setSalespeople] = useState([])

  useEffect(() => {
    getData()
    getSalespeople()
  }, [getTokenSilently])

  //Handle table row click
  const handleRowClick = row => {
    let opid = row.recordId
    let path = '/opportunities/' + opid
    setRedirect(path)
  }

  //Performs an HTTP get request to the server
  //Returns a JSON object of the result
  const fetchCommand = async url => {
    const token = await getTokenSilently()
    const headers = { headers: { Authorization: 'Bearer ' + token } }
    const result = await fetch(url, headers)
    const obj = await result.json()
    return obj
  }

  //Formats data from FileMaker to include the Record ID and Field Data in a JavaScript Array
  const formatList = data => {
    let list = data.map(item => {
      console.log(item)
      item.fieldData.recordId = item.recordId
      return item.fieldData
    })

    return list
  }
  const [openForm, setOpp] = useState(false)

  const OpenNewForm = e => {
    e.preventDefault()
    setOpp(true)
  }

  if (openForm) {
    return <Redirect push to='/add-opportunity' />
  }

  //Get data from the database
  const getData = async () => {
    try {
      const path = config.API_ENDPOINT + '/api/opportunities'
      const result = await fetchCommand(path)

      //if data successfully fetched
      if (result.message) {
        let list = formatList(result.data)
        console.log(list)
        setOpportunities(list)
        setLoading(false)
      } else {
        console.log('No opportunites found.')
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getSalespeople = async () => {
    try {
      const path = config.API_ENDPOINT + '/api/getSalespeople'
      const response = await fetchCommand(path)

      if (response.message) {
        setSalespeople(response.data.response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Handle redirect when user selects an item
  if (redirect) {
    return <Redirect push to={redirect} />
  }

  //Handle loading
  if (loading) {
    return <Loading />
  }

  //Render the view
  return (
    <>
      <div className={styles.opportunitylist_wrapper}>
        <Paper>
          <MaterialTable
            // title="Opportunities"
            title={
              <h2 className={styles.opportunitylist_heading}>
                Opportunities 1
                {/* {props.isTabletOrMobile ? 'Opportunities' : 'Manage Opportunities'} */}
              </h2>
            }
            columns={Columns}
            data={opportunities}
            options={{
              pageSize: 10,
              filtering: true,
              searchFieldStyle: customStyle
            }}
            icons={Icons}
            onRowClick={(e, row) => handleRowClick(row)}
            components={{
              Action: props => {
                return (
                  // <Button
                  //     onClick={(event) => OpenNewForm(event, props.data)}
                  //     color="primary"
                  //     variant="contained"
                  //     className={classes.opportunityList_addButton}
                  //     size="small"
                  //     >
                  //     { isMobile ?  "Add Opportunity" : "Add Opportunity/Roof Inspection"}
                  // </Button>
                  null
                )
              }
            }}
            actions={[
              {
                icon: Icons.Add,
                isFreeAction: true,
                onClick: event => OpenNewForm(event)
              }
            ]}
          />
        </Paper>
      </div>

      <JobDialog
        dialogOpen={jobDialogOpen}
        setDialogOpen={setJobDialogOpen}
        selectedOpp={selectedOpp}
        setSelectedOpp={setSelectedOpp}
        setRedirect={setRedirect}
        selectedInspection={selectedInspection}
        setSelectedInspection={setSelectedInspection}
        salespeople={salespeople}
      />
      <PdfDialog
        dialogOpen={pdfDialogOpen}
        setDialogOpen={setPdfDialogOpen}
        jobDialogOpen={jobDialogOpen}
        setJobDialogOpen={setJobDialogOpen}
        selectedOpp={selectedOpp}
        setSelectedOpp={setSelectedOpp}
        selectedInspection={selectedInspection}
        setSelectedInspection={setSelectedInspection}
      />
    </>
  )
}

export default OpportunitiesList
