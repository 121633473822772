import React, { Component, useState, useEffect } from 'react';
import style from './actionDetail.css';
import MaterialTable from 'material-table';
// import styles from '../../AddOpportunity/contactDuraroof.module.css';
import logo from '../../../assets/images/logo.png';
import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, Input, CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Zoom from '@material-ui/core/Zoom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useAuth0 } from "../../../react-auth0-wrapper";
import backPng from '../../../assets/icons/back_arrow.svg';
import pdfPng from '../../../assets/icons/pdf.png';
import { Link, useParams } from 'react-router-dom';
import { actionColumns, actionOptions, Icons } from "../List/TableSettings";
import { config } from "../../../config";


const  List = (props) => {
    
  // Initialize the state
  const item = localStorage.getItem('pdf_url')
  let url = JSON.parse(item)
  const {opid} = useParams();

  const { getTokenSilently } = useAuth0();
  const [finalized, setFinalized] = useState({
    creation_data_time: '',
    inspection_no: '',
    note: '',
    opportunity_id: '',
    pdf_path: "url.signUrl"
  })
  let [lastInspection, setLastInspection] = useState([])
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  
  
  const  handleChange = (event) => {
   
    let r = Math.random().toString(36).substring(7);
    let d = new Date();
    let n = d.toLocaleString();
    let value = finalized;
    value.inspection_no = r
    value.note = event.target.value;
    value.creation_data_time = n
    value.opportunity_id = opid;
   

    setFinalized(value);
  }

  const fetchCommand = async (url, data) => {
    const token = await getTokenSilently();
    const request = {
        method: 'POST',
        headers: { 
            Authorization: 'Bearer ' + token,
            Accept: 'application/json'
        },
        body: data
    };
    const result = await fetch(url, request);
    const obj = await result.json();
    return obj;
}

const saveInspection = async (inspectionData) => {
    try {
        const path = config.API_ENDPOINT + '/api/create/roof/inspection';
        const result = await fetchCommand(path, JSON.stringify(inspectionData));
        return result.message;
    } catch(error) {
        console.error(error);
        return false;
    }
}

  const onFinialized = (event) => {
    event.preventDefault();
    saveInspection(finalized)
    setLoading(true)
  }
  let DEP = {
    opid, loading, finalized
  }
  useEffect(()=> {
    (async () => {
        const path = config.API_ENDPOINT + '/api/roof/inspections/' + opid;   
    const token = await getTokenSilently();
    const request = {
        method: 'GET',
        headers: { 
            Authorization: 'Bearer ' + token,
            Accept: 'application/json'
        }
    };
    const result = await fetch(path, request);
    let obj = await result.json();
    if(!obj.message) return;
    obj  = obj.data.map(result => {
        let obj1 = {
                creation_data_time: result.fieldData.creation_data_time,
                note: result.fieldData.note,
                inspection_no: result.fieldData.inspection_no,
                pdf_path: result.fieldData.pdf_path
        }
        return obj1
    })

    setLastInspection(obj)
    })()
    // return obj;
  }, [loading]);

return ( 
            
    <div className={style.body__header}>
        
        <div className="container__action">

            <div className="action__header">
                <h2 className="action__heading">Action Detail</h2>
                
                <div className="action__back">
                    {/* <img src={backPng} alt='back arrow' className="back__icon"></img> */}
                    <Link className="back__text" to={`/opportunities/${opid}`}>
                        <img src={backPng} alt='back arrow' className="back__icon" />back</Link>
                </div>
           
            </div>
            <div className="action__body">

                <div className="body__finalize">

                    <h5 className="body__heading">Finalize</h5>
                    <div className="body__content1">
                        <textarea placeholder="Note:" className="content__textarea" name="notes" onChange={e =>  handleChange(e)}></textarea>
                        <div className="content__button">
                            {/* <button className="dark__button">Finalize</button> */}
                            <Button variant="contained" color="primary" onClick={e => onFinialized(e)}>Finalize</Button>
                        </div>
                    </div>
                </div>


                <h5 className="body__heading">History</h5>

                {
                    lastInspection.length && <MaterialTable
    
                        components={{
                            Container: props => <Paper {...props} elevation={0}/>
                        }}
                        
                        // title={
                        //     <p className="">
                        //         History
                        //     </p>
                        // }
                    columns={actionColumns}
                    options={actionOptions}
                    icons={Icons}
                    data={lastInspection}
                    >
    
                    </MaterialTable>
                }
               
            </div>
        </div>
    </div>
)
}

export default List;