import React, { useEffect, useState } from 'react'
import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import WorkIcon from '@material-ui/icons/Work'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useAuth0 } from '../../react-auth0-wrapper'
import Button from '@material-ui/core/Button'
import InputIcon from '@material-ui/icons/Input'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import logo from '../../assets/images/logo.png'
import Slide from '@material-ui/core/Slide'
import AddAlertIcon from '@material-ui/icons/AddAlert'
import HomeIcon from '@material-ui/icons/Home'
import TimerIcon from '@material-ui/icons/Timer'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import openSocket from 'socket.io-client'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import theme from '../../themes/theme'
import Tooltip from '@material-ui/core/Tooltip'
import _ from 'lodash'
import StatusBadge from './StatusBadge'
import BuildIcon from '@material-ui/icons/Build'
import { config } from '../../config'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  appBar: {
    bottom: 0,
    width: '100%',
    position: 'fixed',
    top: 'auto'
  },
  avatar: {
    width: 24,
    height: 24,
    margin: 0
  }
}))

export default function PrimarySearchAppBar () {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    loading,
    getTokenSilently
  } = useAuth0()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const [userPermissions, setUserPermissions] = useState([])
  const [clockedIn, setClockedIn] = useState(false)
  const [timetrackerSnackbarOpen, setTimetrackerSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('Clocked In!')

  let socket
  useEffect(() => {
    const connect = async () => {
      let token = await getTokenSilently()
      socket = await openSocket(config.API_ENDPOINT, { query: 'token=' + token })
      socket.on('update', newData => {
        if (newData.message) {
          if (newData.data.fieldData.TimeEnd_t != '') {
            setClockedIn(false)
          } else {
            setClockedIn(true)
          }
        }
      })
      const response = await fetch(config.API_ENDPOINT + '/api/getTimecardData', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // sent request
          Accept: 'application/json' // expected data sent back
        },
        body: null
      })

      const responseData = await response.json()
      if (responseData.message) {
        if (responseData.data.fieldData.TimeEnd_t != '') {
          setClockedIn(false)
        } else {
          setClockedIn(true)
        }
      }
    }
    if (!loading && isAuthenticated) {
      connect()
    }
  }, [loading, isAuthenticated, getTokenSilently])

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(
          config.API_ENDPOINT + '/api/getUserPermissions',
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        const responseData = await response.json()
        setUserPermissions(responseData)
      } catch (error) {
        console.error(error)
      }
    }
    if (!loading) {
      getPermissions()
    }
  }, [getTokenSilently, loading])

  function handleProfileMenuOpen (event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMobileMenuClose () {
    setMobileMoreAnchorEl(null)
  }

  function handleMenuClose () {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/*<MenuItem component={Link} to={'/profile'} onClick={handleMenuClose}>Profile</MenuItem>*/}

      <MenuItem
        onClick={() => {
          handleMenuClose()
          logout()
        }}
      >
        Log Out
      </MenuItem>
      {/*
      {userPermissions.includes("type:admin") && <MenuItem component={Link} to={'/admin-panel'}>Admin Panel</MenuItem>}
      */}
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMobileMenuClose()
        }}
        component={Link}
        to={'/alert-list'}
      >
        <IconButton
          disabled={userPermissions.includes('type:admin') ? false : true}
          aria-label='show 11 new notifications'
          color='inherit'
        >
          <Badge badgeContent={0} color='secondary'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Alerts</p>
      </MenuItem>
      {!isAuthenticated && (
        <MenuItem
          onClick={() => {
            handleMobileMenuClose()
          }}
          component={Link}
          to={'/report-leak'}
        >
          <IconButton aria-label='report-leak' color='inherit'>
            <Badge badgeContent={0} color='secondary'>
              <AddAlertIcon />
            </Badge>
          </IconButton>
          <p>Report Leak</p>
        </MenuItem>
      )}
      {isAuthenticated && (
        <MenuItem
          onClick={() => {
            handleMobileMenuClose()
          }}
          component={Link}
          to={'/report-leak-staff'}
        >
          <IconButton aria-label='report-leak-staff' color='inherit'>
            <Badge badgeContent={0} color='secondary'>
              <AddAlertIcon />
            </Badge>
          </IconButton>
          <p>Report Leak Staff</p>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleMobileMenuClose()
        }}
        component={Link}
        to={'/timecard'}
      >
        <IconButton aria-label='Time Tracker' color='inherit'>
          <Badge color='secondary' variant='dot' invisible={!clockedIn}>
            {/* <TimerIcon /> */}
            <WatchLaterIcon />
          </Badge>
        </IconButton>
        <p>Time Tracker</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMobileMenuClose()
        }}
        component={Link}
        to={'/jobs-list'}
      >
        <IconButton aria-label='show 11 new notifications' color='inherit'>
          <Badge badgeContent={0} color='secondary'>
            <WorkIcon />
          </Badge>
        </IconButton>
        <p>Jobs</p>
      </MenuItem>
      {isAuthenticated && (
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            aria-label='account of current user'
            aria-controls='primary-search-account-menu'
            aria-haspopup='true'
            color='inherit'
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      )}
      {!isAuthenticated && (
        <MenuItem onClick={() => loginWithRedirect({})}>
          <IconButton
            aria-label='account of current user'
            aria-controls='primary-search-account-menu'
            aria-haspopup='true'
            color='inherit'
          >
            <InputIcon />
          </IconButton>
          <p>Log in</p>
        </MenuItem>
      )}
    </Menu>
  )
  let date = new Date()

  const handleTimetrackerSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setTimetrackerSnackbarOpen(false)
  }

  return (
    <div className={classes.grow}>
      <Slide direction='down' in={true} mountOnEnter unmountOnExit>
        <AppBar position='fixed'>
          <Toolbar>
            <div style={{ width: '35%', minWidth: '200px' }}>
              <Link to='/'>
                <img
                  src={logo}
                  style={{ width: '200px', height: 'auto' }}
                  alt='logo'
                />
              </Link>
            </div>
            <div className={classes.grow}></div>
            <p className={classes.sectionDesktop}>DuraRoof App {process.env.DATABASE}</p>

            {/*<img src={logo} alt="logo" />*/}
            {/*<div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon /> 
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
            </div>*/}
            <div className={classes.grow} />
            <div
              style={{
                width: '35%',
                flexDirection: 'row-reverse',
                display: 'flex'
              }}
            >
              {isAuthenticated && !loading && (
                <>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    open={timetrackerSnackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleTimetrackerSnackbarClose}
                    ContentProps={{
                      'aria-describedby': 'message-id'
                    }}
                    message={<span id='message-id'>{snackbarMessage}</span>}
                    action={[
                      <IconButton
                        key='close'
                        aria-label='close'
                        color='inherit'
                        className={classes.close}
                        onClick={handleTimetrackerSnackbarClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    ]}
                  />
                  <IconButton
                    edge='end'
                    aria-label='account of current user'
                    aria-controls={menuId}
                    aria-haspopup='true'
                    onClick={handleProfileMenuOpen}
                    color='inherit'
                  >
                    <StatusBadge
                      children={
                        <Avatar
                          alt='Avatar'
                          src={user.picture}
                          className={classes.avatar}
                        />
                      }
                    />
                  </IconButton>
                </>
              )}
              {isAuthenticated && loading && (
                <IconButton
                  edge='end'
                  aria-label='account of current user'
                  aria-controls={menuId}
                  aria-haspopup='true'
                  onClick={handleProfileMenuOpen}
                  color='inherit'
                >
                  <AccountCircle />
                </IconButton>
              )}
              <div className={classes.sectionMobile}>
                {!isAuthenticated && (
                  <Button
                    variant='outlined'
                    style={{ color: 'white' }}
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                )}
              </div>
              <div className={classes.sectionDesktop}>
                {isAuthenticated && (
                  <>
                    <IconButton
                      component={Link}
                      to={'/'}
                      aria-label='show 17 new notifications'
                      color='inherit'
                    >
                      <Badge badgeContent={0} color='secondary'>
                        <HomeIcon />
                      </Badge>
                    </IconButton>
                    {userPermissions.includes('type:admin') && (
                      <IconButton
                        component={Link}
                        to={'/alert-list'}
                        aria-label='show 17 new notifications'
                        color='inherit'
                      >
                        <Badge badgeContent={0} color='secondary'>
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                    )}

                    <IconButton
                      component={Link}
                      to={'/timecard'}
                      aria-label='Time Tracker'
                      color='inherit'
                    >
                      <Badge
                        color='secondary'
                        variant='dot'
                        invisible={!clockedIn}
                      >
                        {/* <TimerIcon /> */}
                        <WatchLaterIcon />
                      </Badge>
                    </IconButton>

                    {!isAuthenticated && (
                      <IconButton
                        component={Link}
                        to={'/report-leak'}
                        aria-label=''
                        color='inherit'
                      >
                        <Badge badgeContent={0} color='secondary'>
                          <BuildIcon />
                        </Badge>
                      </IconButton>
                    )}
                    {isAuthenticated && (
                      <IconButton
                        component={Link}
                        to={'/report-leak-staff'}
                        aria-label=''
                        color='inherit'
                      >
                        <Badge badgeContent={0} color='secondary'>
                          <BuildIcon />
                        </Badge>
                      </IconButton>
                    )}

                    <IconButton
                      component={Link}
                      to={'/jobs-list'}
                      aria-label='show 4 new jobs'
                      color='inherit'
                    >
                      <Badge badgeContent={0} color='secondary'>
                        <WorkIcon />
                      </Badge>
                    </IconButton>
                  </>
                )}

                {!isAuthenticated && (
                  <Button
                    variant='outlined'
                    style={{ color: 'white' }}
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                )}
              </div>
            </div>
          </Toolbar>
          {renderMobileMenu}
          {renderMenu}
        </AppBar>
      </Slide>
      <Toolbar />
    </div>
  )
}
