/**
 * Calculate perimeter of a polygon in feet
 * @param {Google maps geometry object} shape 
 */
const getPerimeter = (shape) => {
    let coordinates = [];
    let perimeter = 0;

    //Get the roof coordinates
    shape.forEachLatLng(latlng => {
        coordinates.push(latlng);
    });
    
    //add up the distance between each coordinate
    coordinates.forEach((coord, i) => {
      if (i > 0) {
        perimeter += window.google.maps.geometry.spherical.computeDistanceBetween(coord, coordinates[i - 1]) * 3.28084;
      }
      else {
        perimeter += window.google.maps.geometry.spherical.computeDistanceBetween(coord, coordinates[coordinates.length - 1]) * 3.28084;
      }
    });

    return perimeter;

}

/**
 * Calculate the square footage of a polygon
 * @param {Google maps geometry object} shape 
 */
const getArea = (shape) => {
    let coordinates = [];
    let area = 0;

    //Get the roof coordinates
    shape.forEachLatLng(latlng => {
        coordinates.push(latlng);
    });

    area = window.google.maps.geometry.spherical.computeArea(coordinates) * 10.7639;

    return area;
}

/**
 * Converts a Google Maps Polygon feature to a string
 * @param {} feature 
 */
const featureToString = (feature) => {
    let area = feature.getProperty('area');
    let perimeter = feature.getProperty('perimeter');
    let string = "Area: " + area.toFixed(2) + " Sq Ft Perimeter: " + perimeter.toFixed(2) + " Ft";

    return string;
}


//Get the center of a shape
const getCenter = (shape) => {
    //Find the lowest latitude coordinate
    var y1 = 90;
    shape.forEachLatLng(latlng => {
        if(latlng.lat() < y1) {
            y1 = latlng.lat();
        }
    });

    //Find the highest latitude coordinate
    var y2 = -90;
    shape.forEachLatLng(latlng => {
        if(latlng.lat() > y2) {
            y2 = latlng.lat();
        }
    });

    //Find the lowest longitude coordinate
    var x1 = 180;
    shape.forEachLatLng(latlng => {
        if(latlng.lng() < x1) {
            x1 = latlng.lng();
        }
    });

    //Find the highest longitude coordinate
    var x2 = -180;
    shape.forEachLatLng(latlng => {
        if(latlng.lng() > x2) {
            x2 = latlng.lng();
        }
    });

    //Calclute the center coordinate
    var center = {};
    center.lat = y1 + ((y2 - y1) / 2);
    center.lng = x1 + ((x2 - x1) / 2);


    return center;
}


export { getArea, getPerimeter, getCenter, featureToString };