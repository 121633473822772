import React, {useState, useEffect} from "react";
import JobSelect from './JobSelect';
import ReportSelect from './ReportSelect';
import { useAuth0 } from "../../../react-auth0-wrapper";
import Button from '@material-ui/core/Button';
import ChangeOrderSelect from "./ChangeOrderSelect";
import DateSelect from "./DateSelect";
import NextPng from '../../../assets/icons/next_arrow.svg';
import styles from './reportSelect.module.css';
import { config } from "../../../config";

const SelectOptions = (props) => {
    const { getTokenSilently } = useAuth0();
    const [displayMessage, setDisplayMessage] = useState(false);

    const handleButtonClick = async (event) => {
        //If this is a new report, create one in FileMaker
        if(props.selectedReport === null) {
            let report = await createNewReport(props.selectedJob.__ID);
            props.setSelectedReport(report);
        }

        //Route to it
        props.setCurrentScreen(3);
    }

    //Performs an HTTP POST request to the server
    //Returns a JSON object of the result
    const fetchCommand = async (url, data) => {
        const token = await getTokenSilently();
        const request = {
            method: 'POST',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            },
            body: JSON.stringify(data)
        };
        const result = await fetch(url, request);
        const obj = await result.json();
        return obj;
    }

    const createNewReport = async (selectedJobId) => {
        try {
            const path = config.API_ENDPOINT + '/api/dailyReport/reports/' + selectedJobId;
            const result = await fetchCommand(path, {changeOrder : props.selectedChangeOrder === null ? '' : props.selectedChangeOrder.ChangeOrderNumber_Ae, dateOfWork : props.dateOfWork });

            //if data successfully fetched
            if(result.message) {
                return result.data;
            } else {

            }
        } catch(error) {
            console.error(error);
        }
    }

    if(props.currentScreen !== 2) {
        return null;
    }

    const style = {
        textAlign: 'left',
    };

    const input = {
        fontSize:'13px'
    };
    
    const btnStyle = {
        color : 'white',
        fontWeight : '400',
        backgroundColor: '#24a0ed',
        width: '100%',
        marginLeft: '0',
        marginRight: '0',
        position:'relative'
    }


    return (
        <>
        {/* Header */}
        <div className={styles.subHeading}>
            {props.action === 'modify' && 'Modify Existing Report'}
            {props.action === 'new' && 'Create New Report'}
        </div>

        <div style={style}>

            {/* List of Jobs */}
            <JobSelect className={styles.spacing}
                jobs={props.jobs} 
                loadingJobs={props.loadingJobs} 
                selectedJob={props.selectedJob} 
                setSelectedJob={props.setSelectedJob} 
            />

            {/* Give option to select change order and date, if creating a new report */}
            {props.action === 'new' && (props.selectedJob !== null) && <>
                <ChangeOrderSelect 
                    selectedJob={props.selectedJob}
                    changeOrders={props.changeOrders}
                    setChangeOrders={props.setChangeOrders}
                    selectedChangeOrder={props.selectedChangeOrder}
                    setSelectedChangeOrder={props.setSelectedChangeOrder}
                />

                <DateSelect
                    dateOfWork={props.dateOfWork}
                    setDateOfWork={props.setDateOfWork}
                />
            </>}

            <br />
            {/* List of Daily Reports --only shown if action=modify and selected job is not null */}
            {props.action === 'modify' && (props.selectedJob !== null) && 
            <>
            <ReportSelect 
                selectedJob={props.selectedJob} 
                selectedReport={props.selectedReport}
                setSelectedReport={props.setSelectedReport}
                action={props.action} 
                setDisplayMessage={setDisplayMessage}
            />
            {/* Give a message if no reports are found for the selected job */}
            {displayMessage}

            <br />
            </>
            }

            {/* Go to the Manage Report screen */}
            {(props.action === 'new' || props.selectedJob !== null) &&
            // <Button style={btnStyle} onClick={handleButtonClick}> Next &#8594;</Button>

                <Button style={btnStyle} onClick={handleButtonClick}>
                    Next
                    <img src={NextPng} alt='next arrow' className={styles.next__icon} />
                </Button>

            }
            <br />
        </div>
        </>
    );
}

export default SelectOptions;