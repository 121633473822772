import React, { useState, useEffect, useRef } from 'react'
import Loading from './Loading'
import { useAuth0 } from '../../../react-auth0-wrapper'
import { GoogleMap, Marker } from '@react-google-maps/api'
import {
  Options,
  ContainerStyle,
  Zoom,
  Tilt,
  MarkerOptions,
  Colors
} from './MapSettings'
import {
  getArea,
  getPerimeter,
  getCenter,
  featureToString
} from './MapFunctions'
import Button from '@material-ui/core/Button'
import SnapshotFrame from '../Individual/SnapshotFrame'
import { useMediaQuery } from 'react-responsive'
import { config } from '../../../config'
//import { AddFeature, SelectFeature, SetGeometry, SetProperty, MapMouseUp, RemoveFeature } from './MapEventListeners';
const google = window.google;


const RoofSectionMap = props => {
  const [loading, setLoading] = useState(true)
  const { getTokenSilently } = useAuth0()
  const [location, setLocation] = useState({
    lat: 44.156,
    lng: -90.3894
  })
  const [colors, setColors] = useState(['#FFA436']) //Roof Colors
  const [mapData, setMapData] = useState(null)
  let barHeight = '130px'
  if (props.isTabletOrMobile) {
    barHeight = '176px'
  }
  if (props.isMobile) {
    barHeight = '176px'
  }
  if (props.isExtraSmall) {
    barHeight = '198px'
  }

  useEffect(() => {
    getData()
  }, [getTokenSilently])

  useEffect(() => {
    // Add the map data after the map loads
    if (props.map !== null) {
      props.map.data.addGeoJson(mapData)
    }
  }, [props.map])

  useEffect(() => {
    function buttonListener (event) {
      let selectedFeature = null

      //Handle Delete button
      if (
        event.keyCode === 8 &&
        (props.selectedIndex !== null || props.selectedLabel !== null) &&
        !props.textBoxFocus
      ) {
        props.map.data.forEach(feature => {
          if (feature.getProperty('isMarker') !== undefined) {
            if (feature.getProperty('label') === props.selectedLabel) {
              selectedFeature = feature
            }
          } else {
            if (feature.getProperty('markerIndex') === props.selectedIndex) {
              selectedFeature = feature
            }
          }
        })
        // handleDelete(props.map, selectedFeature, props.selectedIndex);
      }

      //Handle escape button
      if (event.keyCode === 27) {
        //is there a way to switch out of drawing when pressing escape?
        props.setDisplayFrame(!props.displayFrame)
      }
    }

    document.addEventListener('keydown', buttonListener, false)

    return () => {
      document.removeEventListener('keydown', buttonListener, false)
    }
  }, [
    props.selectedIndex,
    props.selectedLabel,
    props.displayFrame,
    props.textBoxFocus
  ])

  const fetchCommand = async url => {
    const token = await getTokenSilently()
    const request = {
      headers: { Authorization: 'Bearer ' + token }
    }
    const result = await fetch(url, request)
    const obj = await result.json()
    return obj
  }
  
  let geocoder = new google.maps.Geocoder();  
  let loc = '';

  //Get coordinates for center location of map
  //Also get any map data from the database
  const getData = async () => {
    try {
      const path =
        config.API_ENDPOINT +
        '/api/map/data/' +
        props.opportunity.recordId +
        '/' +
        encodeURI(props.address)
        console.log("address from props: "+ props.address);
      const result = await fetchCommand(path)

      //if data successfully fetched
      let location = result.data.location
      
      console.log("locationa: " + loc);
      if (result.message) {
        geocoder.geocode({ 'address': props.address }, function (results, status) {  
          if (status == google.maps.GeocoderStatus.OK) {
            console.log("geocode success");
            console.log(results[0].geometry.location.lat());
            console.log(results[0].geometry.location.lng());
            loc = {"lat":results[0].geometry.location.lat(), "lng":results[0].geometry.location.lng()};
            console.log("locationb: " + loc);
            setLocation(loc) // HPDS RENNEL PARINO 6/9/2023 - added initial location based on address
          } 
      }); 
        let markers = result.data.markers
        let mapData = result.data.mapData

        console.log("locationc: " + loc);

        //set the center of the map
        if (location !== '') setLocation(loc)

        //set the markers
        if (markers !== '') props.setMarkers(markers)

        //set the roof sections
        if (mapData !== '') {
          setMapData(mapData)
        }

        //indicate loading is finished
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Called when a feature is added to the map
  const AddFeature = (event, map) => {
    console.log('feature added!')

    //if this feature already has a marker index, don't add it again
    if (typeof event.feature.getProperty('markerIndex') !== 'undefined') {
      return
    }

    //give this feature an id
    if (event.feature.getProperty('id') === undefined) {
      var uid =
        Date.now().toString(36) +
        Math.random()
          .toString(36)
          .substr(2)
      event.feature.setProperty('id', uid)
    } else {
      return
    }

    let type = event.feature.getGeometry().getType()
    let shape = event.feature.getGeometry()

    //Adding a new Polygon or Point
    if (type === 'Polygon') {
      //Add a marker inside the polygon
      let center = getCenter(shape)
      props.setMarkers(prevState => {
        return prevState.concat(center)
      })

      //Set the color to the next available color
      setColors(prevState => {
        var color = prevState.splice(0, 1)
        event.feature.setProperty('color', color[0])
        return prevState.concat(color)
      })

      //Set the area
      let area = getArea(shape)
      event.feature.setProperty('area', area)

      //Set the perimeter
      let perimeter = getPerimeter(shape)
      event.feature.setProperty('perimeter', perimeter)

      //Set the index of the marker matched with this polygon
      props.setMarkers(prevState => {
        event.feature.setProperty('markerIndex', prevState.length - 1)
        return prevState
      })
    } else if (type === 'Point') {
      map.data.overrideStyle(event.feature, { draggable: true })
      event.feature.setProperty('isMarker', '1')

      const availableLabels =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      for (var i = 0; i < availableLabels.length; i++) {
        var exists = false
        map.data.forEach(feature => {
          if (feature.getGeometry().getType() == 'Point') {
            if (feature.getProperty('label') == availableLabels[i]) {
              exists = true
            }
          }
        })
        if (!exists) {
          event.feature.setProperty('label', availableLabels[i])
          break
        }
        if (i == availableLabels.length - 1) {
          event.feature.setProperty('label', '*')
        }
      }
    }

    //open description box
    handleEdit(event.feature)
  }

  const handleEdit = feature => {
    props.setEditFeature(feature)
    props.setEditMode(true)
  }

  const handleDelete = (map, feature, index) => {
    //remove the polygon feature
    map.data.remove(feature)

    if (feature.getProperty('isMarker') === undefined) {
      //remove and set the markers
      props.setMarkers(prevState => {
        let newState = prevState.slice()
        newState.splice(index, 1)
        return newState
      })

      //reset feature indexes for markers
      let i = 0
      map.data.forEach(feature => {
        if (feature.getProperty('isMarker') === undefined) {
          feature.setProperty('markerIndex', i)
          i++
        }
      })
    }

    //hide the message
    props.setMessage(null)
    props.setEditMode(false)
    props.setSelectedIndex(null)
    props.setSelectedLabel(null)
  }

  //Called when a feature is clicked on the map (Either a letter (A,B,C, etc) marker OR roof section)
  const SelectFeature = (event, map) => {
    //unselect other features
    map.data.revertStyle()
    map.data.forEach(feature => {
      map.data.overrideStyle(event.feature, { draggable: true })
    })

    let type = event.feature.getGeometry().getType()

    if (type === 'Polygon') {
      map.data.overrideStyle(event.feature, {
        editable: true,
        draggable: true,
        strokeWeight: 7
      })
      const feature = event.feature
      const index = feature.getProperty('markerIndex')
      props.setSelectedIndex(index)

      //open description box
      handleEdit(feature)

      //set banner message with details about this feature
      // let message =
      // <>
      //     {featureToString(feature)}
      //     <Button onClick={e => {handleDelete(map, feature, index)}} style={{padding: '0px', lineHeight: '1.5'}} color="secondary">Delete</Button>
      // </>;
      // props.setMessage(message);
    } else if (type === 'Point') {
      const feature = event.feature

      if (feature.getProperty('isMarker')) {
        const label = feature.getProperty('label')
        props.setSelectedLabel(label)
      } else {
        const index = feature.getProperty('markerIndex')
        props.setSelectedIndex(index)
      }

      //open description box
      handleEdit(feature)
    }
  }

  //called when a Roof Section marker (little white circle with number) is clicked on the map
  const SelectMarker = index => {
    let selectedFeature = null

    //look for the associated feature on the map
    props.map.data.forEach(feature => {
      if (feature.getProperty('markerIndex') === index) {
        selectedFeature = feature
        props.setSelectedIndex(index)
      }
    })

    //if a feature is found, select the feature as well
    if (selectedFeature !== null) {
      props.map.data.revertStyle()
      let type = selectedFeature.getGeometry().getType()

      if (type === 'Polygon') {
        props.map.data.overrideStyle(selectedFeature, {
          editable: true,
          draggable: true,
          strokeWeight: 7
        })

        //open description box
        handleEdit(selectedFeature)

        //set banner message with details about this feature
        // let message =
        // <>
        //     {featureToString(selectedFeature)}
        //     <Button onClick={e => {handleDelete(props.map, selectedFeature, index)}} style={{padding: '0px', lineHeight: '1.5'}} color="secondary">Delete</Button>
        // </>;
        // props.setMessage(message);
      }
    }
  }

  //Called for a generic map click
  const MapClick = (event, map) => {
    if (typeof (event.feature === 'undefined')) {
      map.data.revertStyle()
      props.setMessage(null)
      props.setSelectedIndex(null)
      props.setSelectedLabel(null)
      props.setEditMode(false)
    }
  }

  //Called when a shape is edited on the map - used to reset the area and perimeter
  const SetGeometry = (event, map) => {
    let type = event.feature.getGeometry().getType()

    if (type === 'Polygon') {
      let shape = event.feature.getGeometry()

      let feature = event.feature

      //Set the area
      let area = getArea(shape)
      feature.setProperty('area', area)

      //Set the perimeter
      let perimeter = getPerimeter(shape)
      feature.setProperty('perimeter', perimeter)

      let index = feature.getProperty('markerIndex')
      //set banner message with details about this feature
      // let message =
      // <>
      //     {featureToString(feature)}
      //     <Button onClick={e => {handleDelete(map, feature, index)}} style={{padding: '0px', lineHeight: '1.5'}} color="secondary">Delete</Button>
      // </>;
      // props.setMessage(message);
    }
  }


  //Setup map
  const setMap = map => {
    //set controls
    map.data.setControls(['Polygon', 'Point'])

    //set style
    map.data.setStyle(function (feature) {
      return {
        geodesic: true,
        fillColor: feature.getProperty('color'),
        strokeColor: feature.getProperty('color'),
        label: { text: feature.getProperty('label') + '' }
      }
    })

    map.addListener('click', event => {
      MapClick(event, map)
    })
    //add listeners
    map.data.addListener('addfeature', event => {
      AddFeature(event, map)
    })
    map.data.addListener('mousedown', event => {
      SelectFeature(event, map)
    })
    map.data.addListener('setgeometry', event => {
      SetGeometry(event, map)
    })

    // map.data.addListener('setproperty', SetProperty);
    //map.data.addListener('mouseup', MapMouseUp);
    // map.data.addListener('removefeature', RemoveFeature);

    props.setMap(map)
  }

  //Setup markers
  const setMarker = marker => {
    let lat = location.lat
    let lng = location.lng
    marker.setPosition({ lat, lng })
  }

  const resetMarker = (index, latLng) => {
    let lat = latLng.lat()
    let lng = latLng.lng()

    props.setMarkers(prevState => {
      //create shallow copy
      let newMarkers = [...prevState]

      //update the marker
      newMarkers[index] = {
        lat: lat,
        lng: lng
      }
      return newMarkers
    })
  }

  //create the view
  const element = (
    <div
      style={{
        display: 'block',
        height: 'calc(100vh - ' + barHeight + ')',
        width: '100%',
        position: 'relative'
      }}
    >
      <GoogleMap
        id='roof-section-map'
        onLoad={setMap}
        mapContainerStyle={ContainerStyle}
        zoom={Zoom}
        center={location}
        tilt={Tilt}
        options={Options}
      >
        {/* <Marker onLoad={setMarker} zIndex={1000} /> */}
        {props.markers.map((marker, i) => (
          <Marker
            onClick={e => {
              SelectMarker(i)
            }}
            onDragEnd={e => resetMarker(i, e.latLng)}
            key={'' + (i + 1)}
            icon={MarkerOptions.icon}
            draggable={true}
            zIndex={1000}
            position={marker}
            label={'' + (i + 1)}
          />
        ))}
      </GoogleMap>
      {!props.isMobile && (
        <SnapshotFrame
          displayFrame={props.displayFrame}
          setDisplayFrame={props.setDisplayFrame}
          snapshotSize={props.snapshotSize}
          setSnapshotSize={props.setSnapshotSize}
          isTabletOrMobile={props.isTabletOrMobile}
        />
      )}
    </div>
  )

  if (loading) {
    return <Loading />
  }

  return element
}

export default RoofSectionMap
