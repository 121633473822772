import React from "react";

class Loading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const element = (
            <div>Loading map...</div>
        );

        return element;
    }
}

export default Loading;