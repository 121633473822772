import React, {useState} from "react";
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router';


const Name = (props) => {

    const [goBack, setGoBack] = useState(false);
    // const classes = useStyles();
   

    if(goBack) {
        return <Redirect push to='/opportunities' />;
    }

    const element = (
        <div style={{marginLeft:'5%'}}>
            {/*HPDS Rennel Parino 12/15/2022 - This is the location of the Header.*/ }
        <span style={{fontWeight: 'bold', fontSize : '15px', color : '#333', width : '100%', display : 'block'}}>Opportunity</span>
            {/* <span style={{fontSize : (props.name.length > 30 ? '16px' : '22px'), fontWeight:'600', color:'#000'}}>{props.name}</span>  */}
            <span style={{fontSize: '22px', fontWeight:'600', color:'#000'}}>{props.name}</span> 
            <a style={{fontSize : '12px', color : 'gray', width : '100%', display : 'block'}}  onClick={e => {setGoBack(true)}}>← Go Back</a>
        </div>
        
    );

    return element;
}

export default Name;