import React, {useState, useEffect} from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useAuth0 } from "../../../react-auth0-wrapper";
import InputLabel from '@material-ui/core/InputLabel';
import { config } from "../../../config";

const ChangeOrderSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const { getTokenSilently } = useAuth0();

    useEffect(() => {
        //Every time selected job changes, reload the change orders
        loadChangeOrders(props.selectedJob.__ID);

        //set the selected changeOrder to null
        props.setSelectedChangeOrder(null);
    }, [props.selectedJob]);

    //Formats data from FileMaker to include the Record ID and Field Data in a JavaScript Array
    const formatList = (data) => {
        let list = data.map(item => {
            item.fieldData.recordId = item.recordId;
            return item.fieldData;
        });
        return list;
    }

    //Performs an HTTP POST request to the server
    //Returns a JSON object of the result
    const fetchCommand = async (url, data) => {
        const token = await getTokenSilently();
        const request = {
            method: 'POST',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            },
            body: JSON.stringify(data)
        };
        const result = await fetch(url, request);
        const obj = await result.json();
        return obj;
    }

    const loadChangeOrders = async (selectedJobId) => {
        try {
            setLoading(true);
            const path = config.API_ENDPOINT + '/api/getChangeOrders';
            const result = await fetchCommand(path, {id: selectedJobId});

            //if data successfully fetched
            if(result.message) {
                let list = formatList(result.data.response.data);
                props.setChangeOrders(list);
                setLoading(false);
            } else {
                props.setChangeOrders([]);
                setLoading(false);
            }
        } catch(error) {
            console.error(error);
        }
    }

    const handleSelect = (event) => {
        let changeOrder = props.changeOrders.find(changeOrder => changeOrder.__ID === event.target.value);

        if(typeof changeOrder === 'undefined') {
            changeOrder = null;
        }
        props.setSelectedChangeOrder(changeOrder);
    }

    const selectStyle = {
        width : '100%'
    }

    return (
        props.changeOrders.length > 0 && 
        <div>
            <br />
            <>
            <InputLabel id="co-select-label">Select a change order</InputLabel>
            <Select
              id="demo-simple-select2"
              labelid="co-select-label"
              value={props.selectedChangeOrder ? props.selectedChangeOrder.__ID : ''}
              onChange={handleSelect}
              style={selectStyle}
            >
              <MenuItem value={''}></MenuItem>
              {props.changeOrders.map(changeOrder => <MenuItem key={changeOrder.recordId} value={changeOrder.__ID}>CO {changeOrder.ChangeOrderNumber_Ae}</MenuItem>)}
            </Select>
            </>
        </div>
    );
}

export default ChangeOrderSelect;