import React, {useState, useEffect} from "react";
import { colors, makeStyles, Paper } from '@material-ui/core';
import { useAuth0 } from "../../react-auth0-wrapper";
import appPaths from '../../applicationPaths.json';
import ChooseAction from './Screen1/ChooseAction';
import SelectOptions from './Screen2/SelectOptions';
import ManageReport from './Screen3/ManageReport';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from 'react-responsive';
import backPng from '../../assets/icons/back_arrow.svg';
import styles from "./dailyReport.module.css";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { config } from "../../config";


const paperStyle = makeStyles(theme => ({
    
    wrapper: {
        width:'40%',
        margin:'0 auto',
        marginTop:'1.4%',
        paddingBottom:'2%',
        // background:'red',
        
        [theme.breakpoints.down('sm')]: {
            width:'60%',
            margin:'0 auto',
            marginTop:'3%',
            paddingBottom:'3%',
        },

        [theme.breakpoints.down('xs')]: {
            width:'100%',
            margin:'0 auto',
            marginTop:'0%',
            paddingBottom:'10%',
        }

    }

   
}))

const DailyReport = (props) => {

    const classes = paperStyle()
    const [jobs, setJobs] = useState([]);
    const [loadingJobs, setLoadingJobs] = useState(true);
    const { getTokenSilently } = useAuth0();
    const [currentScreen, setCurrentScreen] = useState(1);
    const [action, setAction] = useState(null);
    const [selectedReport, setSelectedReport] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [changeOrders, setChangeOrders] = useState([]);
    const [selectedChangeOrder, setSelectedChangeOrder] = useState(null);
    const [dateOfWork, setDateOfWork] = useState( new Date() );

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    useEffect(() => {
        //make sure job param is null to only set it once
        //make sure there is a parameter in the URL to set
        if(selectedJob === null && typeof props.match.params.id !== 'undefined') {
            //make sure job list contains the value
            let filteredJobs = jobs.filter( job => job.__ID === props.match.params.id );
            if( filteredJobs.length === 1 ) {
                setSelectedJob(filteredJobs[0]);
            }
        }
    }, [selectedJob, jobs]);

    useEffect(() => { 
        getData();
    }, [getTokenSilently]);
    
    //Performs an HTTP get request to the server
    //Returns a JSON object of the result
    const fetchCommand = async (url) => {
        const token = await getTokenSilently();
        const headers = { headers: { Authorization: 'Bearer ' + token } };
        const result = await fetch(url, headers);
        const obj = await result.json();
        return obj;
    }



    //Formats data from FileMaker to include the Record ID and Field Data in a JavaScript Array
    const formatList = (data) => {
        let list = data.map(item => {
            item.fieldData.recordId = item.recordId;
            return item.fieldData;
        });

        list.sort((item1, item2) => {
            if(item1.Job_Name < item2.Job_Name) {
                return -1;
            } else if(item1.Job_Name > item2.Job_Name) {
                return 1;
            } else {
                return 0;
            }
        });

        return list;
    }

    //Get data from the database
    const getData = async () => {
        
        try {
            const path = config.API_ENDPOINT + '/api/getJobsListActive';
            const result = await fetchCommand(path);

            //if data successfully fetched
            if(result.message) {
                let list = formatList(result.data.response.data);
                setJobs(list);
                setLoadingJobs(false);
            } else {
                console.log('No Jobs found.');
                setLoadingJobs(false);
            }

        } catch(error) {
            console.error(error);
        }
    };

    const goBack = () => {
        if(currentScreen > 1 && currentScreen <= 3) {
            let screen = currentScreen - 1;
            setCurrentScreen(screen);
        }
    }

    const style = {
        // paddingTop: '3px',
        // paddingLeft: currentScreen === 3 ? '0' : '10%',
        // paddingRight: currentScreen === 3 ? '0' : '10%',
        textAlign: 'center',
        fontSize:'18px',
        // background:'pink',
        width:'80%',
        margin:'0 auto',
        
    }   
  

    const btnStyle = {
        // fontWeight : 'bold',
        fontSize: '12px',
        padding: '12px 25px',
        fontWeight: '400',
        color:'#2d2d2dde'
    }

    return (
        <>
        <Paper className={classes.wrapper}>
            <div>
            {/* Back button */}
            {/* {currentScreen > 1 && <Button onClick={goBack} style={btnStyle}>&#8592; Back</Button>} */}

            {currentScreen > 1 && <Button onClick={goBack} style={btnStyle}>
                <img src={backPng} alt='back arrow' className="back__icon" />
                {/* <TrendingFlatIcon style={{transform: [{rotate: '180deg'}]}}></TrendingFlatIcon> */}
                Back
            </Button>}
            
            <div style={style}>
                <h2 className={styles.report_heading}>Daily Report</h2>   

                {/* Screen 1: Choose Action */}
                <ChooseAction 
                
                    currentScreen={currentScreen} 
                    setCurrentScreen={setCurrentScreen} 
                    setAction={setAction}
                />


            {/* <div style={{background:'red', width:'50%'}}> */}
                {/* Screen 2: Select Options */}
                <SelectOptions style={{width:'50%'}}
                    currentScreen={currentScreen} 
                    setCurrentScreen={setCurrentScreen}
                    action={action} 
                    jobs={jobs} 
                    loadingJobs={loadingJobs}
                    selectedJob={selectedJob}
                    setSelectedJob={setSelectedJob}
                    selectedReport={selectedReport}
                    setSelectedReport={setSelectedReport}
                    changeOrders={changeOrders}
                    setChangeOrders={setChangeOrders}
                    selectedChangeOrder={selectedChangeOrder}
                    setSelectedChangeOrder={setSelectedChangeOrder}
                    dateOfWork={dateOfWork}
                    setDateOfWork={setDateOfWork}
                />


                {/* Screen 3: Manage Report */}
                <ManageReport 
                    currentScreen={currentScreen} 
                    setCurrentScreen={setCurrentScreen}
                    selectedReport={selectedReport}
                    selectedJob={selectedJob}
                />

            {/* </div> */}

            </div>
        
            </div>
        </Paper>

        </>
    );
}

export default DailyReport;