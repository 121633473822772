import React, {useState, useEffect} from "react";
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  dialogSpacing: {
    padding:'16px',
  },

  dialogContainer: {
    width:'100%',
    textAlign:'center'
  },

  dialogImage: {
    width:'50%'
  },

  dialogTextarea: {
    width:'100%'
  }

}));

const EditDialog = (props) => {
    const [description, setDescription] = useState('');
    const classes = useStyles();

    const styles = theme => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },

        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });
      
      const DialogTitle = withStyles(styles)(props => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
           
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });
      
      const DialogContent = withStyles(theme => ({
        root: {
          padding: theme.spacing(2),
        },

      }))(MuiDialogContent);
      
      const DialogActions = withStyles(theme => ({
        root: {
          margin: 0,
          padding: theme.spacing(1),
        },
      }))(MuiDialogActions);


    const handleClose = () => {
        props.setDialogOpen(false);
    };

    useEffect(() => {
        if(props.dialogOpen) {
            setDescription(props.selectedPhoto.Description);
        }
    }, [props.dialogOpen]);

    //Update the text description of a photo when changed
    const onChangeHandler = (event) => {
        event.preventDefault();
        setDescription(event.target.value);
    }

    const onSaveHandler = () => {
        props.setDialogOpen(false);
        props.updateDescription(description);
    }

    const modalStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'scroll',
    }

    const dialog = (
      <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.dialogOpen}>
        
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Edit Photo
        </DialogTitle>
        
        <div className={classes.dialogSpacing}>

            {/* Display the photo */}
            <div className={classes.dialogContainer}>
                <img className={classes.dialogImage} src={props.selectedPhoto.base64Img} />
            </div> 

            {/* Edit the caption */}
            <div className={classes.dialogContainer}>
                <TextField className={classes.dialogTextarea} multiline rowsMax="4" onChange={onChangeHandler} value={description} />
            </div>
        </div>

        <DialogActions>
        
          <Button onClick={e => props.deletePhoto(props.selectedPhoto.__ID)} color="primary">
            Delete
          </Button>
          
          <Button onClick={onSaveHandler} color="primary">
            Save
          </Button>
        
        </DialogActions>
      
      </Dialog>
    );

    return dialog;
}

export default EditDialog;