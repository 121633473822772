import React, { useState, useEffect } from 'react';
import {CircularProgress, Button, Select, MenuItem, OutlinedInput, TextField, Zoom} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import theme from '../../../themes/theme';
import { useAuth0 } from "../../../react-auth0-wrapper";
import Compress from 'compress.js';
import lists from './individual.module.css';
import { makeStyles } from '@material-ui/core/styles';
import { config } from "../../../config";

const useStyles = makeStyles(theme => ({

  upload_primaryButton: {
    color : 'white',
    backgroundColor: '#24a0ed',
    width: '100%',
    fontSize:'14px',
    marginTop:'10px',
    '&:hover': {
      backgroundColor: '#258fd2',
    }
  },

  upload_secondaryButton: {
    backgroundColor: '#535b69',
    color: '#fff',
    width: '100%',
    fontSize:'14px',
    '&:hover': {
        backgroundColor: '#494f5a',
    }
},

}));



export default function Upload (props) {
    const classes = useStyles();
    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user } = useAuth0();
    const [selectedFile, setSelectedFile] = useState(null);
    const [loaded, setLoaded] = useState(true);
    const [buttonText, setButtonText] = useState('Select Photo');
    const [isImage, setIsImage] = useState(false);
    const [beforeAfter, setBeforeAfter] = useState('Before');
    const [caption, setCaption] = useState('');
    const [captionList, setCaptionList] = useState([]);

    useEffect(() => {
      getCaptions();
    }, [getTokenSilently]);

    
    /**
     * Gets a list of pre-defined image captions from FileMaker, and populates the auto-complete drop down
     */
    const getCaptions = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(config.API_ENDPOINT + "/api/getCaptionList", {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
        });
        const responseData = await response.json();

        if(responseData.message) {
          setCaptionList(responseData.data.response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };


    /**
     * Image upload field change handler
     * @param {*} event 
     */
    const onImageSelect = (event) => {
       event.preventDefault()
        //Verify image file
        if(event.target.files.length > 0) {
          setSelectedFile(event.target.files[0]);
          setButtonText(event.target.files[0].name);
          setIsImage(true);
        } else {
          setSelectedFile(false);
          setButtonText('Select Image');
          setIsImage(false);
        }
    }

    /**
     * Before/after select change handler
     * @param {*} event 
     */
    const onBeforeAfterChange = (event) => {
      event.preventDefault()
      setBeforeAfter(event.target.value);
    };

    /**
     * Submit handler
     */
    const onSubmit = () => {
        if( !selectedFile ) {
          return alert('Error: No photo selected.');
        }

        //Set loading
        setLoaded(false);
        props.roofapi(true)
        //Testing image compression
        const compress = new Compress();

        compress.compress([selectedFile], {
          size: .25, // the max size in MB, defaults to 2MB
          quality: .75, // the quality of the image, max is 1,
          maxWidth: 400, // the max width of the output image, defaults to 1920px
          maxHeight: 400, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
        }).then((compressedImages) => {
          console.log('Selected File:', selectedFile);
          // returns an array of compressed images
          console.log('Compressed Image Data:', compressedImages);

          //Get the marker id
          var markerId = props.feature.getProperty('id') === undefined ? '' : props.feature.getProperty('id');

          //Prepare form data
          const formData = new FormData();
          formData.append('filename', selectedFile.name);
          formData.append('file', compressedImages[0].data);
          formData.append('_idf_opportunity', props.opportunity.id);
          formData.append('_idf_marker', markerId);
          formData.append('BeforeAfter', beforeAfter);
          formData.append('Description', caption);
          formData.append('source', 'file');
          formData.append('username', user.name);
          //upload data
          uploadImage(formData, 0);
        });
    };

    /**
     * Attempts to upload an image to FileMaker server
     * 
     * @param {} form form data
     * @param {*} i nth attempt to upload image (starts at 0 and attempts 9 times)
     */
    const uploadImage = async (form, i) => {
        try {
          //Get server token
          const token = await getTokenSilently();
      
          //Create the fetch request and wait for response
          const response = await fetch(config.API_ENDPOINT + "/api/opportunities/"+ props.opportunity.id + "/photos", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Accept': 'application/json'   // expected data sent back
            },
            body: form
          });
      
          //Parse JSON response
          const responseData = await response.json();

          //Check if upload was successful, or failed
          if(responseData.data.message || i > 9) {
            if(i > 9) {
              if(window.confirm("Image failed to upload! Would you like to try resending it?")) {
                uploadImage(form, 0);
              }
            } else {
              //Close the modal
              props.setDialogOpen(false);

              //Re-load images from FileMaker
              props.setReloadPhotos(true);
            }
          } else {
            //retry image upload if failed less than 9 times
            uploadImage(form, i + 1)
          }
          
        } catch (error) {
          console.error(error);
        }
      };

    const btnStyle = {
     color : 'white',
    backgroundColor: '#24a0ed',
    width: '100%',
    fontSize:'14px',
    marginTop:'10px',
    '&:hover': {
      backgroundColor: '#258fd2',
    }
    }


    return (
        <div className={lists.upload_wrapper}>
        {loaded &&
        <Card className={lists.upload_card}>
          <Zoom in={true}
        {...({ timeout: 1000})}>
            <div style={{marginBottom: '12px'}}>
              <Select
                onChange={onBeforeAfterChange}
                value={beforeAfter}
                style={{width: '100%', height: '40px'}}
                input={<OutlinedInput name="beforeAfter" id="beforeAfter" />}
              >
                <MenuItem value='Before'>Before</MenuItem>
                <MenuItem value='After'>After</MenuItem>
              </Select>
            </div>
            </Zoom>
          <Zoom in={true}
              {...({ timeout: 1500})}>
              <div className={lists.upload_spacing}>
              <TextField 
                className={lists.fullWidth}
                onChange={e => setCaption(e.target.value)} 
                name='caption'
                value={caption}
                label="Caption"
                placeholder="Caption"
              />
              </div>
            </Zoom>
          
          
          <Zoom in={true}
        {...({ timeout: 500})}>
              <div className={lists.upload_spacing}>
                <input
                  accept="image/*"
                  style={{display:'none'}}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={onImageSelect}
                />

                <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" className={classes.upload_primaryButton}>
                    {buttonText}
                </Button>
                </label>
              </div>
            </Zoom>
               
           
            
            <Zoom in={true}
        {...({ timeout: 2000})}>
            <div className={lists.upload_spacing}>
            {/* <Button onClick={onSubmit} disabled={!isImage} variant="contained" color="primary" style={btnStyle_dark}> */}
            <Button onClick={onSubmit} variant="contained" className={classes.upload_secondaryButton}>
                Submit
            </Button>
            </div>
            </Zoom>
            
        </Card>
        }
        {!loaded && <div style={{height: '75px'}}><CircularProgress></CircularProgress></div>}
        </div>
    )
}