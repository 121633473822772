import React, {useState, useEffect} from "react";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        color:'#1b1b1b',
        fontSize:'16px',
        fontWeight:'400',
		marginBottom :'10px',
      '& > *': {
        margin: theme.spacing(1),
      }
    },
  }));

const ChooseAction = (props) => {
    const classes = useStyles();

    const btnStyle = {
        color : 'white',
        fontWeight : '400',
        backgroundColor: '#535b69',
        width: '100%',
        marginLeft: '0',
        marginRight: '0',
		marginTop:'5px',
		'&:hover':{
            backgroundColor :'#535b69',
            
            boxShadow:'3px 2px 3px #535b69',
        },
    }
	const modifybtnStyle ={
		color : 'white',
        fontWeight : '400',
        backgroundColor: '#24a0ed',
        width: '100%',
        marginLeft: '0',
        marginRight: '0',
		marginTop:'5px',
        '&:hover':{
            backgroundColor :'#535b69',
            
            boxShadow:'3px 2px 3px #535b69',
        },
	}

    const handleButtonClick = (action) => {
        props.setAction(action);
        props.setCurrentScreen(2);
    }


    if(props.currentScreen !== 1) {
        return null;
    }

    return (
        <div className={classes.root}>
            Choose Action<br /><br />

            <Button variant="contained" style={btnStyle} onClick={e => {handleButtonClick('new')}}>Create New Report</Button>
            <Button variant="contained" style={modifybtnStyle} onClick={e => {handleButtonClick('modify')}}>Modify Existing Report</Button>
        </div>
    );
}

export default ChooseAction;