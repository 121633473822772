import React from 'react'
import NavBar from './components/NavBars/Navbar'
import { Router, BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/Routes/PrivateRoute'
import AdminRoute from './components/Routes/AdminRoute'

import Home from './components/Home/Home'
import AlertDetails from './components/Alert/AlertDetails'
import AlertList from './components/Alert/AlertList'
import ReportLeak from './components/Opportunities/AddOpportunity/ContactDuraroof'
import AddOpportunity from './components/Opportunities/AddOpportunity/ContactDuraroof'
import Job from './components/Job/Job'
import JobsList from './components/Job/JobsList'
import { useAuth0 } from './react-auth0-wrapper'
import JobMap from './components/Job/JobMap'
import JobCamera from './components/Job/JobCamera'
import JobImages from './components/Map/JobImageList'
import JobUpload from './components/Job/JobUpload'
import JobInvoice from './components/Invoice/Invoice'
import JobEditInvoice from './components/Invoice/editInvoice/Invoice'

import Timecard from './components/Timecard/Timecard'

import ReportLeakStaff from './components/Alert/ReportLeak'
import CreateUser from './components/User/CreateUser'
import OpportunitiesList from './components/Opportunities/List/OpportunitiesList'
import Opportunity from './components/Opportunities/Individual/Opportunity'
import DailyReport from './components/DailyReport/DailyReport'
import history from './utils/history'
import ActionDetail from './components/Opportunities/Individual/ActionDetail'

function App () {
  const { loading, isAuthenticated } = useAuth0()

  if (loading) {
    return (
      <BrowserRouter>
        <NavBar />
      </BrowserRouter>
    )
  } else {
    if (isAuthenticated) {
      //connect();
    }
  }

  return (
    <>
      <Router history={history}>
        <NavBar />
        <Switch>
          {/* Home Route */}
          <Route path='/' exact component={Home} />

          {/* Reporting Leak Routes */}
          <Route path='/report-leak' exact component={ReportLeak} />
          <PrivateRoute
            path='/report-leak-staff'
            exact
            component={ReportLeakStaff}
          />

          {/* Alert Routes */}
          <AdminRoute path='/alert-details' exact component={AlertDetails} />
          <AdminRoute path='/alert-list' exact component={AlertList} />

          {/* Timecard Routes */}
          <PrivateRoute path='/timecard' exact component={Timecard} />

          {/* User Routes */}
          <AdminRoute path='/create-user' exact component={CreateUser} />

          {/* Opportunity Routes */}
          <Route path='/add-opportunity' exact component={AddOpportunity} />
          <PrivateRoute
            path='/opportunities'
            exact
            component={OpportunitiesList}
          />
          <PrivateRoute
            path='/opportunities/:opid'
            exact
            component={Opportunity}
          />
          <PrivateRoute
            exact
            path='/opportunity/action/:opid'
            component={ActionDetail}
          />

          {/* Daily Report Routes */}
          <PrivateRoute path='/daily-report' exact component={DailyReport} />
          <PrivateRoute
            path='/daily-report/:id'
            exact
            component={DailyReport}
          />

          {/* Routes for Jobs */}
          <PrivateRoute path='/job' exact component={Job} />
          <PrivateRoute path='/jobs-list' exact component={JobsList} />
          <PrivateRoute path='/job-map' exact component={JobMap} />
          <PrivateRoute path='/job-camera' exact component={JobCamera} />
          <PrivateRoute path='/job-images' exact component={JobImages} />
          <PrivateRoute path='/job-upload' exact component={JobUpload} />
          <PrivateRoute path='/job-invoice' exact component={JobInvoice} />
          <PrivateRoute
            path='/job-edit-invoice'
            exact
            component={JobEditInvoice}
          />
          <PrivateRoute path='/job/:id' exact component={Job} />
        </Switch>
      </Router>
    </>
  )
}

export default App
