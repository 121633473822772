import React, { useState, useEffect } from 'react'
import Drawer from '@material-ui/core/Drawer'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import EditMobile from './EditMobile'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import PhotoDialog from './PhotoDialog'
import PhotoList from './PhotoList'
import lists from './individual.module.css'
import NumberFormat from 'react-number-format'
import { useAuth0 } from '../../../react-auth0-wrapper'
import Autocomplete from '@mui/material/Autocomplete'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Collapse } from '@material-ui/core'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
// import { deleteFile } from '../../../../../server/api/s3'
//import TextField from '@mui/material/TextField';
import { config } from '../../../config'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginBottom: '25px'
  },

  edit_formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
    width: '100%',
    marginTop: '50px',
    fontWeight: '500'
  },

  edit_deleteButton: {
    padding: '0px',
    lineHeight: '1.5',
    color: 'red',
    position: 'fixed',
    marginLeft: '-135px'
  },

  edit_doneButton: {
    padding: '0px',
    lineHeight: '1.5',
    position: 'fixed',
    marginLeft: '-70px'
  },

  edit_primaryButton: {
    color: 'white',
    backgroundColor: '#24a0ed',
    width: '95%',
    fontSize: '14px',
    position: 'absolute',
    left: '15px',
    bottom: '10px',
    '&:hover': {
      backgroundColor: '#258fd2'
    }
  },

  edit_primaryButtonMobile: {
    color: 'white',
    backgroundColor: '#24a0ed',
    width: '100%',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#258fd2'
    }
  }
}))

const Edit = props => {
  const classes = useStyles()
  const [description, setDescription] = useState('')
  const [replacement, setEstReplacement] = useState('')
  const [repair, setEstRepair] = useState('')
  const [repairCost, setCostOfRepair] = useState('')
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false)
  const [descriptionHeight, setDescriptionHeight] = useState('150px')
  const [selectedPhoto, setSelectedPhoto] = useState({})
  const [editOpen, setEditOpen] = useState(false)
  const [editMobileOpen, setEditMobileOpen] = useState(false)
  const [roofType, setRoofType] = useState('')
  const [yearType, setRoofYear] = useState([])
  const { getTokenSilently } = useAuth0()
  const [descriptionInputValue, setDescriptionInputValue] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [testInput, setTestInput] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [newRoofName, setNewRoofName] = useState('')
  // const roofType1 = [
  //   { "id": 1, name: "BUR"},
  //   { "id": 2, name: "TPO rhino bond"},
  //   { "id": 3, name: "EPDM ballast"},
  //   { "id": 4, name: "TPO fully adhered"},
  //   { "id": 5, name: "EPDM fully adhered"},
  // ]
  const years = []

  const allRoofs = props.roofTypes

  let setAllRoofs = props.setRoofTypes

  var year = new Date().getFullYear()

  for (var i = 0; i < 30; i++) {
    if (year + i == year) years.push(year + i)
    else years.push(year + i)
  }

  let style = {
    width: '400px',
    height: '452px',
    backgroundColor: 'rgba(255,255,255,0.92)',
    color: 'black',
    position: 'fixed',
    zIndex: '4',
    bottom: '10px',
    left: '15px',
    transition: '1s',
    padding: '15px 18px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  }

  let mobileStyle = {
    width: '75vw',
    height: '70px',
    backgroundColor: 'rgba(255,255,255,0.9)',
    color: 'black',
    borderRadius: '5px',
    position: 'fixed',
    zIndex: 4,
    bottom: '8px',
    left: '7px',
    transition: '1s',
    padding: '7px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  }

  let extraSpace = '89px'

  if (props.isTabletOrMobile) {
    extraSpace = '89px'
  }

  useEffect(() => {
    // console.log(props.opportunity, 'propssss')
    if (props.feature !== null) {
      // console.log('feature changed...')
      // console.log('description: ', props.feature.getProperty('description'))
      if (
        typeof props.feature.getProperty('description') === 'undefined' ||
        props.feature.getProperty('description') == null
      ) {
        setDescription('')
        setDescriptionInputValue('')
      } else {
        setDescription(props.feature.getProperty('description'))
        setDescriptionInputValue(props.feature.getProperty('description'))
      }

      if (typeof props.feature.getProperty('roof_type_id') === 'undefined') {
        setRoofType('')
      } else {
        setRoofType(props.feature.getProperty('roof_type_id'))
      }

      if (typeof props.feature.getProperty('remaining_year') === 'undefined') {
        setRoofYear('')
      } else {
        setRoofYear(props.feature.getProperty('remaining_year'))
      }

      if (
        typeof props.feature.getProperty('replacement_estimate') === 'undefined'
      ) {
        setEstReplacement('')
      } else {
        setEstReplacement(props.feature.getProperty('replacement_estimate'))
      }

      if (typeof props.feature.getProperty('repair_estimate') === 'undefined') {
        setEstRepair('')
      } else {
        setEstRepair(props.feature.getProperty('repair_estimate'))
      }

      if (typeof props.feature.getProperty('cost_for_repair') === 'undefined') {
        setCostOfRepair('')
      } else {
        setCostOfRepair(props.feature.getProperty('cost_for_repair'))
      }

      if (props.isMobile) {
        setDescriptionHeight('50px')
      }
    }
  }, [props.feature])

  const setMarkerDescription = event => {
    if (!event.target.value) {
      event.target.value = ''
    }

    const shouldSetValue = event.target.value.length < 500
    if (shouldSetValue) {
      props.feature.setProperty('description', event.target.value)
      setDescription(event.target.value)
      setDescriptionInputValue(event.target.value)
    }
  }

  const setMarkerRoofType = event => {
    props.feature.setProperty('roof_type_id', event.target.value)
    setRoofType(event.target.value)
    console.log(event.target.value, 'event target value')
    const roof_name = props.roofTypes.filter(
      val => val.recordId === event.target.value
    )
    props.feature.setProperty('roof_type', roof_name[0].name || 'BUR')
  }
  const setMarkerRemainingYear = event => {
    props.feature.setProperty('remaining_year', event.target.value)
    setRoofYear(event.target.value)
  }
  const setMarkerEstReplacement = values => {
    const { formattedValue, value } = values
    props.feature.setProperty('replacement_estimate', formattedValue)
    setEstReplacement(formattedValue)
  }
  const setMarkerEstRepair = values => {
    const { formattedValue, value } = values
    props.feature.setProperty('repair_estimate', formattedValue)
    setEstRepair(formattedValue)
  }
  const setMarkerCostOfRepair = values => {
    const { formattedValue, value } = values
    props.feature.setProperty('cost_for_section', formattedValue)
    setCostOfRepair(formattedValue)
  }

  const handleDelete = (map, feature) => {
    //remove the polygon feature
    map.data.remove(feature)

    if (feature.getProperty('isMarker') === undefined) {
      let index = feature.getProperty('markerIndex')

      //remove and set the markers
      props.setMarkers(prevState => {
        let newState = prevState.slice()
        newState.splice(index, 1)
        return newState
      })

      //reset feature indexes for roof section markers
      let i = 0
      map.data.forEach(feature => {
        if (feature.getProperty('isMarker') === undefined) {
          feature.setProperty('markerIndex', i)
          i++
        }
      })
    }

    //hide the message
    props.setMessage(null)
    props.setOpen(false)
    props.setSelectedIndex(null)
  }
  const handleChange = e => {
    console.log(e.target.value, 'target')
    setNewRoofName(e.target.value)

    console.log(newRoofName)
  }
  const handleClose = async () => {
    props.setOpen(false)
    props.setSelectedIndex(null)
    props.map.data.revertStyle()
    //Format all the data
    let geoJson = await getGeoJson(props.map)

    let obj = {
      mapData: geoJson,
      markers: props.markers,
      center: props.map.getCenter()
    }

    let data = JSON.stringify(obj)

    //Save Map data to server
    let result = await saveMapData(props.opportunity.recordId, data)

    //Display result to user
    // if(result) {
    //     props.setMessage('Changes saved.');
    // } else {
    //     props.setMessage('Error saving. Please try again.');
    // }
  }

  const fetchCommand = async (url, data) => {
    const token = await getTokenSilently()
    const request = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json'
      },
      body: JSON.stringify(data)
    }
    console.log('url', url)
    console.log('reqeust', request.body)
    const result = await fetch(url, request)

    const obj = await result.json()
    return obj
  }
  // DELETE REQUEST
  const deleteCommand = async (url, data) => {
    const token = await getTokenSilently()
    const request = {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json'
      },
      body: data
    }
    const result = await fetch(url, request)
    const obj = await result.json()
    return obj
  }

  // UPDATE REQUEST
  const updateCommand = async (url, data) => {
    const token = await getTokenSilently()
    const request = {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json'
      },
      body: data
    }
    const result = await fetch(url, request)
    const obj = await result.json()
    return obj
  }

  const saveMapData = async (opid, data) => {
    try {
      const path = config.API_ENDPOINT + '/api/opportunities/map/' + opid
      const result = await fetchCommand(path, data)

      return result.message
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const addRoofTypeFM = async data => {
    console.log('DATA', data)
    try {
      const path = config.API_ENDPOINT + `/api/settings/rooftypes/`
      const result = await fetchCommand(path, data)
      console.log(path, data, result)
      return result.message
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const addRoofType = async (id, name) => {
    let newRoof = {
      recordId: id,
      name: name
    }

    console.log(newRoof.recordId)
    let result = await addRoofTypeFM(newRoof)
    window.location.reload(false)
    // setAllRoofs = props.setRoofTypes + newRoof
    return result
  }

  const handleModalOpen = event => {
    setModalOpen(true)
    setAnchorEl(event.currentTarget)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }

  const deleteRoofTypeFM = async id => {
    try {
      const path = config.API_ENDPOINT + `/api/settings/rooftypes/${id}`
      let result = await deleteCommand(path, id)

      return result.message
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const deleteRoofType = async id => {
    console.log('id to delete', id)
    let result = await deleteRoofTypeFM(id)
    console.log('alll roofs delete roof type', allRoofs)
    let updatedRoofTypes = allRoofs.filter(roof => roof.recordId !== id)
    setAllRoofs(updatedRoofTypes)

    return result
  }

  const getGeoJson = map => {
    let promise = new Promise((resolve, reject) => {
      map.data.toGeoJson(object => {
        resolve(object)
      })
    })

    return promise
  }

  const featureElement = feature => {
    let area = feature.getProperty('area')
    let perimeter = feature.getProperty('perimeter')

    if (area === undefined) {
      return (
        <>
          &nbsp;
          <br />
        </>
      )
    }

    return (
      <div className={lists.edit_form}>
        <p className={lists.edit_boldText}>
          <span>Area:</span> {area.toFixed(2)} Sq Ft &nbsp;&nbsp;&nbsp;
          <span>Perimeter:</span> {perimeter.toFixed(2)} Ft
        </p>

        <FormControl className={classes.edit_formControl}>
          <span> Roof Type </span>
          <Select
            className={lists.edit_input}
            value={roofType}
            onChange={setMarkerRoofType}
            // inputProps={{
            //   name: 'workType',
            //   id: 'workType'
            // }}
          >
            {allRoofs.map((roofs, index) => (
              <MenuItem value={roofs.recordId} key={index}>
                {roofs.name}
                <HighlightOffIcon
                  onClick={() => deleteRoofType(roofs.recordId)}
                />
              </MenuItem>
            ))}
          </Select>
          <input
            type='text'
            onChange={handleChange}
            value={newRoofName}
            placeholder='Add new Roof Type'
          />
          <Button onClick={() => addRoofType(allRoofs.length + 1, newRoofName)}>
            ADD NEW ROOF
          </Button>
        </FormControl>
        {'  '}

        <FormControl className={classes.edit_formControl}>
          Suggested Year of Replacement
          <Select
            className={lists.edit_input}
            value={yearType}
            onChange={setMarkerRemainingYear}
            inputProps={{
              name: 'yearType',
              id: 'yearType'
            }}
          >
            {years.map((year, index) => (
              <MenuItem value={year} key={index}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  }

  let sidebar = (
    <div style={props.isMobile ? mobileStyle : style}>
      <div className={lists.edit_formHeading}>
        {props.feature && props.feature.getProperty('isMarker') === undefined
          ? 'Roof Section ' +
            (Number(props.feature.getProperty('markerIndex')) + 1)
          : ''}
        {props.feature && props.feature.getProperty('isMarker') !== undefined
          ? 'Marker ' + props.feature.getProperty('label')
          : ''}
      </div>

      <div className={lists.edit_topbuttons}>
        <Button
          onClick={e => {
            handleDelete(props.map, props.feature)
          }}
          className={classes.edit_deleteButton}
        >
          Delete
        </Button>
        <Button
          onClick={e => {
            handleClose()
          }}
          className={classes.edit_doneButton}
          color='secondary'
        >
          Done
        </Button>
      </div>

      <div className={lists.edit_clearStyle}></div>
      {props.feature && !props.isTabletOrMobile
        ? featureElement(props.feature)
        : ''}

      {!props.isMobile &&
      props.feature &&
      props.feature.getProperty('isMarker') === undefined ? (
        <>
          {/* added for tablet view */}

          <div className={lists.edit_inputWrapper}>
            <p className={lists.edit_inputLabel}>Est. Replacement Cost</p>
            <NumberFormat
              className={lists.fullWidth}
              decimalScale={2}
              onFocus={e => {
                props.setTextBoxFocus(true)
              }}
              onBlur={e => {
                props.setTextBoxFocus(false)
              }}
              id='replacement_cost'
              value={replacement}
              customInput={TextField}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
              onValueChange={values => setMarkerEstReplacement(values)}
            />
          </div>

          <div className={lists.edit_inputWrapper}>
            <p className={lists.edit_inputLabel}>Est. Annual Repair Cost</p>
            <NumberFormat
              className={lists.fullWidth}
              decimalScale={2}
              onFocus={e => {
                props.setTextBoxFocus(true)
              }}
              onBlur={e => {
                props.setTextBoxFocus(false)
              }}
              id='repair_cost'
              value={repair}
              customInput={TextField}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
              onValueChange={values => setMarkerEstRepair(values)}
            />
            <br />
          </div>

          <div className={lists.edit_inputWrapper}>
            <p className={lists.edit_inputLabel}>Condition of Roof/Comment</p>
            <textarea
              onChange={setMarkerDescription}
              onFocus={e => {
                props.setTextBoxFocus(true)
              }}
              onBlur={e => {
                props.setTextBoxFocus(false)
              }}
              value={description}
              style={{
                width: '100%',
                height: '70px',
                descriptionHeight,
                boxSizing: 'border-box',
                padding: '8px',
                marginTop: '10px',
                color: 'currentcolor',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
              }}
            ></textarea>
          </div>

          <div className={lists.edit_inputWrapper}>
            <p className={lists.edit_inputLabel}>
              Cost of Repair for this Section
            </p>
            <NumberFormat
              className={lists.fullWidth}
              onFocus={e => {
                props.setTextBoxFocus(true)
              }}
              onBlur={e => {
                props.setTextBoxFocus(false)
              }}
              id='to_cost'
              value={repairCost}
              customInput={TextField}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
              onValueChange={values => setMarkerCostOfRepair(values)}
            />
            {/* <TextField
              className={lists.fullWidth}
              onFocus={ e => {props.setTextBoxFocus(true)} }
              onBlur={ e => {props.setTextBoxFocus(false)} }
              onChange={setMarkerCostOfRepair}
              value={repairCost}
              id="to_cost"
            /> */}
          </div>

          {/* <div className={lists.edit_inputWrapper}>
            <p className={lists.edit_inputLabel}>Photos</p> 

          <div className={lists.edit_photoContainer}>

            <PhotoList 
                photos={props.photos} 
                setPhotos={props.setPhotos} 
                loadingPhotos={props.loadingPhotos} 
                setLoadingPhotos={props.setLoadingPhotos}
                reloadPhotos={props.reloadPhotos}
                setReloadPhotos={props.setReloadPhotos}
                opportunity={props.opportunity}
                editOpen={editOpen}
                setEditOpen={setEditOpen}
                selectedPhoto={selectedPhoto}
                setSelectedPhoto={setSelectedPhoto}
                selectedFeature={props.feature}
          </div>
          </div>

          <div className={lists.edit_Button}>
            <Button onClick={e => setPhotoDialogOpen(true)} variant="contained" className={classes.edit_primaryButton}>Add Photo</Button>
          </div> */}
        </>
      ) : (
        <>
          {' '}
          <div className={lists.edit_inputWrapper_one}>
            <p className={lists.edit_inputLabel}>Description</p>
            <textarea
              onChange={setMarkerDescription}
              onFocus={e => {
                props.setTextBoxFocus(true)
                console.log('focused')
              }}
              onBlur={e => {
                props.setTextBoxFocus(false)
              }}
              value={description}
              style={{
                width: '100%',
                height: '70px',
                descriptionHeight,
                boxSizing: 'border-box',
                padding: '8px',
                marginTop: '10px',
                color: 'currentcolor',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
              }}
            ></textarea>
            {/*<Autocomplete
            id="free-solo-demo"
            freeSolo
            options={props.problemList}
            onChange={(event, newValue) => {
              console.log('value changed');
              setDescription(newValue);
              props.feature.setProperty('description', newValue);
            }}
            value={description}
            inputValue={descriptionInputValue}
            onInputChange={(event, newInputValue) => {
              console.log('input value changed');
              setDescriptionInputValue(newInputValue);
              props.feature.setProperty('description', newInputValue);
            }}
            renderInput={(params) => <TextField {...params} label="Description of Problem" />}
          />*/}

            <p className={lists.edit_inputLabel}>Photos</p>

            <div className={lists.edit_photoContainer}>
              <PhotoList
                photos={props.photos}
                setPhotos={props.setPhotos}
                loadingPhotos={props.loadingPhotos}
                setLoadingPhotos={props.setLoadingPhotos}
                reloadPhotos={props.reloadPhotos}
                setReloadPhotos={props.setReloadPhotos}
                opportunity={props.opportunity}
                editOpen={editOpen}
                setEditOpen={setEditOpen}
                selectedPhoto={selectedPhoto}
                setSelectedPhoto={setSelectedPhoto}
                selectedFeature={props.feature}
              />
            </div>
          </div>
          <div className={lists.edit_Button}>
            <Button
              onClick={e => setPhotoDialogOpen(true)}
              variant='contained'
              className={classes.edit_primaryButton}
            >
              Add Photo
            </Button>
          </div>
        </>
      )}

      {props.isMobile && (
        <>
          <div className={lists.edit_Button}>
            <Button
              onClick={e => setEditMobileOpen(true)}
              className={classes.edit_primaryButtonMobile}
            >
              Add Description and Photos
            </Button>
          </div>
        </>
      )}

      <PhotoDialog
        dialogOpen={photoDialogOpen}
        setDialogOpen={setPhotoDialogOpen}
        reloadPhotos={props.reloadPhotos}
        setReloadPhotos={props.setReloadPhotos}
        opportunity={props.opportunity}
        feature={props.feature}
        roofapi={props.roofapi}
      />

      <EditMobile
        setMarkerDescription={setMarkerDescription}
        setMarkerRoofType={setMarkerRoofType}
        setMarkerRemainingYear={setMarkerRemainingYear}
        setMarkerEstReplacement={setMarkerEstReplacement}
        setMarkerEstRepair={setMarkerEstRepair}
        setMarkerCostOfRepair={setMarkerCostOfRepair}
        setTextBoxFocus={props.setTextBoxFocus}
        description={description}
        roofType={roofType}
        yearType={yearType}
        replacement={replacement}
        repair={repair}
        repairCost={repairCost}
        descriptionHeight={descriptionHeight}
        photos={props.photos}
        setPhotos={props.setPhotos}
        loadingPhotos={props.loadingPhotos}
        setLoadingPhotos={props.setLoadingPhotos}
        reloadPhotos={props.reloadPhotos}
        setReloadPhotos={props.setReloadPhotos}
        opportunity={props.opportunity}
        dialogOpen={editMobileOpen}
        setDialogOpen={setEditMobileOpen}
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        selectedPhoto={selectedPhoto}
        setSelectedPhoto={setSelectedPhoto}
        selectedFeature={props.feature}
        setPhotoDialogOpen={setPhotoDialogOpen}
      />

      {/*<div style={{textAlign: 'center'}}><Button onClick={e => {handleClose()}} style={{padding: '0px', lineHeight: '1.5'}} color="secondary">Done</Button></div>*/}
    </div>
  )

  if (props.open) {
    return sidebar
  } else {
    return null
  }
}

export default Edit
