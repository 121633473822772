import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';

export default function CompleteJobDialog(props) {
  const {open, setOpen} = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCompleteJob = () => {
    props.completeJob();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Complete Job</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{width:"100%"}}>
              {!props.completingJob && <>
                Do not forget to add your field notes and materials.  Also note that this prompt will not log you out.
                <span style={{fontWeight: 'bold'}}>You still need to clock out of the time card app</span>
              </>}
              {props.completingJob && <div style={{width: '100%', textAlign: 'center'}}><CircularProgress /></div>}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCompleteJob} color="primary" autoFocus>
            Complete Job
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}