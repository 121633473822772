import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Card, TextField, Button } from '@material-ui/core';
import theme from '../../themes/theme';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'scroll',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export default function ImageModal(props)
{
    const classes = useStyles();
    const [editing, setEditing] = useState(false);
    const [description, setDescription] = useState("");
    

    useEffect(() => {
      if(props.image != null)
      {
        setDescription(props.image.fieldData.Description);
      }
    }, [props.image])

  const handleClose = () => {
    props.changeOpen(false);
  };

    return (
      <>
      {props.image != null &&
        <Modal
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        >
          
            <Card style={{width: '80%', height: '80%', margin: theme.spacing(2), display: 'flex', textAlign: 'center', flexDirection:'column'}}>
                <div style={{width: '100%', height: '100%', backgroundImage: 'url(' + props.image.fieldData.url + ')', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
                </div>
                    {/*<img alt={props.image.Description} style={{}} src={props.image.Picture_oAe} />*/}
                    {!editing && props.editable &&
                    /* This is the modal that allows the photo to be viewed, and its caption editable.*/
                    <>
                      <div style={{margin: theme.spacing(2)}}>
                        <div>{props.image.fieldData.Description}</div>
                        <Button variant="contained" color="primary" onClick={() => {setEditing(true); setDescription(props.image.fieldData.Description)}}>
                          <EditIcon />
                          Edit
                        </Button>
                      </div>
                      
                      </>
                    }
                    {editing && props.editable &&
                    <>
                      <div style={{margin: theme.spacing(2)}}>
                        <TextField style={{width:'100%'}} multiline value={description} onChange={(event) => setDescription(event.target.value)} ></TextField>
                        <Button variant="contained" color="primary" onClick={() => setEditing(false)}>
                          <CloseIcon />
                          Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => {setEditing(false); props.changeDescription(description)}}>
                          <DoneIcon />
                          Save
                        </Button>
                      </div>
                      
                      </>
                    }
                    {!props.editable &&
                    <>
                      <div style={{marginBottom: '25px'}}>{props.description}</div>
                    </>}
                    
                

            </Card>

        </Modal>
      }
      </>
    );
}