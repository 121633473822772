import React, {useState, useEffect} from "react";
import { useAuth0 } from "../../../react-auth0-wrapper";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './reportSelect.module.css';
import { config } from "../../../config";


const ReportSelect = (props) => {
    const { getTokenSilently } = useAuth0();
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //Every time selected job changes, reload the reports for this job
        if(props.action === 'modify') {
            props.setDisplayMessage('');
            loadReports(props.selectedJob.__ID);
        }

        //set the selected report to null
        props.setSelectedReport(null);
    }, [props.selectedJob]);


    //Formats data from FileMaker to include the Record ID and Field Data in a JavaScript Array
    const formatList = (data) => {
        let list = data.map(item => {
            item.fieldData.recordId = item.recordId;
            return item.fieldData;
        });
        return list;
    }

    const fetchCommand = async (url) => {
        const token = await getTokenSilently();
        const headers = { headers: { Authorization: 'Bearer ' + token } };
        const result = await fetch(url, headers);
        const obj = await result.json();
        return obj;
    }

    const loadReports = async (selectedJobId) => {
        try {
            setLoading(true);
            const path = config.API_ENDPOINT + '/api/dailyReport/reports/' + selectedJobId;
            const result = await fetchCommand(path);

            //if data successfully fetched
            if(result.message) {
                let list = formatList(result.data);
                setReports(list);
                setLoading(false);
            } else {
                props.setDisplayMessage('No Reports Found for this Job. To create a new daily report for this job, click Next.');
                setReports([]);
                setLoading(false);
            }
        } catch(error) {
            console.error(error);
        }
    }



    const handleSelect = (event) => {
        let report = reports.find(report => report.__ID === event.target.value);

        if(typeof report === 'undefined') {
            report = null;
        }
        props.setSelectedReport(report);
    }

    return (

         <div className={styles.wrapper}>

                {/* List of Reports */}
                {(!loading && reports.length > 0) && <>
            
                <p className={styles.label_content}>Select a report to modify</p>
                
                <Select
                id="demo-simple-select2"
                labelid="report-select-label"
                value={props.selectedReport ? props.selectedReport.__ID : ''}
                onChange={handleSelect}
                className={styles.input_content}>

                <MenuItem value={''}></MenuItem>
                    {reports.map(report => <MenuItem key={report.recordId} value={report.__ID}>{report.z_Creation_TS}
                </MenuItem>)}
    
                </Select>
            
            </>}

            {/* Loading */}
            {loading && <div>Loading Reports...</div>}
        </div>

    );
}

export default ReportSelect;