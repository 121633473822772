import React, { useState, useEffect } from 'react'
import styles from '../Home/home.module.css'
import { useAuth0 } from '../../react-auth0-wrapper'
import { makeStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Select, MenuItem } from '@material-ui/core'
import { config } from './../../config'

const alertDetailHeader = { textAlign: 'center', color: 'white' }

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  alertFullWidth: {
    width: '100%'
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const listStyles = makeStyles(theme => ({
  root: {
    minWidth: 250,
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`
  },
  listItemColor: {
    color: 'black'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const AlertDetails = props => {
  const list = listStyles()
  const listItems = listStyles()
  const [showResult, setShowResult] = useState(false)
  const [customersResult, setCustomersResult] = useState(false)
  const [customer, setCustomer] = useState('')
  const [jobCreated, setJobCreated] = useState(false)
  const { getTokenSilently } = useAuth0()
  const [customers, setCustomers] = useState('')
  const [apiMessage, setApiMessage] = useState([])
  const [jobId, setJobId] = useState([])
  const [toJob, setToJob] = useState({ set: false, location: '/job' })
  const [loading, setLoading] = useState(false)

  const goToJob = id => {
    console.log('id', id)
    let data = { set: true, to: { pathname: `job/${id}`, state: { __ID: id } } }
    setToJob(data)
  }

  useEffect(() => {
    if (!showResult) {
      const callApi = async () => {
        try {
          const token = await getTokenSilently()

          const response = await fetch(config.API_ENDPOINT + '/api/alertDetails', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json', // sent request
              Accept: 'application/json' // expected data sent back
            },
            body: JSON.stringify({ id: props.location.state.__ID })
          })

          const responseData = await response.json()

          if (responseData.message) {
            setShowResult(true)
            setApiMessage(responseData.data.response.data[0].fieldData)
          } else {
            alert(responseData)
          }
        } catch (error) {
          console.error(error)
          setShowResult(false)
        }
      }
      callApi()
    }
  }, [getTokenSilently, props.location.state.__ID, showResult])

  useEffect(() => {
    if (apiMessage.JobCreated_flag == '1') {
      const callJobApi = async () => {
        try {
          const token = await getTokenSilently()

          const response = await fetch(config.API_ENDPOINT + '/api/getJobId', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json', // sent request
              Accept: 'application/json' // expected data sent back
            },
            body: JSON.stringify({ id: props.location.state.__ID })
          })

          const responseData = await response.json()
          if (responseData.message) {
            setJobId(responseData.data)
          } else {
            //alert(responseData);
            setJobId(null)
          }
        } catch (error) {
          console.error(error)
        }
      }
      callJobApi()
    }
  }, [apiMessage, getTokenSilently, props.location.state.__ID])

  useEffect(() => {
    if (!customersResult) {
      setCustomersResult(true)
      const callApi = async () => {
        try {
          const token = await getTokenSilently()

          const response = await fetch(config.API_ENDPOINT + '/api/getCustomers', {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json', // sent request
              Accept: 'application/json' // expected data sent back
            }
          })

          const responseData = await response.json()
          if (responseData.message) {
            setCustomers(responseData.data.response.data)
          } else {
            alert(responseData)
          }
        } catch (error) {
          console.error(error)
          setCustomersResult(false)
        }
      }
      callApi()
    }
  }, [customersResult, getTokenSilently])

  const createJob = async () => {
    setJobCreated(true)
    try {
      const token = await getTokenSilently()

      const response = await fetch(config.API_ENDPOINT + '/api/createJob', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // sent request
          Accept: 'application/json' // expected data sent back
        },
        body: JSON.stringify({
          data: { alertId: apiMessage.__ID, customer: customer }
        })
      })

      const responseData = await response.json()
      //setJobCreated(false);
      //setShowResult(false);
      //setLoading(false);
      //setApiMessage({});
      if (responseData.message) {
        let data = {
          set: true,
          to: { pathname: '/job', state: { __ID: responseData.data } }
        }
        setToJob(data)
      }
      /*

    */
    } catch (error) {
      console.error(error)
      setShowResult(false)
    }
  }

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  function handleClickOpen () {
    setOpen(true)
  }

  function handleClose (type) {
    setOpen(false)

    if (type) {
      setLoading(true)
      if (customer != '') {
        createJob()
        setShowResult(false)
      }
    }
  }

  return (
    <div>
      {toJob.set && <Redirect push to={toJob.to} />}
      <div className={styles.Body}>
        <h2 style={alertDetailHeader}>Alert Details</h2>

        {!open && showResult && !loading && (
          <List className={list.root}>
            <ListItem>
              <ListItemText
                className={list.listItemColor}
                primary='Company Name'
                secondary={apiMessage.CompanyName}
              />
            </ListItem>
            <Divider component='li' />
            <ListItem>
              <ListItemText
                className={list.listItemColor}
                primary='Description of Problem'
                secondary={apiMessage.ProblemDescription}
              />
            </ListItem>
            <Divider component='li' />
            <ListItem>
              <ListItemText
                className={list.listItemColor}
                primary='Address'
                secondary={
                  (apiMessage.JobAddressLine1 != '' ||
                    apiMessage.JobAddressCity != '' ||
                    apiMessage.JobAddressState != '' ||
                    apiMessage.JobAddressZip != '') && (
                    <>
                      <span>
                        {apiMessage.JobAddressLine1}
                        <br></br>
                        {apiMessage.JobAddressLine2 != '' && (
                          <>
                            {apiMessage.JobAddressLine2}
                            <br></br>
                          </>
                        )}
                        {apiMessage.JobAddressCity} {apiMessage.JobAddressState}
                        , {apiMessage.JobAddressZip}
                      </span>
                    </>
                  )
                }
              />
            </ListItem>

            <Divider component='li' />

            <ListItem>
              <ListItemText
                className={list.listItemColor}
                primary='Jobsite Building'
                secondary={apiMessage.JobBuilding}
              />
            </ListItem>
            <Divider component='li' />
            <ListItem>
              <ListItemText
                className={list.listItemColor}
                primary='Company Email'
                secondary={apiMessage.CompanyEmail}
              />
            </ListItem>
            <Divider component='li' />
            <ListItem>
              <ListItemText
                className={list.listItemColor}
                primary='Company Phone'
                secondary={apiMessage.CompanyPhone}
              />
            </ListItem>
            <Divider component='li' />
            <ListItem>
              <ListItemText
                className={list.listItemColor}
                primary='Contact Person Name'
                secondary={
                  apiMessage.ContactNameFirst + ' ' + apiMessage.ContactNameLast
                }
              />
            </ListItem>
            <Divider component='li' />
            <ListItem>
              <ListItemText
                className={list.listItemColor}
                primary='Contact Phone'
                secondary={apiMessage.ContactPhone}
              />
            </ListItem>
            <Divider component='li' />

            {apiMessage.JobCreated_flag == '' && (
              <ListItem className={classes.alertContainer}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleClickOpen}
                >
                  Create Job for Alert
                </Button>
              </ListItem>
            )}

            {apiMessage.JobCreated_flag == '1' && jobId != null && (
              <ListItem className={classes.alertContainer}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    goToJob(jobId)
                  }}
                >
                  View Job
                </Button>
              </ListItem>
            )}
          </List>
        )}

        {/*<span>{props.location.state.__ID}</span>*/}
        {showResult && (
          <>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={() => {
                      handleClose(false)
                    }}
                    aria-label='close'
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant='h6' className={classes.title}>
                    Create Job
                  </Typography>
                  <Button
                    color='inherit'
                    onClick={() => {
                      handleClose(true)
                    }}
                  >
                    Save
                  </Button>
                </Toolbar>
              </AppBar>
              <List>
                <ListItem>
                  {customers != '' && (
                    <Select
                      value={customer}
                      onChange={event => {
                        setCustomer(event.target.value)
                      }}
                      className={classes.alertFullWidth}
                    >
                      {customers.map(customer => (
                        <MenuItem
                          key={customer.fieldData.__ID}
                          value={customer.fieldData.__ID}
                        >
                          {customer.fieldData.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {/*<SelectCustomer choice={setCustomer} customers={customers} />*/}
                </ListItem>
                <Divider />
                {/*<ListItem>
                  <ListItemText primary="Default notification ringtone" secondary="Tethys" />
                </ListItem>*/}
              </List>
            </Dialog>
          </>
        )}
        {!showResult && <CircularProgress className={classes.progress} />}
      </div>
    </div>
  )
}
export default AlertDetails
