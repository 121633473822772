import React, {useState} from "react";
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Search from './Search';
import Name from './Name';
import GeneratePDF  from './GeneratePDF';
import SaveBtn from './SaveBtn';
import Finalized from './FinalizeInspection';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styles from './individual.module.css';


// const useStyles = makeStyles(theme => ({
    
// }));

const Header = (props) => {
    
    const [goBack, setGoBack] = useState(false);
    // const classes = useStyles();
    let element = '';

    if(goBack) {
        return <Redirect push to='/opportunities' />;
    }
    
    if(props.isTabletOrMobile) {
        element = (
            <Paper>
                <Grid container spacing={0} className={styles.header_wrapper}>
                    <Grid item xs={12}>
                        <div className={styles.header_center}>

                        <div className={styles.header_title}> {props.opportunity.name.length > 35 ? props.opportunity.name.substring(0,35) + '...' : props.opportunity.name} 
                        </div>
                        
                        {/*props.opportunity.address*/}
                        <Button variant="contained" color="primary" onClick={e => {setGoBack(true)}}> 
                            <ArrowBackIcon className={styles.header_backIcon}/>Back 
                        </Button>
                        <SaveBtn setMessage={props.setMessage} opportunity={props.opportunity} map={props.map} markers={props.markers} isTabletOrMobile={props.isTabletOrMobile} />
                        <GeneratePDF setMessage={props.setMessage} opportunity={props.opportunity} map={props.map} markers={props.markers} snapshotSize={props.snapshotSize} isTabletOrMobile={props.isTabletOrMobile} photos={props.photos} />
                        <Finalized setMessage={props.setMessage} opportunity={props.opportunity} map={props.map} markers={props.markers} snapshotSize={props.snapshotSize} isTabletOrMobile={props.isTabletOrMobile} photos={props.photos} />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        );
    } else {
        element = (
            <Paper>
                <Grid container spacing={0} className={styles.header_wrapper}>
                    <Grid item xs={3} md={2} lg={3}>
                        <Name name={props.opportunity.name} />
                    </Grid>
                    <Grid item xs={5} md={4} lg={4}>
                        <Search address={props.opportunity.address}/>
                    </Grid>
                    <Grid item xs={4} md={6} lg={5} className={styles.header_wrapper}>
                        
                        <SaveBtn setMessage={props.setMessage} opportunity={props.opportunity} map={props.map} markers={props.markers} isTabletOrMobile={props.isTabletOrMobile} />&nbsp;&nbsp;
                        <GeneratePDF setMessage={props.setMessage} opportunity={props.opportunity} map={props.map} markers={props.markers} snapshotSize={props.snapshotSize} isTabletOrMobile={props.isTabletOrMobile} photos={props.photos} />&nbsp;&nbsp;
                        <Finalized setMessage={props.setMessage} opportunity={props.opportunity} map={props.map} markers={props.markers} snapshotSize={props.snapshotSize} isTabletOrMobile={props.isTabletOrMobile} photos={props.photos} />&nbsp;&nbsp;
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    return element;
} 

export default Header;