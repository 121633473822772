import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { TextField } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import CircularProgress from '@material-ui/core/CircularProgress'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'
import {
  generateSnapshot,
  generateSnapshotList,
  generatePDF,
  generateMarkerPhotos
} from './ImageFunctions'
import { useAuth0 } from '../../../react-auth0-wrapper'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { StylesContext } from '@material-ui/styles'
import lists from './individual.module.css'
import { config } from '../../../config'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  formControl: {
    width: '100%'
  },
  opportunities_Icon: {
    fontSize: '21px',
    marginLeft: '4px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '2px'
    }
  },

  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  paper: {
    minWidth: '35%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '55%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '90%',
      margin: '0 auto'
    }
  },

  action: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0',
    padding: '0',
    borderBottom: '1px solid #acacac',
    marginBottom: '18px'
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '10px',
    color: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      right: '11px',
      top: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      right: '11px',
      top: '4px'
    }
  },

  generateAction: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 24px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: '18px 24px'
    }
  },

  cancel: {
    width: '50%',
    color: '#fff',
    background: '#535b69',
    '&:hover': {
      background: '#494f5a'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '2%',
      marginLeft: '0'
    }
  },

  generate: {
    width: '50%',
    color: '#fff',
    background: '#24a0ed',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '8px',
      marginTop: '0'
    },

    '&:hover': {
      background: '#258fd2'
    }
  },

  dialogContentSpacing: {
    padding: '8px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 24px'
    }
  },

  finalize_button: {
    marginTop: '0',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4px'
    }
  }
}))

const GeneratePDF = props => {
  const [loading, setLoading] = useState(false)
  const [selectedName, setSelectedName] = useState('Roof Inspection Report')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { getTokenSilently } = useAuth0()
  const classes = useStyles()

  const fetchCommand = async (url, data) => {
    const token = await getTokenSilently()
    const request = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json'
      },
      body: JSON.stringify(data)
    }
    const result = await fetch(url, request)
    const obj = await result.json()
    return obj
  }

  const savePDF = async (opp, pdf) => {
    //Format all the data
    let geoJson = await getGeoJson(props.map)

    let obj = {
      mapData: geoJson,
      markers: props.markers,
      center: props.map.getCenter()
    }

    //let map = JSON.stringify(obj);

    try {
      const path =
        config.API_ENDPOINT + '/api/opportunities/pdf/' + props.opportunity.id
      const result = await fetchCommand(path, { opp: opp, pdf: pdf, map: obj })
      console.log(path, 'path', result, 'result', obj, 'props')
      localStorage.setItem('pdf_url', JSON.stringify(result.data))
      return result.message
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const getGeoJson = map => {
    let promise = new Promise((resolve, reject) => {
      map.data.toGeoJson(object => {
        resolve(object)
      })
    })

    return promise
  }

  const handleClick = () => {
    setIsDialogOpen(true)
  }

  const handleCloseClick = () => {
    setIsDialogOpen(false)
  }

  const handleSaveClick = async () => {
    setIsDialogOpen(false)

    setLoading(true)
    props.setMessage('Generating PDF...')

    //get the list of snapshots
    let snapshot = generateSnapshot(
      props.map,
      props.markers,
      props.snapshotSize
    )
    let list = await generateSnapshotList(
      props.map,
      props.markers,
      props.snapshotSize,
      props.opportunity
    )
    let markerPhotos = generateMarkerPhotos(props.map, props.photos)

    //Combine photos, markers, and roof sections
    let combinedList = list.concat(markerPhotos)

    //sort markers and photos
    combinedList.sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      } else {
        return 0
      }
    })

    //add title
    props.opportunity.reportTitle = selectedName
    console.log(props.opportunity,);

    //generate a pdf of them
    let pdf = await generatePDF(props.opportunity, snapshot, combinedList)

    props.setMessage('Uploading PDF to FileMaker...')

    //Save PDF to server
    let result = await savePDF(props.opportunity, pdf)
    console.log(result)

    //Display result to user
    if (result) {
      props.setMessage('PDF saved to FileMaker.')
    } else {
      props.setMessage('Error uploading PDF. Please try again.')
    }

    //Hide result after 5 seconds
    setTimeout(() => {
      props.setMessage(null)
    }, 5000)

    setLoading(false)
  }

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        onClick={handleClick}
        className={classes.finalize_button}
      >
        {/* {props.isTablet ? 'PDF' : 'Generate PDF'} */}
        {props.isTabletOrMobile ? 'PDF' : 'Generate PDF'}
        {/* Generate PDF */}
        {!loading && (
          <PictureAsPdfIcon className={classes.opportunities_Icon} />
        )}
        {loading && (
          <CircularProgress
            size={20}
            thickness={4}
            style={{ color: 'white' }}
          />
        )}
      </Button>

      {/* <Button variant="contained" color="primary" onClick={handleClick} className={classes.button_mobile}> */}
      {/* {props.isTablet ? 'PDF' : 'Generate PDF'} */}
      {/* {props.isTabletOrMobile ? 'PDF' : 'Generate PDF'} */}
      {/* PDF */}
      {/* {!loading && <PictureAsPdfIcon />} */}
      {/* {loading && <CircularProgress size={24} thickness={4} style={{color: 'white'}}/>} */}
      {/* </Button> */}

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseClick}
        aria-labelledby='max-width-dialog-title'
        classes={{ paper: classes.paper }}
      >
        <DialogActions className={classes.action}>
          <DialogTitle
            id='max-width-dialog-title'
            className={lists.generate_title}
          >
            Enter Report Name
          </DialogTitle>

          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleCloseClick}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>

        {/* <DialogTitle id="max-width-dialog-title">Enter Report Name</DialogTitle> */}

        <DialogContent className={classes.dialogContentSpacing}>
          {/* <form className={classes.form} noValidate> */}
          <FormControl className={classes.formControl}>
            <TextField
              value={selectedName}
              label={'Name'}
              onChange={event => {
                setSelectedName(event.target.value)
              }}
            />
          </FormControl>
          {/* </form> */}
        </DialogContent>

        <DialogActions className={classes.generateAction}>
          <Button
            className={classes.cancel}
            onClick={handleCloseClick}
            variant='contained'
            color='primary'
          >
            Cancel
          </Button>
          <Button
            className={classes.generate}
            color='secondary'
            variant='contained'
            onClick={handleSaveClick}
          >
            Generate PDF
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GeneratePDF
