import React, { Component, useState, useEffect } from 'react';
import { useAuth0 } from "../../../react-auth0-wrapper";
import { Paper, Button, TextField, MenuItem, OutlinedInput, Select, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Camera from 'react-html5-camera-photo';
import ReplayIcon from '@material-ui/icons/Replay';
import theme from '../../../themes/theme';
import InvoiceMaterialUnitsDropdown from "../../Invoice/editInvoice/InvoiceMaterialUnitDropdown";
import { config } from "../../../config";

const useStyles = makeStyles(theme => ({
    progress: {
        width: '100%',
    }
  }));

export default function UploadCamera (props) {
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [mirror, setMirror] = useState(false);
    const [idealFacingMode, setIdealFacingMode] = useState('environment');
    const [showImages, setShowImages] = useState(0);
    const [caption, setCaption] = useState('');
    const { getTokenSilently } = useAuth0();
    const [uploading, setUploading] = useState(false);
    const [newImages, setNewImages] = useState(0);
    const [values, setValues] = useState({
      beforeAfter: 'Before',
      id:''
    });
    const [captionList, setCaptionList] = useState([]);

    useEffect(() => {
      getCaptions();
    }, [getTokenSilently]);

    /**
    * Gets a list of pre-defined image captions from FileMaker, and populates the auto-complete drop down
    */
    const getCaptions = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(config.API_ENDPOINT + "/api/getCaptionList", {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
        });
        const responseData = await response.json();

        if(responseData.message)
        {
          setCaptionList(responseData.data.response.data);
        }
        else
        {
          //alert(responseData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const onTakePhoto = (dataUri) => {
        let currentImages = images;
        currentImages.unshift({data: dataUri, description: ''});
        setImages(currentImages);
        let number = showImages;
        setShowImages(number + 1);
    };

    function handleChange(event) {
      setValues(oldValues => ({
        ...oldValues,
        [event.target.name]: event.target.value,
      }));
    }


    const removeImage = (i) => {
      let currentImages = images;
      currentImages.splice(i, 1);
      setImages(currentImages);
      let number = showImages;
      setShowImages(number - 1);
      
    };


    const uploadImages = () => {
      setUploading(true);
      images.forEach((image, i) => {
        var formData = new FormData();
        formData.append('upload', images[i].data);
        formData.append('_idf_job', props.selectedJob.__ID);
        formData.append('Description', caption);
        formData.append('BeforeAfter', values.beforeAfter);
        formData.append('source', 'camera');
        uploadImage(formData, 0);
      })
    };

    const uploadImage = async (form, i) => {
      try {
        const token = await getTokenSilently();
    
        const response = await fetch(config.API_ENDPOINT + "/api/dailyReport/"+ props.selectedReport.__ID +"/photos", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json'   // expected data sent back
          },
          body: form
        });
    
        const responseData = await response.json();

        if(responseData.data.message || i > 9) {
          if(i > 9) {
            if(window.confirm("Image failed to upload! Would you like to try resending it?")) {
              uploadImage(form, 0);
            }
          } else {
            setNewImages(newImages + 1);
            setImages([]);
            setCaption('');
            
            //Close the modal
            props.setDialogOpen(false);

            //Re-load images from FileMaker
            props.setReloadPhotos(true);
          }
          setUploading(false);
          
        } else {
          uploadImage(form, i + 1)
        }
        
      } catch (error) {
        console.error(error);
        setUploading(false);
      }
    };

    const btnStyle = {
      color : 'white',
      fontWeight : 'bold',
      backgroundColor: '#24a0ed',
      width: '100%',
      marginTop : '10px'
    }


    return (
        <div style={{width: '100%', display: 'flex', justifyContent:'center', 'flexDirection': 'column', textAlign: 'center'}}>


          {uploading && <div style={{height : '75px'}}><CircularProgress className={classes.progress} /></div>}

          {!uploading && <>
          <Paper style={{ margin: theme.spacing(2)}}>

            {images.length == 0 && <div style={{position: 'relative'}}>
            <Camera
                imageType="jpg"
                idealFacingMode = {idealFacingMode}
                isImageMirror={mirror}
                onTakePhoto = { (dataUri) => { onTakePhoto(dataUri); } }
            />
            <Button onClick={(event) => {if(idealFacingMode == 'environment') {setIdealFacingMode('user'); setMirror(true);} else { setIdealFacingMode('environment'); setMirror(false); }}} variant="contained" style={{position: 'absolute', right: '0', top: '0'}}><ReplayIcon /></Button>
            </div>}
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            {images.length > 0 && images.map((row, i) => (
                <div key={i} style={{position: 'relative'}}>
                  <img style={{width: '50%'}} src={row.data}></img>
                  <Button onClick={(event) => {removeImage(i); setCaption('')}} variant="contained" style={{backgroundColor:"#FF0000", position: 'absolute', right: '0', top: '0'}}>X</Button>
                </div>
            ))}
            {images.length > 0 &&
            <div style={{ width:'100%'}}>
            <Select
                onChange={handleChange}
                value={values.beforeAfter}
                style={{width: '100%', marginBottom : '10px'}}
                input={<OutlinedInput name="beforeAfter" id="beforeAfter" />}
              >
                <MenuItem value='Before'>Before</MenuItem>
                <MenuItem value='After'>After</MenuItem>
              </Select>
              {/*<InvoiceMaterialUnitsDropdown
                changeValue={setCaption}
                value={caption}
                changeChoice={() => {}}
                materials={values.beforeAfter  == "Before" ? [...new Set(captionList.filter(caption => caption.fieldData.flag_before == "1").map(material => { return material.fieldData.caption }))] : [...new Set(captionList.filter(caption => caption.fieldData.flag_before != "1").map(material => { return material.fieldData.caption }))]}
                style={{width:'100%'}}
                label="Caption"
                placeholder="Caption"
              />*/}
              <TextField 
                onChange={e => setCaption(e.target.value)} 
                value={caption}
                style={{width:'100%'}}
                label="Caption"
                placeholder="Caption"
              />
              
              <Button variant="contained" style={btnStyle} color="primary" onClick={() => {uploadImages()}}>Upload Image</Button>
            </div>
            }
            </div>

          </Paper>
          </>
          }

          </div>
    );
}