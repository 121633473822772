import React, { useState, useEffect } from 'react'
import { useAuth0 } from '../../react-auth0-wrapper'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {
  CircularProgress,
  TextField,
  ListItemText,
  Typography
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import lists from './timecard.module.css'
import { config } from '../../config'

const styles = theme => ({
  root: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: '14px',
    top: '10px',
    color: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      right: '11px',
      top: '4px'
    }
  }
})

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: '10px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 24px'
    }
  },
  formControl: {
    marginTop: '20px',
    minWidth: 120,
    color: '#000'
  },
  formControlLabel: {
    color: '#000'
  },

  paper: {
    minWidth: '60%',
    maxHeight: 'calc(100% - 120px)',
    [theme.breakpoints.down('sm')]: {
      minWidth: '92%'
    }
  },

  timecard_primaryButton: {
    backgroundColor: '#24a0ed',
    color: '#fff',
    width: '100%',
    margin: '12px 0 0',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#258fd2'
    }
  },

  timecard_primaryButtonPopup: {
    backgroundColor: '#24a0ed',
    color: '#fff',
    width: '100%',
    margin: '12px 0 0',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#258fd2'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0 0'
    }
  },

  timecard_secondaryButton: {
    backgroundColor: '#535b69',
    color: '#fff',
    width: '100%',
    marginTop: '25px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#494f5a'
    }
  },

  timecard_secondaryButtonPopup: {
    backgroundColor: '#535b69',
    color: '#fff',
    width: '100%',
    marginTop: '16px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#494f5a'
    }
  },

  timecard_thirdButton: {
    backgroundColor: '#d3d3d3',
    width: '100%',
    marginTop: '15px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#494f5a',
      color: '#fff'
    }
  },

  timecard_dialogContent: {
    margin: '0',
    padding: '0',
    paddingBottom: '10px',
    borderTop: '1px solid #acacac'
  },

  timecard_label: {
    marginTop: '20px',
    color: '#000',
    fontWeight: '600',
    display: 'block',
    marginBottom: '0',
    fontSize: '14px'
  },

  timecard_radio: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0',
    padding: '0'
  },

  timecard_textField: {
    marginBottom: '16px'
  }
}))

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

function Details (props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState('md')
  const [workType, setWorkType] = useState('')
  const [jobType, setJobType] = useState('')
  const [jobs, setJobs] = useState([])
  const [jobsLoaded, setJobsLoaded] = useState(false)
  const { getTokenSilently } = useAuth0()
  const [changeOrderTM, setChangeOrderTM] = useState('')
  const [changeOrder, setChangeOrder] = useState('')
  const [changeOrders, setChangeOrders] = useState([])
  const [customTask, setCustomTask] = useState('')
  const [loadingCO, setLoadingCO] = useState(false)
  const [selectedJob, setSelectedJob] = useState(null)
  const [timecardData, setTimecardData] = useState(props.timeCardData)

  function compare (a, b) {
    if (a.fieldData.Job_Name < b.fieldData.Job_Name) {
      return -1
    }
    if (a.fieldData.Job_Name > b.fieldData.Job_Name) {
      return 1
    }
    return 0
  }

  useEffect(() => {
    if (!jobsLoaded) {
      const getJobs = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(
            config.API_ENDPOINT + '/api/getJobsListActive',
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // sent request
                Accept: 'application/json' // expected data sent back
              },
              body: null
            }
          )

          const responseData = await response.json()
          if (responseData.message) {
            const newJobs = responseData.data.response.data.sort(compare)
            setJobs(newJobs)
            setJobsLoaded(true)

            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          } else {
            setJobsLoaded(true)
            //alert(responseData);
          }
        } catch (error) {
          console.error(error)
        }
      }
      getJobs()
    }
  }, [getTokenSilently, jobsLoaded])

  useEffect(() => {
    if (jobType != '') {
      jobs.forEach(job => {
        if (jobType === job.fieldData.__ID) {
          setSelectedJob(job.fieldData)
        }
      })

      setLoadingCO(true)
      const getJobs = async () => {
        try {
          const token = await getTokenSilently()
          const response = await fetch(
            config.API_ENDPOINT + '/api/getChangeOrders',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // sent request
                Accept: 'application/json' // expected data sent back
              },
              body: JSON.stringify({ id: jobType })
            }
          )

          const responseData = await response.json()

          if (responseData.message) {
            setChangeOrders(responseData.data.response.data)
          } else {
          }

          setLoadingCO(false)
        } catch (error) {
          console.error(error)
        }
      }
      getJobs()
    }
  }, [getTokenSilently, jobType])

  const handleClickOpen = () => {
    setOpen(true)
    console.log(props.selectedPhoto, 'selected photo')
    props.timecardData.fieldData.workType = 'TEST'
    console.log(props.timecardData.fieldData, 'timecard data aasdfasdfasdf')
  }

  const handleClose = () => {
    props.setSelectedPhoto(null)
    props.setReceiptPhoto(null)
    setOpen(false)
  }

  const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value)
  }

  const handleFullWidthChange = event => {
    setFullWidth(event.target.checked)
  }

  const onImageSelect = event => {
    //Verify image file
    if (event.target.files.length > 0) {
      props.setSelectedPhoto(event.target.files[0])
      console.log(event.target.files[0], 'set roof photo')
    } else {
      props.setSelectedPhoto(null)
    }
  }

  const onReceiptSelect = event => {
    //Verify image file
    if (event.target.files.length > 0) {
      props.setReceiptPhoto(event.target.files[0])
      console.log(event.target.files[0], 'set receipt photo')
    } else {
      props.setReceiptPhoto(null)
    }
  }

  const saveClose = () => {
    if (!workType) {
      alert('You must select a work type before clocking in.')
      return
    }

    /* /* HPDS Rennel Parino 10/25/2023 - Temporary deprecation per client request
    if (!props.selectedPhoto) {
      alert('You must add a photo of yourself on the roof before clocking in.')
      return
    }
    */

    let data = {}
    data._idf_job = jobType
    data._idf_changeOrder = changeOrder
    data.WorkType = workType
    data.flag_ChangeOrder = changeOrderTM
    data.SystemTask_Lu = customTask
    data.jobPhoto = props.selectedPhoto
    data.receiptPhoto = props.receiptPhoto

    setOpen(false)
    // console.log('save close data', data)
    props.createTimecard(data)
    props.changeStatus()
  }

  return (
    <div className={lists.timecard_ContentWrapper}>
      <>
        <h2 className={lists.timecard_ContentHeading}>Clock In</h2>

        {!props.timecardData && (
          <Typography gutterBottom>
            <span className={lists.timecard_subHeading}>
              Select a job to begin tracking time
            </span>
          </Typography>
        )}

        {/* <div>
          <input
            accept='image/*'
            style={{ display: 'none' }}
            id='raised-button-file'
            multiple
            type='file'
            onChange={onImageSelect}
          />
          <label htmlFor='raised-button-file'>
            <Button
              variant='contained'
              component='span'
              className={classes.timecard_secondaryButton}
            >
              Add Photo On Roof{' '}
              {props.selectedPhoto ? <span>&nbsp;&#10003;</span> : ''}
            </Button>
          </label>
        </div>
        <div>
          <input
            accept='image/*'
            style={{ display: 'none' }}
            id='raised-button-file_2'
            multiple
            type='file'
            onChange={onReceiptSelect}
          />
          <label htmlFor='raised-button-file_2'>
            <Button
              variant='contained'
              component='span'
              className={classes.timecard_thirdButton}
            >
              Add Photo of Job Receipt{' '}
              {props.receiptPhoto ? <span>&nbsp;&#10003;</span> : ''}
            </Button>
          </label>
        </div> */}

        <Button
          variant='contained'
          className={classes.timecard_primaryButton}
          onClick={handleClickOpen}
        >
          {props.timecardData && <>Change Task</>}
          {!props.timecardData && <>Select Job</>}
        </Button>

        {/* open model box on click of select job button */}
        <Dialog
          // fullScreen
          open={open}
          onClose={handleClose}
          aria-labelledby='max-width-dialog-title'
          classes={{ paper: classes.paper }}
        >
          {jobsLoaded && (
            <>
              <DialogTitle
                id='customized-dialog-title'
                onClose={handleClose}
                className={lists.timecard_dialogTitle}
              >
                What will you be working on today?
              </DialogTitle>

              <DialogContent className={classes.timecard_dialogContent}>
                <form className={classes.form} noValidate>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor='workType'>Select work type</InputLabel>

                    <Select
                      value={workType}
                      onChange={event => {
                        setWorkType(event.target.value)
                      }}
                      inputProps={{
                        name: 'workType',
                        id: 'workType'
                      }}
                    >
                      <MenuItem
                        value='Paver Installation'
                        style={{ fontSize: '15px' }}
                      >
                        Paver Installation
                      </MenuItem>
                      <MenuItem value='RFG Field' style={{ fontSize: '15px' }}>
                        RFG Field
                      </MenuItem>
                      <MenuItem
                        value='RFG Delivery'
                        style={{ fontSize: '15px' }}
                      >
                        RFG Delivery
                      </MenuItem>
                      <MenuItem value='RFG PM' style={{ fontSize: '15px' }}>
                        RFG PM
                      </MenuItem>
                      <MenuItem
                        value='RFG Supervisor'
                        style={{ fontSize: '15px' }}
                      >
                        RFG Supervisor
                      </MenuItem>
                      <MenuItem value='SM Field' style={{ fontSize: '15px' }}>
                        SM Field
                      </MenuItem>
                      <MenuItem value='SM Shop' style={{ fontSize: '15px' }}>
                        SM Shop
                      </MenuItem>
                      <MenuItem
                        value='SM Delivery'
                        style={{ fontSize: '15px' }}
                      >
                        SM Delivery
                      </MenuItem>
                      <MenuItem value='SM PM' style={{ fontSize: '15px' }}>
                        SM PM
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor='jobType'>Select a job</InputLabel>

                    <Select
                      value={jobType}
                      onChange={event => {
                        setChangeOrders([])
                        setChangeOrder('')
                        setCustomTask('')
                        setChangeOrderTM('')
                        setJobType(event.target.value)
                      }}
                      inputProps={{
                        name: 'jobType',
                        id: 'jobType'
                      }}
                    >
                      {jobs.map(job => {
                        return (
                          <MenuItem
                            key={job.fieldData.__ID}
                            value={job.fieldData.__ID}
                          >
                            <ListItemText
                              primary={job.fieldData.Job_Name}
                              secondary={job.fieldData.Customer_lu}
                            />
                          </MenuItem>
                        )
                      })}
                    </Select>

                    {selectedJob &&
                    selectedJob.address_Combined_c !== '' &&
                    selectedJob.address_Combined_c.replace(/\s+/g, '') !==
                      ',' ? (
                      <>
                        <div className={classes.timecard_label}>
                          Address:
                          <p className={lists.timecard_link}>
                            <a
                              href={
                                'https://maps.google.com/?q=' +
                                selectedJob.address_Combined_c
                              }
                              target='_blank' rel="noreferrer"
                            >
                              {selectedJob.address_Combined_c}
                            </a>
                          </p>
                        </div>
                      </>
                    ) : (
                      ''
                    )}

                    {selectedJob && selectedJob.FixItStatus === 'Invoiced' ? (
                      <>
                        <div style={{ color: 'red' }}>
                          WARNING: This job has already been invoiced.
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </FormControl>

                  {jobType !== '' && workType !== '' && loadingCO && (
                    <div>
                      <CircularProgress />
                    </div>
                  )}
                  {jobType !== '' && workType !== '' && !loadingCO && (
                    <>
                      {Array.isArray(changeOrders) && changeOrders.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='changeOrder'>
                            Select a Change Order
                          </InputLabel>
                          <Select
                            value={changeOrder}
                            onChange={event => {
                              setChangeOrder(event.target.value)
                            }}
                            inputProps={{
                              name: 'changeOrder',
                              id: 'changeOrder'
                            }}
                          >
                            {changeOrders.map(changeOrder => {
                              return (
                                <MenuItem
                                  key={changeOrder.fieldData.__ID}
                                  value={changeOrder.fieldData.__ID}
                                >
                                  {changeOrder.fieldData.ChangeOrderNumber_Ae +
                                    ' ' +
                                    changeOrder.fieldData.EstimateName}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      )}

                      <FormControl component='fieldset'>
                        <p className={classes.timecard_label}>
                          Change Order T&M
                        </p>

                        <RadioGroup
                          className={classes.timecard_radio}
                          aria-label='changeOrder'
                          name='changeOrder'
                          value={changeOrderTM}
                          onChange={event => {
                            setChangeOrderTM(event.target.value)
                          }}
                        >
                          <FormControlLabel
                            value='1'
                            control={<Radio />}
                            label='Yes'
                          />
                          <FormControlLabel
                            value=''
                            control={<Radio />}
                            label='No'
                          />
                        </RadioGroup>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <TextField
                          className={classes.timecard_textField}
                          value={customTask}
                          label='Enter Custom Task'
                          onChange={event => {
                            setCustomTask(event.target.value)
                          }}
                        ></TextField>
                      </FormControl>

                      <FormControl>
                        <div>
                          <input
                            accept='image/*'
                            style={{ display: 'none' }}
                            id='raised-button-file'
                            multiple
                            type='file'
                            onChange={onImageSelect}
                          />
                          <label htmlFor='raised-button-file'>
                            <Button
                              variant='contained'
                              component='span'
                              className={classes.timecard_secondaryButtonPopup}
                              style={{ display: 'none' }}
                            >
                              Add Photo On Roof{' '}
                              {props.selectedPhoto ? (
                                <span>&nbsp;&#10003;</span>
                              ) : (
                                ''
                              )}
                            </Button>
                          </label>
                        </div>
                      </FormControl>
                      <FormControl>
                        <div>
                          <input
                            accept='image/*'
                            style={{ display: 'none' }}
                            id='raised-button-file_2'
                            multiple
                            type='file'
                            onChange={() => onReceiptSelect()}
                          />
                          <label htmlFor='raised-button-file_2'>
                            <Button
                              variant='contained'
                              component='span'
                              className={classes.timecard_thirdButton}
                            >
                              Add Photo of Job Receipt{' '}
                              {props.receiptPhoto ? (
                                <span>&nbsp;&#10003;</span>
                              ) : (
                                ''
                              )}
                            </Button>
                          </label>
                        </div>
                      </FormControl>
                      <FormControl>
                        <Button
                          variant='contained'
                          component='span'
                          className={classes.timecard_primaryButtonPopup}
                          onClick={saveClose}
                        >
                          Clock In
                        </Button>
                      </FormControl>
                    </>
                  )}
                </form>

                <div>&nbsp;</div>
              </DialogContent>
            </>
          )}

          {!jobsLoaded && (
            <div>
              <CircularProgress />
            </div>
          )}
        </Dialog>
      </>
    </div>
  )
}

export default Details
