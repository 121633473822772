//Generates a PDF from a single snapshot and a list of roof section objects
const generatePDF = async (filename, report, job, photos, username = '') => {
    //generate a pdf document
    var pdf = new window.jsPDF({orientation: 'p', unit: 'pt', format: 'letter', compress : true});
    pdf.canvas.height = 72 * 11; //792
    pdf.canvas.width = 72 * 8.5; //612
    let x = 25;
    let y = 25;
    let imagesPerPage = 4;
    pdf.setFontSize(11);

    //Header
    let logo = await getImageData('/images/logo.png');
    pdf.addImage(logo, 'PNG', x, y, 250, 45, null, 'FAST');
    let companyInfo = [
        '8906 Autumn Oaks Drive',
        'Rockford, MN 55373',
        '',
        'Phone: (763) 316-0660',
        'Email: acct.duraroof@gmail.com',
        'Web: www.duraroofusa.com'
    ];
    let textOptions = {
        align : 'right'
    };
    pdf.text(companyInfo, 587, y, textOptions, 0);
    y += 45 + 28;

    //line break
    pdf.line(x,y,612-25,y);


    y += 16;
    //pdf.text(x, y, 'Daily Report for ' + job.Job_Name + ' (' + job.Number_JobCombined + ')');

    //Three columns of information
    let col1 = [
        'Customer:',
        job.Customer_lu
    ];
    pdf.text(col1, x, y, {}, 0);
    x += 237;

    let address = job.address_Combined_c.split('\r');

    let jobName = job.Job_Name + (report.ChangeOrder !== '' ? ' CO ' + report.ChangeOrder : '');
    let jobNameArr = splitString(jobName, 35);

    let col2 = [
        'Job:'
    ];
    col2 = col2.concat(jobNameArr).concat(address);
        
    pdf.text(col2, x, y, {}, 0);

    let col3 = [
        'Date Work Performed:',
        report.DateOfWork !== '' ? report.DateOfWork.split('T')[0] : report.z_Creation_Date,
        'Created By',
        username
    ]
    pdf.text(col3, 587, y, textOptions, 0);
    x = 25;



    //Date on the right
    // let dateStr = 'Date: ' + new Date(Date.now()).toLocaleString().split(',')[0];
    // pdf.text(dateStr, 587, y, textOptions, 0);

    y += 70;
    /**
     * Photo loop
     */
    for(let i=0; i < photos.length; i++) {
        //once number of images exceeds limit, add a page
        if(i > 0 && i % imagesPerPage === 0) {
            pdf.addPage('letter', 'p');
            x=25;
            y=50;
        }

        //Add image
        let imgData = photos[i].base64Img;
        let totalWidth = 268;
        let totalHeight = 200;
        let imgWidth = totalWidth;
        let imgHeight = totalHeight;

        let imgType = 'JPEG';
        let position_x = x;
        let position_y = y;
        let rotation = 0;
        
        let orientation = await getOrientation(imgData);

        if(orientation === 6) {
            rotation = -90;
            imgWidth = 200;
            imgHeight = 150;

            position_y -= imgHeight;
            position_x += 59;
        } else if(orientation === 3) {
            rotation = -180;
            position_x += imgWidth;
            position_y -= imgHeight;
        }

        pdf.addImage(imgData, imgType, position_x, position_y, imgWidth, imgHeight, null, 'FAST', rotation);
        
        //Add caption
        pdf.text(x, y + totalHeight + 24, splitString(photos[i].Description, 55) );

        if(i % 2 === 0) {
            x += totalWidth + 25;
        }

        if(i % 2 === 1) {
            y += totalHeight + 100;
            x = 25;
        }
    }

    

    //Download the PDF in the browser
    // pdf.setProperties({
    //     title: filename
    // });
    // window.open(pdf.output('bloburl')); 
    
    //pdf.save(filename);

    //Return a base 64 encoded PDF for saving to server
    return pdf;
}

const getImageData = async (url) => {
    //Fetch base 64 image data
    let response = await fetch(url); 
    let buffer = await response.arrayBuffer();
    let imageData = 'data:image/png;base64,' + arrayBufferToBase64(buffer);

    return imageData;
}

//Taken from https://medium.com/front-end-weekly/fetching-images-with-the-fetch-api-fb8761ed27b2
const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
  
    bytes.forEach((b) => binary += String.fromCharCode(b));
  
    return window.btoa(binary);
  };

  const splitString = (str, charNum) => {
    if(typeof str === 'undefined') {
        return [];
    }

    let arr = [];
    let words = str.split(' ');
    let currentWord = '';
    let nextWord = '';
    let line = '';

    for(var i=0; i < words.length; i++) {
        currentWord = words[i];
        if(i < words.length - 1) {
            nextWord = words[i+1];
        } else {
            nextWord = null;
        }

        line += currentWord + ' ';

        if(nextWord !== null && (line.length + nextWord.length) > charNum) {
            arr.push(line);
            line = '';
        }
    }
    
    //push the last line
    arr.push(line);

    return arr;
}

//https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side
function getOrientation(base64img) {
    return new Promise((resolve,reject) => {
        var base64string = base64img.split(',')[1];
        var arrayBuffer = base64ToArrayBuffer(base64string);
    
        var view = new DataView(arrayBuffer);
        if (view.getUint16(0, false) != 0xFFD8)
        {
            return resolve(-2);
        }
        var length = view.byteLength, offset = 2;
        while (offset < length) 
        {
            if (view.getUint16(offset+2, false) <= 8) return resolve(-1);
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker == 0xFFE1) 
            {
                if (view.getUint32(offset += 2, false) != 0x45786966) 
                {
                    return resolve(-1);
                }
    
                var little = view.getUint16(offset += 6, false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++)
                {
                    if (view.getUint16(offset + (i * 12), little) == 0x0112)
                    {
                        return resolve(view.getUint16(offset + (i * 12) + 8, little));
                    }
                }
            }
            else if ((marker & 0xFF00) != 0xFF00)
            {
                break;
            }
            else
            { 
                offset += view.getUint16(offset, false);
            }
        }
        return resolve(-1);
    });
}

function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export default generatePDF;