import React, {useState, useEffect} from 'react';
import {
  CircularProgress,
  Card,
  List,
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { useAuth0 } from "../../react-auth0-wrapper";
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { config } from "../../config";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%'

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));


export default function InvoiceAccounting(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [materiallist, setMaterialList] = useState([]);
    const { isAuthenticated, loginWithRedirect, logout, user, getTokenSilently } = useAuth0();
    const [permissionType, setPermissionType] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState('');
    const [selectedQty, setSelectedQty] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedDirectCost, setSelectedDirectCost] = useState('');
    const [selectedMargin, setSelectedMargin] = useState('');
    const [lineItemType, setLineItemType] = useState('');
    const [isNewItemOpen, setIsNewItemOpen] = useState(false);
    const [marginError, setMarginError] = useState(false);


    useEffect(() => {
      const getPermissions = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(config.API_ENDPOINT + "/api/getUserPermissions", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
    
          const responseData = await response.json();
          setPermissionType(responseData);
        }
        catch (error) {
          console.error(error);
        }
      };
      getPermissions();
    }, [getTokenSilently, user]);

    useEffect(() => {
      const getJobCost = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(config.API_ENDPOINT + "/api/getJobCost", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({id: props.jobId})
          });
    
          const responseData = await response.json();

          if(responseData.message)
          {

            //set labor rate and hours
            var laborDataObj = createLaborData('Roofing Labor', responseData.data.roofingHours, responseData.data.laborRate);
            laborDataObj.direct_cost = responseData.data.laborCost;
            var laborDataObj2 = createLaborData('Extra Labor', 0, responseData.data.laborRate);
            props.setLaborData([laborDataObj,laborDataObj2]);


            //set truck trip rate
            props.setTrips([{name:"Truck Trips", price: responseData.data.truckTripRate, qty: 1}]);

            /* 
            * Add direct cost and margin to Labor Data, Materials, and Misc Items
            */
            props.setLaborData(laborData => {
              return laborData.map(data => {
                if(typeof data.direct_cost === 'undefined') {
                  data.direct_cost = ''; //for now set the default cost to be the rate
                } else {
                  var margin = ((data.rate - data.direct_cost) / data.rate) * 100;
                  data.margin = parseFloat(margin).toFixed(2);
                }

                if(typeof data.margin === 'undefined') {
                  data.margin = ''; //for now set the default cost to be the rate
                }

                return data;
              });
            });

            props.setMaterialList(props.materialList.map(material => {
              if(typeof material.direct_cost === 'undefined') {
                material.direct_cost = ''; //for now set the default cost to blank
              } else {
                var margin = ((material.price - material.direct_cost) / material.price) * 100;
                material.margin = parseFloat(margin).toFixed(2);
              }

              if(typeof material.margin === 'undefined') {
                material.margin = '';
              }

              return material;
            }));

            props.setTrips(trips => {
              return trips.map(trip => {
                trip.direct_cost = ''; //for now set the default cost to blank
                trip.margin = '';

                return trip;
              });
            });

            props.setMiscItems(items => {
              return items.map(item => {
                item.direct_cost = ''; //for now set the default cost to blank
                item.margin = '';

                return item;
              });
            });
            /* 
            * Add direct cost and margin to Labor Data, Materials, and Misc Items
            */



            setLoading(false);
          }
          else
          {
              setLoading(false);
            //alert(responseData);
          }
  
        } catch (error) {
          console.error(error);
        }
      };
        getJobCost();
    }, [getTokenSilently, props.jobId]);

    const calculateTotalMargin = (directCost, price) => {
      if(directCost === 0) {
        return '';
      } else {
        var margin = ((price - directCost) / price) * 100;
        return parseFloat(margin).toFixed(2);
      }
    }

    const calculateMargin = (directCost, price) => {
      if(directCost == '0' || price == '0' || price == '0.00') {
        setSelectedMargin('');
      } else {
        var margin = ((price - directCost) / price) * 100;
        setSelectedMargin(parseFloat(margin).toFixed(2));
      }

      if(marginError) {
        setMarginError(false);
      }
    }

    const calculatePrice = (directCost, margin) => {
      if(margin >= 100) {
        setSelectedPrice('');
        setMarginError(true);
      } else {
        var price = directCost / (1 - (margin/100));
        setSelectedPrice(parseFloat(price).toFixed(2));

        if(marginError) {
          setMarginError(false);
        }
      }
    }

    const calculateDirectCost = (price, margin) => {
      if(margin >= 100) {
        setSelectedDirectCost('');
        setMarginError(true);
      } else {
        var directCost = (1 - (margin/100)) * price;
        setSelectedDirectCost(parseFloat(directCost).toFixed(2));

        if(marginError) {
          setMarginError(false);
        }
      }
    }

    const handleSelectedDirectCostChange = (value) => {
      if(/^-?\d+$/.test(value) || /^\d+\.\d+$/.test(value) || value === '' || value.includes('.')) {
        setSelectedDirectCost(value);

        if(value !== '') {
          var directCost = value;
          var price = selectedPrice;
          var margin = selectedMargin;

          if(selectedPrice !== '') {
            //calculate margin
            calculateMargin(directCost, price);
          } else if (selectedPrice === '' && selectedMargin !== '') {
            //calculate price
            calculatePrice(directCost, margin);
          }
        }
      }
    }

    const handleSelectedMarginChange = (value) => {
      if(/^-?\d+$/.test(value) || /^\d+\.\d+$/.test(value) || value === '' || value.includes('.')) {
        setSelectedMargin(value);

        if(value !== '') {
          var directCost = selectedDirectCost;
          var price = selectedPrice;
          var margin = value;

          if(selectedDirectCost !== '') {
            calculatePrice(directCost, margin);
          }
        }
      }
    }

    const handleSelectedPriceChange = (value) => {
      if(/^-?\d+$/.test(value) || /^\d+\.\d+$/.test(value) || value === '' || value.includes('.')) {
        setSelectedPrice(value);

        if(value !== '') {
          var directCost = selectedDirectCost;
          var price = value;
          var margin = selectedMargin;

          if(selectedDirectCost !== '') {
            calculateMargin(directCost, price);
          } else if (selectedDirectCost === '' && selectedMargin !== '') {
            calculateDirectCost(price, margin);
          }
        }
      }
    }

    function createLaborData(description, hours, rate) {
      var amount = hours * rate;
      return { 
        description : description, 
        hours : hours, 
        rate : rate, 
        amount : amount 
      };
    }

    function formatTime(time) {
      if(time === '')
        return '';

      let parts = time.split(':');

      let hours = parts[0];
      let ampm = hours > 12 ? 'PM' : 'AM';

      hours = hours > 12 ? hours - 12 : hours - 0;

      if(hours === 0) {
        hours = 12;
      }
      
      let minutes = parts[1];

      return hours + ':' + minutes + ' ' + ampm;
    }
  



    const handleSaveClick = () => {
      if(marginError) {
        return;
      }

      //change material list values 
      if(lineItemType === 'labor') {
        props.laborData.forEach((item, i) => {
          if(selectedIndex === i) {
            item.hours = selectedQty === '' ? '' : parseFloat(selectedQty);
            item.rate = selectedPrice;
            item.direct_cost = selectedDirectCost;
            item.margin = selectedMargin;
          }
        });
      } else if(lineItemType === 'trip') {
        props.trips.forEach((item, i) => {
          if(selectedIndex === i) {
            item.price = selectedPrice;
            item.qty = selectedQty;
            item.direct_cost = selectedDirectCost;
            item.margin = selectedMargin;
          }
        });
      } else if(lineItemType === 'misc') { 
        props.miscItems.forEach((item, i) => {
          if(selectedIndex === i) {
            item.price = selectedPrice;
            item.qty = selectedQty;
            item.direct_cost = selectedDirectCost;
            item.margin = selectedMargin;
          }
        });
      } else {
        props.materialList.forEach((item, i) => {
          if(selectedIndex === i) {
            item.price = selectedPrice;
            item.qty = selectedQty;
            item.direct_cost = selectedDirectCost;
            item.margin = selectedMargin;
          }
        });
      }


      setIsDialogOpen(false);
    };

    const openLineDetail = (item, type, index) => {
        setSelectedIndex(index);

        var price = '';
        var quantity = '';
        var description = '';

        if(typeof item.rate !== 'undefined') {
          price = item.rate;
        } else if (typeof item.price !== 'undefined') {
          price = item.price;
        }

        if(typeof item.qty !== 'undefined') {
          quantity = item.qty;
        } else if (typeof item.hours !== 'undefined') {
          quantity = item.hours;
        }

        if(typeof item.description !== 'undefined') {
          description = item.description;
        } else if (typeof item.name !== 'undefined') {
          description = item.name;
        }

        setSelectedPrice(price);
        setSelectedQty(quantity);
        setSelectedDescription(description);
        setLineItemType(type);
        setSelectedDirectCost(item.direct_cost);
        setSelectedMargin(item.margin);

        setIsDialogOpen(true);

    };

    const handleCloseClick = () => {
      if(marginError) {
        setMarginError(false);
      }
      setIsDialogOpen(false);
    };

    const handleNewItemClose = () => {
      setIsNewItemOpen(false);
    }

    const handleNewItemSave = () => {
      props.setMiscItems(items => {
        return items.concat({
          name: selectedName, 
          price: selectedPrice, 
          qty: selectedQty,
          direct_cost : '',
          margin : ''
        });
      });

      setIsNewItemOpen(false);
    }

    const removeItem = () => {
      props.setMiscItems(items => {
        return items.filter((item, index) => index !== selectedIndex);
      });

      setIsDialogOpen(false);
    }

  return  (
  <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', flexDirection:'column', borderRadius: '0px'}}>
      {!loading && <>
          <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}}>
              <List
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Review labor and material costs. Select a line item to make edits.
                  </ListSubheader>
                }
              ></List>
              <div style={{textAlign: 'right', fontWeight: 'bold', margin: '10px 25px 0px 25px', 'fontSize': '25px'}}>
                <table style={{float: 'right'}}>
                  <tbody>
                    <tr>
                      <td>DIRECT COST:</td> 
                      <td>${props.totalDirectCost().toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>MARGIN:</td> 
                      <td>{calculateTotalMargin(props.totalDirectCost(), props.total())}%</td>
                    </tr>
                    <tr>
                      <td>SALE AMOUNT:</td> 
                      <td>${props.total().toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{textAlign: 'left', margin: '0px 25px 10px 25px', fontSize: '24px'}}>TIMECARDS</div>
              <Paper className={classes.paper} style={{margin: '10px 25px 25px 25px', fontSize: '18px'}}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Person</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Start Time</TableCell>
                      <TableCell>End Time</TableCell>
                      <TableCell>Hours</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.timecards.map((row, i) => (
                      <TableRow>
                        <TableCell>{row.fieldData.NameStaff_lu}</TableCell>
                        <TableCell>{row.fieldData.Date_work_d}</TableCell>
                        <TableCell>{formatTime(row.fieldData.TimeStart_t)}</TableCell>
                        <TableCell>{formatTime(row.fieldData.TimeEnd_t)}</TableCell>
                        <TableCell>{row.fieldData.TimeTotal_ae.toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <div style={{textAlign: 'left', margin: '0px 25px 10px 25px', fontSize: '24px'}}>LABOR</div>
              <Paper className={classes.paper} style={{margin: '10px 25px 25px 25px', fontSize: '18px'}}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Direct Cost</TableCell>
                      <TableCell>Margin</TableCell>
                      <TableCell>Hourly Rate</TableCell>
                      <TableCell>Hours</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.laborData.map((row, i) => (
                      row.description !== "" && <TableRow button onClick={() => openLineDetail(row, 'labor', i)}>
                        <TableCell component="th" scope="row">
                          {row.description}
                        </TableCell>
                        <TableCell>{'$' + row.direct_cost}</TableCell>
                        <TableCell>{row.margin + '%'}</TableCell>
                        <TableCell>{row.rate === '' ? <span style={{color: "red"}}>Click to add rate</span> : '$' + row.rate }</TableCell>
                        <TableCell>{row.hours === '' ? <span style={{color: "red"}}>No timecard hours</span> : row.hours.toFixed(2) }</TableCell>
                        <TableCell>{'$' + props.lineTotal(row.hours, row.rate).toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <div style={{textAlign: 'left', margin: '10px 25px 10px 25px', fontSize: '24px'}}>MATERIAL</div>
              <Paper className={classes.paper} style={{margin: '10px 25px 25px 25px', fontSize: '18px'}}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Direct Cost</TableCell>
                      <TableCell>Margin</TableCell>
                      <TableCell>Price Per Unit</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.materialList.map((row, i) => (
                      row.name !== "" && <TableRow onClick={() => openLineDetail(row, 'material', i)}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>{'$' + row.direct_cost}</TableCell>
                        <TableCell>{row.margin + '%'}</TableCell>
                        <TableCell>{row.price === '' ? <span style={{color: "red"}}>Click to add price</span> : '$' + row.price }</TableCell>
                        <TableCell>{row.qty === '' ? <span style={{color: "red"}}>Click to add quantity</span> : row.qty }</TableCell>
                        <TableCell>{'$' + props.lineTotal(row.price, row.qty).toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <div style={{textAlign: 'left', margin: '10px 25px 10px 25px', fontSize: '24px'}}>OTHER</div>
              <Paper className={classes.paper} style={{margin: '10px 25px 25px 25px', fontSize: '18px'}}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      {/*<TableCell>Description</TableCell>*/}
                      {/*<TableCell>Direct Cost</TableCell>*/}
                      <TableCell>Margin</TableCell>
                      <TableCell>Price Per Unit</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.trips.map((row, i) => (
                        row.name !== "" && <TableRow button onClick={() => openLineDetail(row, 'trip', i)}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          {/*<TableCell>{'$' + row.direct_cost}</TableCell>*/}
                          {/*<TableCell>{row.margin + '%'}</TableCell>*/}
                          <TableCell>{'$' + row.price}</TableCell>
                          <TableCell>{row.qty}</TableCell>
                          <TableCell>{'$' + props.lineTotal(row.qty, row.price).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                      {props.miscItems.map((row, i) => (
                        row.name !== "" && <TableRow button onClick={() => openLineDetail(row, 'misc', i)}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          {/*<TableCell>{'$' + row.direct_cost}</TableCell>*/}
                          {/*<TableCell>{row.margin + '%'}</TableCell>*/}
                          <TableCell>{'$' + row.price}</TableCell>
                          <TableCell>{row.qty}</TableCell>
                          <TableCell>{'$' + props.lineTotal(row.qty, row.price).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>

              <Button color="secondary" onClick={() => setIsNewItemOpen(true)}>Add New Line Item</Button>

              <div style={{textAlign: 'left', margin: '10px 25px 10px 25px', fontSize: '24px'}}>TAX</div>
              <div style={{margin: '10px 25px 25px 25px', fontSize: '18px', textAlign: 'left'}}>
              <FormControl>
                <FormControlLabel control={
                  <Checkbox
                    checked={props.taxChecked}
                    onChange={() => { props.setTaxChecked(!props.taxChecked)}}
                    value="taxChecked"
                    inputProps={{
                      'aria-label': 'Include Tax On Invoice',
                    }}
                  />
                } label={'Include Tax On Invoice'} />
              </FormControl>
              </div>
          </Card>
          <Dialog
            open={isDialogOpen}
            onClose={handleCloseClick}
            aria-labelledby="max-width-dialog-title"
          >
          <DialogTitle id="max-width-dialog-title">Edit - {selectedDescription}</DialogTitle>
          <DialogContent>
          {lineItemType === 'misc' ? <><div style={{width: '100%'}}><Button style={{color: 'red', width: '100%'}} onClick={removeItem}>Remove Line Item</Button><br /><br /></div></> : ''}


          <form className={classes.form} noValidate>
            {(lineItemType === 'material' || lineItemType === 'labor') && <>
              <FormControl className={classes.formControl}>
                  {(lineItemType === 'labor' && selectedIndex === 0) && <TextField disabled
                          value={selectedDirectCost}
                          label={'Direct Cost ($)'}
                          onChange={(event) => {  }}
                  />}
                  {(lineItemType === 'labor' && selectedIndex === 1) && <TextField
                        value={selectedDirectCost}
                        label={'Direct Cost ($)'}
                        onChange={(event) => { handleSelectedDirectCostChange(event.target.value); }}
                  />}
                  {lineItemType === 'material' && <TextField
                        value={selectedDirectCost}
                        label={'Direct Cost ($)'}
                        onChange={(event) => { handleSelectedDirectCostChange(event.target.value); }}
                  />}
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                        value={selectedMargin}
                        label={'Margin (%)'}
                        onChange={(event) => { handleSelectedMarginChange(event.target.value); }}
                  />
                </FormControl>

              <FormControl className={classes.formControl}>
                {marginError && <div style={{color:'red', fontSize: '12px'}}>Margin cannot be equal to or greater than 100%. <br />Please input a lower value.</div>}
              </FormControl>
              </>}
              <FormControl className={classes.formControl}>
                <TextField
                      value={selectedPrice}
                      label={ lineItemType === 'trip' ? 'Price Per Trip' : (lineItemType === 'labor' ? 'Hourly Rate ($)' : 'Price Per Unit ($)') }
                      onChange={(event) => { handleSelectedPriceChange(event.target.value); }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                  {lineItemType !== 'labor' && <TextField
                        value={selectedQty}
                        label={ lineItemType === 'trip' ? 'Number of Trips' : (lineItemType === 'labor' ? 'Hours' : 'Quantity') }
                        onChange={(event) => { setSelectedQty(event.target.value); }}
                  />}
                  {(lineItemType === 'labor' && selectedIndex === 1) && <TextField
                        value={selectedQty}
                        label={ lineItemType === 'trip' ? 'Number of Trips' : (lineItemType === 'labor' ? 'Hours' : 'Quantity') }
                        onChange={(event) => { setSelectedQty(event.target.value); }}
                  />}
                  {(lineItemType === 'labor' && selectedIndex === 0) && <TextField disabled
                        value={selectedQty}
                        label={'Timecard hours'}
                        onChange={(event) => {  }}
                  />}
              </FormControl>
          </form>
          </DialogContent>
          <DialogActions style={{ display:'flex', justifyContent: "space-between"}}>
            <Button onClick={handleCloseClick} color="primary">
                Close
            </Button>
            <Button color="secondary" onClick={handleSaveClick}>
                Save
            </Button>
          </DialogActions>
      </Dialog>


      {/** Dialog for adding a new line item */}
      <Dialog
        open={isNewItemOpen}
        onClose={handleNewItemClose}
        aria-labelledby="max-width-dialog-title"
      >
          <DialogTitle id="max-width-dialog-title">Add New Line Item</DialogTitle>
          <DialogContent>
            <form className={classes.form} noValidate>
            
                <FormControl className={classes.formControl}>
                  <TextField
                        value={selectedName}
                        label={'Name'}
                        onChange={(event) => { setSelectedName(event.target.value); }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                        value={selectedPrice}
                        label={'Price Per Unit ($)'}
                        onChange={(event) => { setSelectedPrice(event.target.value); }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <TextField
                          value={selectedQty}
                          label={'Quantity'}
                          onChange={(event) => { setSelectedQty(event.target.value); }}
                    />
                </FormControl>
            </form>
          </DialogContent>
          <DialogActions style={{ display:'flex', justifyContent: "space-between"}}>
            <Button onClick={handleNewItemClose} color="primary">
                Close
            </Button>
            <Button color="secondary" onClick={handleNewItemSave}>
                Save
            </Button>
          </DialogActions>
      </Dialog>
      </>
      }
      {loading && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}
  </div>
  );
};