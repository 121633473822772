import React, { useState, useEffect, useMemo, } from 'react';
import {CircularProgress, IconButton,Card, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Badge, Select, MenuItem} from '@material-ui/core';
import theme from '../../themes/theme';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from "../../react-auth0-wrapper";
import FormLabel from '@material-ui/core/FormLabel';
import RoomIcon from '@material-ui/icons/Room';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import ImageModal from './Image';
import DeleteIcon from '@material-ui/icons/Delete';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      margiRight: theme.spacing(1),
      
      marginLeft: theme.spacing(1),
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    paper: {
      margin: theme.spacing(3),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    list: {
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    jobCard: {
      width:'100%',
      display: 'flex',
      textAlign: 'center',
      flexDirection:'column',
      borderRadius: '0px'
    }
  
  }));




export default function JobimageList(props) {
    const [currentImage, setCurrentImage] = useState(null);
    const [stepImages, setStepImages] = useState([]);
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [imageOpen, setImageOpen] = useState(false);
    const {getTokenSilently} = useAuth0();
    const [checkedBA, setCheckedBA] = useState([0]);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [features, setFeatures] = useState([]);
    const [itemClicked, setItemClicked] = useState(false);

    console.log(props.images);
    
    function handleListItemClick(event, index, image) {
      setSelectedIndex(index);
      props.findFeature(image.fieldData._idf_marker);
      setItemClicked(true);

    }

    const handleDeleteClick = (e, image) => {
      e.stopPropagation();
      image.deleted = true;
      image.changed = true;
      props.setChanges(true);
      console.log('image deleted');
    };

          const handleImageClose = () => {
            setImageOpen(false);
          }

          useEffect(() => {
            const newChecked = [0];
            props.images.forEach(element => {
              const currentIndex = checkedBA.indexOf(element);
                  if(element.fieldData.BeforeAfter == "After")
                  {
                      newChecked.push(element);
                  }
    
            });


          
      
          setCheckedBA(newChecked);
    
      }, [props.images]);

      const handleBAToggle = value => (e) => {
        e.stopPropagation();
        const currentIndex = checkedBA.indexOf(value);
        const newChecked = [...checkedBA];
    
        if (currentIndex === -1) {
          newChecked.push(value);
          value.fieldData.BeforeAfter = "After";
          value.changed = true;
        } else {
          newChecked.splice(currentIndex, 1);
          value.fieldData.BeforeAfter = "Before";
          value.changed = true;
        }
    
        setCheckedBA(newChecked);
        props.setChanges(true);
      };

      const handleBAChange = (event, image) => {
        image.fieldData.BeforeAfter = event.target.value;
        image.changed = true;

        props.setChanges(true);
      };

    useEffect(() => {
      if(!props.loading)
      {
        const newFeatures = [];
        props.features.forEach(feature => {
          newFeatures.push(feature);
          props.images.forEach(image => {
            if(feature.getProperty('id') == image.fieldData._idf_marker)
            {
              image.type = feature.getGeometry().getType();
              image.label = feature.getProperty('label');
              image.color = feature.getProperty('color');

            }
          });
        });
        setFeatures(newFeatures);
      }
    }, [props.features, props.images, props.loading]);

    useEffect(() => {
      if(props.currentFeature != null && itemClicked)
      {
        setItemClicked(false);
        const lat = [];
        const lng = [];
            
            props.currentFeature.getGeometry().forEachLatLng((currentLatLng) => {
              lat.push(currentLatLng.lat());
              lng.push(currentLatLng.lng());
            });
        
        var sum = 0;
        var latAvg = 0;
        var lngAvg = 0;
        if (lat.length)
        {
            sum = lat.reduce(function(a, b) { return a + b; });
            latAvg = sum / lat.length;
        }
        if (lng.length)
        {
            sum = lng.reduce(function(a, b) { return a + b; });
            lngAvg = sum / lng.length;
        }
        props.changeNewBounds({latMin: Math.min(...lat), latMax: Math.max(...lat),lngMin: Math.min(...lng), lngMax: Math.max(...lng)});
        //props.changeCenter({lat: latAvg, lng: lngAvg});
      }
      
    }, [itemClicked, props.loading]);

    const handleMarkerChange = (event, image) => {
      image.fieldData._idf_marker = event.target.value;
      image.changed = true;
      props.setChanges(true);
    }

    const selectItems = useMemo(() => { return features.map((feature, i) => (
        <MenuItem value={feature.getProperty('id')} key={i}>
          {feature.getGeometry().getType() == "Point" && <><Badge color="primary" badgeContent={feature.getProperty('label')}><RoomIcon style={{color: 'red'}}/></Badge></>}
          {feature.getGeometry().getType() == "Polygon" && <ChangeHistoryIcon style={{color: feature.getProperty('color')}}/>}
        </MenuItem>)
    )}, [features]);

    const compare = (a,b) => {
      var label_a = '';
      var label_b = '';

      if(typeof a.label !== 'undefined') {
        label_a = a.label;
      }

      if(typeof b.label !== 'undefined') {
        label_b = b.label;
      }

      //First sort by Label
      if(label_a > label_b) {
        return 1;
      } else if (label_a < label_b) {
        return -1;
      } else {
        if(a.fieldData.BeforeAfter === 'Before' && b.fieldData.BeforeAfter !== 'Before') {
          return -1;
        } else if (a.fieldData.BeforeAfter === 'After' && b.fieldData.BeforeAfter !== 'After') {
          if(b.fieldData.BeforeAfter === 'Before') {
            return 1;
          } else {
            return -1;
          }
        } else if(a.fieldData.BeforeAfter === 'Other' && b.fieldData.BeforeAfter !== 'Other') {
          return 1;
        } else {
          return 0;
        }
      }

    }

    return (
        <>
        {!props.loading &&
        <>
        <Card className={classes.jobCard}>
        <IconButton classes={{label: classes.label}} color="secondary" onClick={() => { props.setNewPicOpen(true); }}>               
          <AddAPhotoIcon />&nbsp;Add Image
        </IconButton>
        <List className={classes.list}>
            {props.loading && <CircularProgress></CircularProgress>}
            {!props.loading && <>
              <Divider />
                {props.images.sort(compare).map((image, i) => {
                    const labelId = `checkbox-list-label-${image}`;
                    return (
                      <div key={image.fieldData.__ID}>
                        <ListItem button selected={selectedIndex === i}
                        onClick={/*event => handleListItemClick(event, i, image)*/ () => {setCurrentImage(image); setImageOpen(true)}}>
                          <Select
                            value={image.fieldData._idf_marker}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(event) => handleMarkerChange(event, image)}
                          >    
                            <MenuItem value={''} key={'_blank'}></MenuItem>
                            {selectItems}
                          </Select>
                            <ListItemAvatar>
                            <IconButton>
                                <Avatar  style={{backgroundColor:theme.palette.secondary.main}} alt={image.fieldData.Description} src={image.fieldData.url} />
                            </IconButton>
                            </ListItemAvatar>
                            <ListItemText primary={image.fieldData.Description} />
                            {/*image.type == "Point" && <><Badge color="primary" badgeContent={image.label}><RoomIcon style={{color: 'red'}}/></Badge></>}
                            {image.type == "Polygon" && <ChangeHistoryIcon style={{color: image.color}}/>*/}
                            <FormLabel
                              value={image.fieldData.BeforeAfter}
                              /*control={<Switch checked={checkedBA.indexOf(image) !== -1} onClick={handleBAToggle(image)} color="secondary" />}*/
                              label={image.fieldData.BeforeAfter}
                              labelPlacement="bottom"
                            />
                            <Select
                              onChange={e => handleBAChange(e, image)}
                              value={image.fieldData.BeforeAfter}
                              style={{maxWidth: '75px'}}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <MenuItem value='Before'>Before</MenuItem>
                              <MenuItem value='After'>After</MenuItem>
                              <MenuItem value='Other'>Other</MenuItem>
                            </Select>
                            <IconButton button onClick={(e) => handleDeleteClick(e, image)}>
                              <DeleteIcon />
                            </IconButton>
                        </ListItem>
                        <Divider />
                      </div>
                )})}
            </>}
        </List>
        </Card>
        
        <ImageModal
          editable={true}
          changeDescription={(description) =>
          {
            var newImage = currentImage;
            newImage.fieldData.Description = description;
            newImage.changed = true;
            props.images.filter(image => image === currentImage).forEach(image => { props.setImage(image, newImage); });
          }}
          changeCurrentImage={setCurrentImage}
          open={imageOpen}
          image={currentImage}
          changeOpen={() => setImageOpen(false)} />
        </>}
                      </>
    );

};