import React, { useState } from "react";

import { RoofContext } from "./AppContext";

const { Provider } = RoofContext;

export const RoofProvider = ({ children }) => {
  const [roof, setRoof] = useState({
    materialList: [
      { name: '', price: '', qty: '', units: '' },
      { name: '', price: '', qty: '', units: '' },
      { name: '', price: '', qty: '', units: '' }
    ],
  });

  return (
    <Provider value={{ roof, setRoof }}>
      {children}
    </Provider>
  )
}
