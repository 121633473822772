import React from "react";
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';


const Search = (props) => {

    const handleChange = (amount) => {

    }

    const element = (
        <FormControl fullWidth variant="filled">
          <InputLabel htmlFor="filled-adornment-amount">Address</InputLabel>
          <FilledInput
            id="filled-adornment-amount"
            value={props.address}
            onChange={handleChange('amount')}
            startAdornment={<InputAdornment position="start"><RoomOutlinedIcon /></InputAdornment>}
          />
        </FormControl>
    );

    return element;
}

export default Search;