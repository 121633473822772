import React, {useState, useEffect} from "react";
import PhotoDialog from './PhotoDialog';
import PhotoList from './PhotoList';
import { useAuth0 } from "../../../react-auth0-wrapper";
import generatePDF from './GenerateReport';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from "@material-ui/core";
import { useMediaQuery } from 'react-responsive';
import { config } from "../../../config";

const ManageReport = (props) => {
    const [photos, setPhotos] = useState([]);
    const [loadingPhotos, setLoadingPhotos] = useState(false);
    const [dialogOpen,setDialogOpen] = useState(false);
    const [dialogParam, setDialogParam] = useState('');
    const [reloadPhotos, setReloadPhotos] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState({});
    const [editOpen, setEditOpen] = useState(false);
    const { getTokenSilently, user } = useAuth0();
    const [loadingPdf, setLoadingPdf] = useState(false);

    const openPhotoDialog = (value) => {
        setDialogOpen(true);
        setDialogParam(value);
    }

    const isTinyMobile = useMediaQuery({ query: '(max-width: 350px)' });

    //reset photos when a daily report changes
    useEffect(() => {
        setPhotos([]);
    }, [props.selectedReport])


    const fetchCommand = async (url, data) => {
        const token = await getTokenSilently();
        const request = {
            method: 'POST',
            headers: { 
                Authorization: 'Bearer ' + token,
                Accept: 'application/json'
            },
            body: JSON.stringify(data)
        };

        const result = await fetch(url, request);
        const obj = await result.json();
        return obj;
    }

    const savePDF = async (reportId, jobId, filename, pdf) => {
        try {
            const path = config.API_ENDPOINT + '/api/dailyReport/'+ reportId +'/pdf';
            const result = await fetchCommand(path, {jobId : jobId, username : user.name, filename : filename, pdf : pdf});

            return result.message;
        } catch(error) {
            console.error(error);
            return false;
        }
    }
    
    const generateReport = async (event) => {
        setLoadingPdf(true);

        //generate filename
        let date = new Date(Date.now()).toLocaleString().split(',')[0];
        date = date.replace(/\//g, '-');
        let filename = 'Daily Report '+ props.selectedJob.Number_JobCombined + ' ' + props.selectedJob.Job_Name + ' ' + date + '.pdf';

        //generate a pdf of them
        let pdf = await generatePDF(filename, props.selectedReport, props.selectedJob, photos, user.name);

        //Save PDF to server
        let base64pdf =  btoa(pdf.output());
        let result = await savePDF(props.selectedReport.__ID, props.selectedJob.__ID, filename, base64pdf);

        pdf.save(filename);

        //Display result to user
        if(result) {
            alert('PDF Saved Successfully.');
        } else {
            alert('Error uploading PDF. Please try again.');
        }



        setLoadingPdf(false);
    }

    const btnStyle = {
        color : 'white',
        fontWeight : '500',
        backgroundColor: '#24a0ed',
        marginLeft: '2.5px',
        marginRight: '2.5px'
    }

    const take = 'take';
    const upload = 'upload';

    if(props.currentScreen !== 3) {
        return null;
    }

    return (
        <div>
            {/* <div style={{fontSize:'16px'}}> */}
            <div>
                {props.selectedJob.Job_Name + ' ' + props.selectedJob.Number_JobCombined + (props.selectedReport.ChangeOrder !== '' ? ' CO ' + props.selectedReport.ChangeOrder : '')}
                {/* <br /> */}
                {props.selectedReport.DateOfWork !== '' ? props.selectedReport.DateOfWork.split('T')[0] : props.selectedReport.z_Creation_Date}
            
            </div>
            {/* <br /> */}

            <Button onClick={e => openPhotoDialog('upload')} style={btnStyle}>Add Photo</Button>
            
            <Button onClick={generateReport} style={btnStyle}>{loadingPdf ? <CircularProgress size={24} thickness={4}/> : (isTinyMobile ? 'PDF' : 'Generate PDF')}</Button>
            
            {/* <br /> */}
            <PhotoList 
                photos={photos} 
                setPhotos={setPhotos} 
                loadingPhotos={loadingPhotos} 
                setLoadingPhotos={setLoadingPhotos} 
                selectedReport={props.selectedReport}
                reloadPhotos={reloadPhotos}
                setReloadPhotos={setReloadPhotos}
                editOpen={editOpen}
                setEditOpen={setEditOpen}
                selectedPhoto={selectedPhoto}
                setSelectedPhoto={setSelectedPhoto}
            />
            
            <PhotoDialog 
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                param={dialogParam} 
                selectedReport={props.selectedReport}
                selectedJob={props.selectedJob}
                reloadPhotos={reloadPhotos}
                setReloadPhotos={setReloadPhotos}
            />


        </div>
    );
}

export default ManageReport;