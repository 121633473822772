import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { Button, FormLabel, MenuItem, InputLabel } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import { TextField } from '@material-ui/core'
import lists from '../Individual/individual.module.css'
import { useAuth0 } from '../../../react-auth0-wrapper'
import NumberFormat from 'react-number-format'
import LinearProgress from '@material-ui/core/LinearProgress'
import Select from '@material-ui/core/Select'
import { config } from '../../../config'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  formControl: {
    width: '100%'
  },
  opportunities_Icon: {
    fontSize: '21px',
    marginLeft: '4px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '2px'
    }
  },

  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  paper: {
    minWidth: '35%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '55%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '90%',
      margin: '0 auto'
    }
  },

  action: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0',
    padding: '0',
    borderBottom: '1px solid #acacac',
    marginBottom: '18px'
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '10px',
    color: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      right: '11px',
      top: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      right: '11px',
      top: '4px'
    }
  },

  generateAction: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 24px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: '18px 24px'
    }
  },

  cancel: {
    width: '50%',
    color: '#fff',
    background: '#535b69',
    '&:hover': {
      background: '#494f5a'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '2%',
      marginLeft: '0'
    }
  },

  generate: {
    width: '50%',
    color: '#fff',
    background: '#24a0ed',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '8px',
      marginTop: '0'
    },

    '&:hover': {
      background: '#258fd2'
    }
  },

  dialogContentSpacing: {
    padding: '8px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 24px'
    }
  },

  finalize_button: {
    marginTop: '0',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4px'
    }
  }
}))

const JobDialog = props => {
  const [amount, setAmount] = useState('')
  const [numericAmount, setNumericAmount] = useState(0)
  const {
    dialogOpen,
    setDialogOpen,
    selectedOpp,
    setSelectedOpp,
    setRedirect,
    selectedInspection,
    salespeople
  } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [jobCreated, setJobCreated] = useState(false)
  const [newJobId, setNewJobId] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [salesperson, setSalesperson] = useState('')

  const handleCloseClick = () => {
    setSalesperson('')
    setErrorMessage('')
    setSelectedOpp(null)
    setAmount('')
    setDialogOpen(false)
  }

  const fetchCommand = async (url, data) => {
    const token = await getTokenSilently()
    const request = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json'
      },
      body: JSON.stringify(data)
    }
    const result = await fetch(url, request)
    const obj = await result.json()
    return obj
  }

  const convertToJob = async () => {
    setLoading(true)
    setErrorMessage('')
    let data = {
      opportunity_id: selectedOpp.__ID,
      contractAmount: numericAmount,
      inspection_id: selectedInspection,
      salesperson: salesperson
    }

    try {
      const path =
        config.API_ENDPOINT + '/api/opportunities/' + data.opportunity_id + '/job'
      const result = await fetchCommand(path, data)
      console.log(result, 'result')
      setLoading(false)

      if (result.message) {
        setJobCreated(true)
        setNewJobId(result.data.job_id)
      } else {
        if (result.data.error == '401') {
          setErrorMessage(
            'Error: Please make sure there is a Customer associated with this Opportunity.'
          )
        }
      }

      return result.message
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const goToJob = () => {
    setRedirect('job/' + newJobId)
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseClick}
        aria-labelledby='max-width-dialog-title'
        classes={{ paper: classes.paper }}
      >
        <DialogActions className={classes.action}>
          <DialogTitle
            id='max-width-dialog-title'
            className={lists.generate_title}
          >
            Convert To Job
          </DialogTitle>

          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleCloseClick}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>

        {!loading && (
          <DialogContent className={classes.dialogContentSpacing}>
            {!jobCreated && (
              <form className={classes.form} noValidate>
                <FormControl className={classes.formControl}>
                  <FormLabel>Enter Contract Amount</FormLabel>
                  <NumberFormat
                    decimalScale={2}
                    id='contract_amount'
                    value={amount}
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={values => {
                      const { formattedValue, value } = values
                      setAmount(formattedValue)
                      setNumericAmount(value)
                    }}
                  />
                </FormControl>
                <FormControl
                  className={classes.formControl}
                  style={{ marginTop: '26px' }}
                >
                  <InputLabel>Select Salesperson</InputLabel>
                  <Select
                    value={salesperson}
                    onChange={event => {
                      setSalesperson(event.target.value)
                    }}
                  >
                    {salespeople.map(cust => (
                      <MenuItem
                        value={cust.fieldData.Name_combined_ae}
                        key={cust.fieldData.__ID}
                      >
                        {cust.fieldData.Name_combined_ae}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorMessage != '' && (
                  <div style={{ color: 'red' }}>
                    <br />
                    {errorMessage}
                  </div>
                )}
              </form>
            )}
            {jobCreated && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <h3>A new job has been created!</h3>
              </div>
            )}
          </DialogContent>
        )}

        {loading && (
          <DialogContent className={classes.dialogContentSpacing}>
            <LinearProgress />
          </DialogContent>
        )}

        <DialogActions className={classes.generateAction}>
          {!loading && !jobCreated && (
            <>
              <Button
                className={classes.cancel}
                onClick={handleCloseClick}
                variant='contained'
                color='primary'
              >
                Cancel
              </Button>
              <Button
                className={classes.generate}
                color='secondary'
                variant='contained'
                onClick={convertToJob}
              >
                Convert To Job
              </Button>
            </>
          )}

          {jobCreated && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                className={classes.generate}
                color='secondary'
                variant='contained'
                onClick={goToJob}
              >
                Go to job &#8594;
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default JobDialog
